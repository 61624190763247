import axios from "axios";
import moment from "moment-timezone";
import { Button, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import Loader from "../components/Loader";
import { GlobalContext } from "../context/GlobalContext";

import "../css/Login.css";
import { notification, VerifyAuth } from "../helpers/Global";
import { useNavigate } from "react-router-dom";
import { LockOpen, PersonAdd } from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { BUTTON } from "../helpers/styles";

const Signup = () => {
	const { isLoading, setIsLoading, cookies } = useContext(GlobalContext);
	const [inputs, setInputs] = useState({ name: "", email: "", password: "", repeatPassword: "" });
	const { name, email, password, repeatPassword } = inputs;
	const [isAccountCreated, setIsAccountCreated] = useState(false);

	const navigate = useNavigate();
	const token = cookies.get("token");
	const [t] = useTranslation("global");

	useEffect(() => {

		console.log("guess:", moment.tz.guess());
		console.log("offset:", moment().tz(moment.tz.guess()))

		console.log("utc:", moment.utc().format())

		setIsLoading(true);
		document.title = t("Signup.title");
		if (token !== undefined) {
			const verifyToken = async () => {
				let result = await VerifyAuth(token);
				if (result.OK) {
					navigate("/dashboard");
				}
				else {
					console.log("Signup.verifyToken-error:", result);
					switch (result.message) {
						case "ERR_NETWORK":
							notification(t("Axios.errors.ERR_NETWORK"), "error");
							break;
						case "WithoutToken":
							notification(t("Global.VerifyAuth.WithoutToken"), "error");
							break;
						case "TokenExpiredError":
							notification(t("Global.VerifyAuth.TokenExpiredError"), "error");
							cookies.remove("token", { path: "/" });
							localStorage.clear();
							break;
						case "JsonWebTokenError":
							notification(t("Global.VerifyAuth.JsonWebTokenError"), "error");
							cookies.remove("token", { path: "/" });
							localStorage.clear();
							break;
						default:
							notification(t("Global.VerifyAuth.default"), "error");
							cookies.remove("token", { path: "/" });
							localStorage.clear();
							break;
					}
				}
			}
			verifyToken();
		}
		setIsLoading(false);
	}, []);

	const onChange = (evt) => {
		setInputs({ ...inputs, [evt.target.name]: evt.target.value });
	}

	const onsubmitCreateAccount = async (evt) => {
		setIsLoading(true);
		evt.preventDefault();

		if (password !== repeatPassword) {
			notification(t("Signup.passwordsNotEquals"), "error");
			return;
		}

		let tempEmail = email.replace(/\s/g, "");
		setInputs({ ...inputs, email: tempEmail });

		await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/users/register`,
			{ name, email: tempEmail, password, timeZone: moment.tz.guess() }
		)
			.then(result => {
				console.log("Signup.createAccount-result:", result);
				setIsAccountCreated(true);
				notification(t("Signup.userCreated"), "success");
			})
			.catch(error => {
				console.log("Signup.createAccount-error:", error);
				if (error.code === "ERR_NETWORK") {
					notification(t("Axios.errors.ERR_NETWORK"), "error");
					return;
				}

				switch (error.response.data.message) {
					case "registrationDisabled":
						notification(t("Signup.errors.registrationDisabled"), "error");
						break;
					case "errorFindingEmail":
						notification(t("Signup.errors.errorFindingEmail"), "error");
						break;
					case "alreadyRegisteredUser":
						notification(t("Signup.errors.alreadyRegisteredUser"), "error")
						break;
					case "errorCreatingHash":
						notification(t("Signup.errors.errorCreatingHash"), "error");
						break;
					case "errorCreatingNewUser":
						notification(t("Signup.errors.errorCreatingNewUser"), "error");
						break;
					default:
						notification(t("Signup.errors.default"), "error");
						break;
				}
			});
		setIsLoading(false);
	}

	return (
		<>
			{
				(isLoading)
					?
					<Loader />
					:
					<div className="container-signup">
						{
							(isAccountCreated)
								?
								<>
									<Typography className="title-signup">
										{t("Signup.sendFirstMessage")}
									</Typography>
									<Button
										color="primary"
										fullWidth
										onClick={() => {
											navigate("/");
										}}
										size="medium"
										variant='contained'
									>
										{t("Signup.loginButton")}
									</Button>
								</>
								:
								<div className="container-login">
									<div className="login-form">
										<div className="login-form-left">

											<div className="login-form-left-title">
												<Typography variant="h5" fontWeight={"bold"}>
													{t("Signup.signupButton")}
												</Typography>
												<Typography>
													{t("Signup.getStarted")}
												</Typography>
											</div>

											<div className="login-form-left-fields">
												<form onSubmit={onsubmitCreateAccount} className="login-form-left-fields">

													<div className="login-textfield">
														<TextField
															required
															fullWidth
															id="name"
															label={t("Signup.form.fullName")}
															name="name"
															onChange={(evt) => { onChange(evt); }}
															value={name}
														/>
													</div>
													<div className="login-textfield">
														<TextField
															required
															fullWidth
															id="email"
															label={t("Signup.form.email")}
															name="email"
															onChange={(evt) => onChange(evt)}
															value={email}
														/>
													</div>

													<div className="login-textfield">
														<TextField
															required
															fullWidth
															name="password"
															label={t("Signup.form.password")}
															type="password"
															id="password"
															onChange={(evt) => onChange(evt)}
															sx={{
																borderRadius: "8px"
															}}
															value={password}
														/>
													</div>

													<div className="login-textfield">
														<TextField
															required
															fullWidth
															id="repeatPassword"
															label={t("Signup.form.repeatPassword")}
															name="repeatPassword"
															onChange={(evt) => { onChange(evt); }}
															type="password"
															value={repeatPassword}
														/>
													</div>

													<Button
														className={"login-button"}
														fullWidth
														startIcon={<PersonAdd />}
														sx={BUTTON.buttonPrimary}
														type={"submit"}
													>
														{t("Signup.signupButton")}
													</Button>

													<center>
														<Typography className="title-signup">
															{t("Signup.form.haveAnAccount")}
														</Typography>
													</center>


												</form>
												<Button
													className={"login-button"}
													fullWidth
													onClick={() => { navigate("/login"); }}
													startIcon={<LockOpen />}
													sx={BUTTON.buttonPrimary}
												>
													{t("Signup.loginButton")}
												</Button>
											</div>

										</div>

										<div className="signup-form-right">
											<div className="login-form-right-container">
												<Typography variant="h5">
													{t("Signup.modernInterface")}
												</Typography>
											</div>
										</div>
									</div>
								</div>
						}

					</div>

			}

		</>
	);
}

export default Signup;
