import { AppBlocking, ContactPhone, Paid, Sms } from "@mui/icons-material";
import { Alert, colors, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Card from "../components/Card";
import moment from "moment-timezone";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from "axios";
import { GlobalContext } from "../context/GlobalContext";
import { notification } from "../helpers/Global";
import { Box } from "@mui/system";

import { COLORS } from "../colors";
import MainContainer from "../components/MainContainer";

import { useTranslation } from "react-i18next";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { SxProps } from "@mui/material";
import Loader from "../components/Loader";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
);

const Dashboard = () => {
	// States
	const [countBulkMessages, setCountBulkMessages] = useState(0);
	const [countContacts, setCountContacts] = useState(0);
	const [countOptOut, setCountOptOut] = useState(0);
	const [balanceAccount, setBalanceAccount] = useState(0);
	const [messagesByMonth, setMessagesByMonth] = useState([]);
	const [currentYear, setCurrentYear] = useState(moment().format("YYYY"));
	const [errorMessage, setErrorMessage] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const { cookies } = useContext(GlobalContext);
	const [t] = useTranslation("global");

	const token = cookies.get("token");

	const bulkMessagesOptions = {
		maintainAspectRatio: false,
		responsive: true,
		plugins: {
			legend: {
				display: false
			}
		},
	};

	const monthData = {
		labels: [
			t("Dashboard.monthData.jan"),
			t("Dashboard.monthData.feb"),
			t("Dashboard.monthData.mar"),
			t("Dashboard.monthData.apr"),
			t("Dashboard.monthData.may"),
			t("Dashboard.monthData.jun"),
			t("Dashboard.monthData.jul"),
			t("Dashboard.monthData.aug"),
			t("Dashboard.monthData.sep"),
			t("Dashboard.monthData.oct"),
			t("Dashboard.monthData.nov"),
			t("Dashboard.monthData.dec")
		],
		datasets: [
			{
				label: t("Dashboard.monthData.label"),
				data: messagesByMonth,
				backgroundColor: COLORS.light.primary,
			}
		],
	};

	const getCountBulkMessagesByYear = (year, wasCalled) => {
		if (wasCalled) {
			setIsLoading(true);
		}
		return new Promise(async (resolve, reject) => {
			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/bulk/getCountBulkMessagesByYear/${year}`,
				{ headers: { "Authorization": token } }
			)
				.then(({ data }) => {
					const tempResult = data.message;
					const tempMessagesByMonth = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
					for (let i in tempResult) {
						tempMessagesByMonth[tempResult[i]._id.month - 1] = tempResult[i].total;
					}
					setMessagesByMonth(tempMessagesByMonth);
					resolve({ OK: true, message: tempMessagesByMonth });
				})
				.catch(error => {
					console.log("Dashboard.getCountBulkMessagesByYear-error:", error);
					// notification(t("Dashboard.errors.messagesStats"), "error");
					reject({ OK: false, message: error });
				});
			if (wasCalled) {
				setIsLoading(false);
			}
		});
	}

	useEffect(() => {
		document.title = t("Dashboard.title");

		const getCountBulkMessages = new Promise(async (resolve, reject) => {
			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/bulk/getCountBulkMessages`,
				{ headers: { "Authorization": token } }
			)
				.then(({ data }) => {
					resolve({ OK: true, message: data.message });
				})
				.catch(error => {
					reject({ OK: false, message: error });
				});
		});

		const getCountContacts = new Promise(async (resolve, reject) => {
			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/contacts/getCountContacts`,
				{ headers: { "Authorization": token } }
			)
				.then(({ data }) => {
					resolve({ OK: true, message: data.message });
				})
				.catch(error => {
					console.log("Dashboard.getCountContacts-error:", error);
					reject({ OK: false, message: error });
				});
		});

		const getCountOptOut = new Promise(async (resolve, reject) => {
			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/blacklist/getCountBlackList`,
				{ headers: { "Authorization": token } }
			)
				.then(({ data }) => {
					resolve({ OK: true, message: data.message.result });
				})
				.catch(error => {
					console.log("Dashboard.getCountContacts-error:", error);
					reject({ OK: false, message: error });
				});
		});

		const getBalanceAccount = async () => {
			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/twilio/getBalanceAccount`,
				{ headers: { "Authorization": token } }
			)
				.then(({ data }) => {
					setBalanceAccount(data.message.balance + " [" + data.message.currency + "]");
				})
				.catch(error => {
					setBalanceAccount("-");
					switch (error.response.data.message) {
						case "incompleteCredentials":
							setErrorMessage("You haven't saved your credentials.");
							notification("You haven't saved your credentials yet, please save them in order to get your balance account.", "error");
							break;

						case "gettingAccountBalanceError":
							if (error.response.data.error.code === 20003) {
								setErrorMessage("Wrong credentials or negative balance on your Twilio account.");
								notification("We couldn't get your balance account. This usually happens when your Twilio account is suspended or your credentials are incorrect.", "error");
							}
							break;
					}
					console.log("error:", error);
				});
		}

		Promise.all([
			getCountBulkMessages,
			getCountContacts,
			getCountOptOut,
			getCountBulkMessagesByYear(currentYear, false)
		])
			.then((results) => {
				setCountBulkMessages(results[0].message);
				setCountContacts(results[1].message);
				setCountOptOut(results[2].message);
				setMessagesByMonth(results[3].message);
				getBalanceAccount();
			})
			.catch((errors) => {
				setCountBulkMessages("-");
				setCountContacts("-");
				setCountOptOut("-");
				setMessagesByMonth("-");
				notification("Getting stats error, please try again later. If the problem persists contact to support.", "error");
			})
			.finally(() => {
				setIsLoading(false);
			});

	}, []);

	return (
		<MainContainer>
			{
				(isLoading)
					? <Loader />
					:
					<>

						{
							(errorMessage !== "") &&
							<Alert variant="filled" severity="warning">
								{errorMessage}
							</Alert>
						}

						{/* Cards */}
						<Box
							sx={{
								display: "grid",
								gridTemplateColumns: {
									xl: "repeat(4, 1fr)",
									lg: "repeat(4, 1fr)",
									md: "repeat(2, 1fr)",
									sm: "1fr",
									xs: "1fr"
								},
								gridAutoRows: "minmax(120px, auto)",
								gap: 4,
								textAlign: "center",
								flexDirection: "column",
							}}
						>
							<Card
								icon={
									<Sms
										sx={{
											height: "64px",
											width: "64px",
											color: COLORS.light.primary
										}}
									/>
								}
								title={t("Dashboard.cards.countBulkMessages")}
								value={countBulkMessages}
							/>

							<Card
								icon={<ContactPhone
									sx={{
										height: "64px",
										width: "64px",
										color: COLORS.light.primary
									}}
								/>}
								title={t("Dashboard.cards.countContacts")}
								value={countContacts}
							/>

							<Card
								icon={<AppBlocking
									sx={{
										height: "64px",
										width: "64px",
										color: COLORS.light.primary
									}}
								/>}
								title={t("Dashboard.cards.countOptOut")}
								value={countOptOut}
							/>

							<Card
								icon={<Paid
									sx={{
										height: "64px",
										width: "64px",
										color: COLORS.light.primary
									}}
								/>}
								title={t("Dashboard.cards.twilioBalance")}
								value={balanceAccount}
							/>
						</Box>

						{/* Graphics */}
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								gap: 3,
								border: "2px solid #dde0ee",
								borderRadius: "5px"
							}}
						>
							<Box
								sx={{
									// height: "500px",
									padding: "32px",
									width: "100%",
									backgroundColor: "#ffffff",
									borderRadius: "5px"
								}}
							>
								<Typography
									style={{
										color: "#94A4C4",
										fontWeight: "800"
									}}
									variant={"h5"}
								>
									{t("Dashboard.graphics.bulkMesssagesThisYear")}
								</Typography>

								<Box
									sx={{
										paddingY: "16px",
										alignItems: "center",
										alignContent: "center",
										justifyContent: "center",
										display: "flex"
									}}
								>
									<Box
										sx={{
											width: "100%"
										}}
									>
										<LocalizationProvider dateAdapter={AdapterMoment}>
											<DesktopDatePicker
												InputProps={{
													sx: {
														"& .MuiSvgIcon-root": { color: "#94A4C4" }
													}
												}}
												PopperProps={{
													sx: {
														"& .PrivatePickersYear-yearButton.Mui-selected.css-m1gykc": {
															backgroundColor: COLORS.light.primary,
															fontWeight: "bold"
														}
													}
												}}
												views={["year"]}
												label="Year"
												value={currentYear}
												onChange={newYear => {
													getCountBulkMessagesByYear(moment(newYear).format("YYYY"), true);
													setCurrentYear(moment(newYear).format("YYYY"));
												}}
												renderInput={(params) => <TextField {...params} />}
											/>
										</LocalizationProvider>
									</Box>

								</Box>

								<Bar
									options={bulkMessagesOptions}
									data={monthData}
									style={{
										maxHeight: "50vh"
									}}
								/>
							</Box>

						</Box>
					</>
			}
		</MainContainer>
	);
}

export default Dashboard;
