import React from "react";

import { Box, CssBaseline, Toolbar } from "@mui/material";
import AppBarMui from "./AppBarMui";
import DrawerMui from "./DrawerMui";

import background from "../img/background.png";

const MainContainer = (props) => {

  const drawerWidth = 80;

  return (
    <Box sx={{
      display: 'flex',
    }}>
      <AppBarMui />
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 }
        }}
        aria-label="mailbox folders"
      >
        <DrawerMui />
      </Box>
      <Box
        component="main"
        sx={{
          padding: 3,
          width: {
            sm: `calc(100% - ${drawerWidth}px)`,
            xs: `calc(100% - ${drawerWidth}px)`
          },
          display: "flex",
          gap: 3
        }}
        display="flex"
        flexDirection="column"
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}

export default MainContainer;
