import { Button, Link, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../colors";
import notFound from "../img/notFound.png";
import { BUTTON } from "../helpers/styles";
import { useNavigate } from "react-router-dom";

const Forbidden = (props) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundColor: COLORS.light.ternary,
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        gap: "16px"
      }}
    >
      <img
        alt={"Page not found"}
        src={notFound}
        height={"50%"}
      />
      <Typography
        fontWeight={"800"}
        sx={{
          color: COLORS.light.primary
        }}
        variant={"h3"}
      >
        {
          (props.title) ? props.title : "Ooops..."
        }
      </Typography>

      <Typography
        variant={"h5"}
      >
        {
          (props.text) ? props.text : "The page you requested could not be found"
        }
      </Typography>

      <Button
        onClick={() => {
          navigate((props.navigateToPage) ? props.navigateToPage : "/dashboard");
        }}
        size="medium"
        sx={BUTTON.buttonPrimary}
      >
        {
          (props.buttonNavigateText) ? props.buttonNavigateText : "Back to Dashboard"
        }
      </Button>

    </div>
  );
}

export default Forbidden;
