import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalContext";

import SettingsIcon from '@mui/icons-material/Settings';
import { Button, Container, TextField, Typography } from "@mui/material";
import { Cancel, Key, Person, PersonOff, Save } from "@mui/icons-material";

import { notification, VerifyAuth } from "../helpers/Global";

import "../css/Settings.css";
import axios from "axios";
import moment from "moment";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import MainContainer from '../components/MainContainer';
import { COLORS } from "../colors";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

const styles = {
	button: {
		backgroundColor: COLORS.light.primary,
		color: "#ffffff",
		border: "2px solid " + COLORS.light.primary,
		"&:hover": {
			backgroundColor: "transparent",
			color: COLORS.light.primary
		}
	},
	buttonAlert: {
		backgroundColor: COLORS.light.alert,
		color: "#ffffff",
		border: "2px solid " + COLORS.light.alert,
		"&:hover": {
			backgroundColor: "transparent",
			color: COLORS.light.alert
		}
	},
	buttonTest: {
		backgroundColor: COLORS.light.test,
		color: "#ffffff",
		border: "2px solid " + COLORS.light.test,
		"&:hover": {
			backgroundColor: "transparent",
			color: COLORS.light.test
		}
	}
};

const Settings = () => {

	useEffect(() => {
		document.title = "Settings - ACR Digitals";
	}, []);

	return (
		<MainContainer>
			<ProfileSettings />
		</MainContainer>
	);
}

const ProfileSettings = () => {
	const { cookies } = useContext(GlobalContext);
	const token = cookies.get("token");
	const [_id, set_Id] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [accountSid, setAccountSid] = useState("");
	const [authToken, setAuthToken] = useState("");
	const [phone, setPhone] = useState("");
	const [messagingServiceSid, setMessaggingServiceSid] = useState("");
	const [balance, setBalance] = useState(undefined);
	const [currency, setCurrency] = useState(undefined);
	const navigate = useNavigate();
	const [t] = useTranslation("global");

	const [expirationTime, setExpirationTime] = useState(undefined);

	useEffect(() => {
		const verifyAuthAndGetTwilioCredentials = async () => {
			let result = await VerifyAuth(token);
			if (!result.OK) {
				notification("Error getting your information, please log out and log in again. If the problem persists contact to support.", "error");
				return;
			}
			const data = result.message;
			set_Id(data._id);
			setName(data.name);
			setEmail(data.email);
			setExpirationTime(data.expirationTime);

			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/users/getTwilioCredentials`,
				{ headers: { "authorization": token } }
			)
				.then(({ data }) => {
					const twilioAccount = data.message.twilioAccount;
					if (twilioAccount?.accountSid && twilioAccount?.phone && twilioAccount?.messagingServiceSid) {
						setAccountSid(twilioAccount.accountSid);
						setPhone(twilioAccount.phone);
						setMessaggingServiceSid(twilioAccount.messagingServiceSid);
					}
				})
				.catch(error => {
					console.log("Settings.getTwilioCredentials-error:", error);
					if (error.name === "AxiosError" && error.code === "ERR_NETWORK") {
						notification(t("Axios.errors.ERR_NETWORK"), "error");
						return;
					}
					notification("Error getting your Twilio credentials, please try again later. If the problem persists, please contact to support.");
				});
		}
		verifyAuthAndGetTwilioCredentials();
	}, []);

	const onSubmitSettings = async (evt) => {
		evt.preventDefault();
		await axios.put(
			`${process.env.REACT_APP_BASE_URL}/api/users/update`,
			{
				filter: {
					_id
				},
				update: {
					name,
					email,
					password
				}
			},
			{
				headers: {
					"authorization": token
				}
			}
		).then(result => {
			console.log("Settings.updateSettings-result:", result);
			notification("Settings updated.", "success");
		}).catch(error => {
			console.log("Settings.updateSettings-error:", error);
		});
	}

	const onSubmitCredentials = async (evt) => {
		evt.preventDefault();
		switch (evt.nativeEvent.submitter.id) {
			case "testTwilioCredentials":
				setBalance(undefined);
				setCurrency(undefined);
				await axios.get(
					`https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Balance.json`,
					{
						auth: {
							username: accountSid,
							password: authToken
						}
					}
				)
					.then(result => {
						console.log("result:", result);
						setBalance(result.data.balance);
						setCurrency(result.data.currency);
						notification("Your credentials are valid.", "success");
					})
					.catch(error => {
						console.log("Settings.onSubmitCredentials-error:", error);
						notification("Invalid credentials or suspended account, please try again.", "error");
					});
				break;

			case "updateTwilioCredentials":
				await axios.put(
					`${process.env.REACT_APP_BASE_URL}/api/users/updateTwilioCredentials`,
					{ accountSid, authToken, phone, messagingServiceSid },
					{ headers: { "Authorization": token } }
				)
					.then(result => {
						console.log("result:", result);
						notification("Twilio credentials correctly saved.", "success");
					})
					.catch(error => {
						console.log("Settings-onSubmitCredentials.error:", error);
						notification("Error saving Twilio credentials, please try again later. If the problem persists contact to support.", "error");
					})
				break;
		}
	}

	const disableAccount = async () => {
		await axios.put(
			`${process.env.REACT_APP_BASE_URL}/api/users/updateIsActive`,
			{ isActive: false },
			{ headers: { "authorization": token } }
		)
			.then(result => {
				cookies.remove("token", { path: "/" });
				localStorage.clear();
				navigate("/");
				notification("Account succesfully disabled. Come back soon c:", "success");
			})
			.catch(error => {
				console.log("Settings.disableAccount-error:", error);
				notification("Your account can not be be disabled, please try again later.");
			});
	}

	return (
		<Container maxWidth="xl" sx={{ overflow: "auto" }}>

			<Box
				sx={{
					backgroundColor: COLORS.light.primary,
					borderRadius: "5px",
					display: "flex",
					flexDirection: "column"
				}}
			>

				<Box
					sx={{
						backgroundColor: COLORS.light.primary,
						display: "flex",
						borderTopLeftRadius: "5px",
						borderTopRightRadius: "5px",
						paddingX: "16px",
						paddingY: "8px",
						alignItems: "center",
						gap: 2
					}}
				>
					<SettingsIcon sx={{ color: COLORS.light.ternary }} fontSize="large" />
					<Typography
						sx={{
							color: COLORS.light.text
						}}
					>
						Settings
					</Typography>
				</Box>

				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						gap: 2,
						width: "100%",
						backgroundColor: COLORS.light.ternary,
						padding: "16px"
					}}
				>

					{/* Profile */}
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							backgroundColor: COLORS.light.ternary,
							gap: 2,
							width: "100%",
							borderRadius: "5px",
							border: "2px solid " + COLORS.light.primary,
							padding: "16px"
						}}
					>

						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								gap: 1
							}}
						>
							<Person
								sx={{ color: COLORS.light.primary }}
								fontSize="large"
							/>
							<Typography
								variant={"h5"}
							>
								Profile
							</Typography>
						</Box>

						<form
							onSubmit={onSubmitSettings}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: 2
								}}
							>
								<TextField
									fullWidth
									id="name"
									label="Full name"
									name="name"
									onChange={(evt) => {
										setName(evt.target.value);
									}}
									required
									value={name}
								/>

								<TextField
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									onChange={(evt) => {
										setEmail(evt.target.value);
									}}
									required
									value={email}
								/>

								<TextField
									fullWidth
									label="Password"
									id="password"
									name="password"
									onChange={(evt) => {
										setPassword(evt.target.value);
									}}
									required
									type="password"
									value={password}
								/>

								<Button
									startIcon={<Save />}
									sx={styles.button}
									type="submit"
									variant='contained'
								>
									Update Settings
								</Button>
							</Box>

						</form>

						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								gap: 1
							}}
						>

							<PersonOff
								sx={{ color: COLORS.light.primary }}
								fontSize="large"
							/>

							<Typography
								variant={"h5"}
							>
								Disable account
							</Typography>

							<Typography
								textAlign={"left"}
								sx={{
									width: "100%"
								}}
							>
								You can disable your account, but your data will be saved on the database.
							</Typography>

						</Box>

						<ConfirmationDialog
							accepText={"Disable account"}
							acceptButtonColor={"error"}
							accepButtonStartIcon={<PersonOff />}
							cancelButtonStartIcon={<Cancel />}
							onClickAccept={() => { disableAccount(); }}
							startIcon={<PersonOff />}
							text={"Your account will not be deleted, only disabled."}
							textButton={"Disable account"}
							title={"Disable account?"}
							sx={{
								width: "100%",
								...styles.buttonAlert
							}}
						/>

					</Box>

					{/* Twilio credentials */}
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							backgroundColor: COLORS.light.ternary,
							gap: 2,
							width: "100%",
							borderRadius: "5px",
							border: "2px solid " + COLORS.light.primary,
							padding: "16px"
						}}
					>

						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								gap: 1
							}}
						>
							<Key
								sx={{ color: COLORS.light.primary }}
								fontSize="large"
							/>
							<Typography
								variant={"h5"}
							>
								Twilio Credentials
							</Typography>
						</Box>

						<form onSubmit={onSubmitCredentials}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									padding: "0 16px 16px 16px",
									gap: 2
								}}>
								<TextField
									fullWidth
									label="Account SID"
									id="accountSid"
									name="accountSid"
									onChange={(evt) => {
										setAccountSid(evt.target.value);
									}}
									required
									placeholder="ACXXX"
									value={accountSid}
								/>

								<TextField
									fullWidth
									label="Auth Token"
									id="authToken"
									name="authToken"
									onChange={(evt) => {
										setAuthToken(evt.target.value);
									}}
									required
									type={"password"}
									placeholder="12345"
									value={authToken}
								/>

								<TextField
									fullWidth
									label="Twilio Phone Number"
									id="phone"
									name="phone"
									onChange={(evt) => {
										setPhone(evt.target.value);
									}}
									required
									placeholder="+15000000000"
									value={phone}
								/>

								<TextField
									fullWidth
									label="Messaging Service SID"
									id="serviceSid"
									name="serviceSid"
									onChange={(evt) => {
										setMessaggingServiceSid(evt.target.value);
									}}
									required
									placeholder="MGXXX"
									value={messagingServiceSid}
								/>
							</Box>

							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									padding: "0 16px 16px 16px",
									justifyContent: "right",
									gap: 2
								}}
							>
								<Button
									id="testTwilioCredentials"
									sx={styles.buttonTest}
									type="submit"
									variant='contained'
								>
									Test credentials
								</Button>

								<Button
									id="updateTwilioCredentials"
									startIcon={<Save />}
									type="submit"
									variant='contained'
									sx={styles.button}
								>
									Update credentials
								</Button>

								{
									(balance !== undefined && currency !== null) &&
									<div style={{ display: "flex", flexDirection: "row", gap: "15px", alignItems: "center", padding: "16px", backgroundColor: COLORS.light.primary, borderRadius: "5px", color: "#ffffff" }}>
										<Typography>
											Your current twilio balance account is {balance} [{currency}]
										</Typography>
									</div>
								}

								{
									(expirationTime !== undefined && expirationTime !== null) &&
									<div style={{ display: "flex", flexDirection: "row", gap: "15px", alignItems: "center", padding: "16px", backgroundColor: "#d32f2f", borderRadius: "5px", color: "#ffffff" }}>
										<Typography>
											Your trial account will expire at {moment(expirationTime).format("MM/DD/YYYY")}
										</Typography>
									</div>
								}

							</Box>
						</form>

					</Box>

				</Box>
			</Box>
		</Container>
	);
}

export default Settings;
