import React from "react";
import { Tooltip, Zoom } from "@mui/material";

const CustomTooltip = (props) => {
	return (
		<Tooltip placement={(props.placement) && props.placement} title={props.title} TransitionComponent={Zoom}>
			{props.children}
		</Tooltip>
	);
}

export default CustomTooltip;
