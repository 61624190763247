import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { GlobalContext } from "../context/GlobalContext";
import { VerifyAuth, notification } from "../helpers/Global";

import { useTranslation } from "react-i18next";

const ProtectedRoutes = () => {
	const { cookies } = useContext(GlobalContext);
	const [isLoggedIn, setIsLoggedIn] = useState(undefined);
	const token = cookies.get("token");
	const [t] = useTranslation("global");

	useEffect(() => {
		if (token === undefined) {
			setIsLoggedIn(false);
		} else {
			const verifyToken = async () => {
				let result = await VerifyAuth(token);
				if (result.OK) {
					setIsLoggedIn(true);
				} else {
					console.log("ProtectedRoutes.verifyAuth-error:", result);
					switch (result.message) {
						case "ERR_NETWORK":
							notification(t("Axios.errors.ERR_NETWORK"), "error");
							break;
						case "WithoutToken":
							notification(t("Global.VerifyAuth.WithoutToken"), "error");
							break;
						case "TokenExpiredError":
							notification(t("Global.VerifyAuth.TokenExpiredError"), "error");
							cookies.remove("token", { path: "/" });
							localStorage.clear();
							break;
						case "JsonWebTokenError":
							notification(t("Global.VerifyAuth.JsonWebTokenError"), "error");
							cookies.remove("token", { path: "/" });
							localStorage.clear();
							break;
						default:
							notification(t("Global.VerifyAuth.default"), "error");
							cookies.remove("token", { path: "/" });
							localStorage.clear();
							break;
					}
					setIsLoggedIn(false);
				}
			}
			verifyToken();
		}
	}, []);

	return (
		<>
			{
				(isLoggedIn !== undefined && isLoggedIn === true) && <Outlet />
			}
			{
				(isLoggedIn !== undefined && isLoggedIn === false) && <Navigate to={"/login"} />
			}
		</>
	);
}

export default ProtectedRoutes;
