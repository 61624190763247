import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

import { Image, KeyboardReturn, MarkUnreadChatAlt, QuestionAnswer, Send, Star, StarBorder } from "@mui/icons-material";
import { notification } from "../helpers/Global";
import Loader from "../components/Loader";
import { ChatContext, ChatProvider } from "../context/ChatContext";
import CustomTooltip from "../components/CustomTooltip";

import { GlobalContext } from "../context/GlobalContext";
import MainContainer from "../components/MainContainer";
import { Container } from "@mui/system";
import { COLORS } from "../colors";
import ContentContainer from "../components/ContentContainer";

const styles = {
	chats: {
		width: "100%",
		padding: "0 15px",
		color: COLORS.light.ternary,
		position: "relative",
		fontSize: "0.9em",
		overflowY: "scroll",
		height: "400px",
		borderBottom: "1px solid " + COLORS.light.primary
	},
	charCount: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: "15px"
	},
	chatImage: {
		width: "fit-content",
		maxWidth: "100%",
		height: "fit-content",
		maxHeight: "200px"
	},
	myChat: {
		width: "fit-content",
		maxWidth: "60%",
		wordBreak: "break-all",
		backgroundColor: COLORS.light.primary,
		padding: "7px 10px",
		borderRadius: "10px 10px 0 10px",
		margin: "5px 0 5px auto",
		fontSize: "16px",
		lineHeight: "1.4",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end"
	},
	myChatDatetime: {
		fontSize: "12px",
		fontWeight: "lighter",
		width: "100%",
		display: "flex",
		justifyContent: "end",
		marginTop: "4px",
		color: "rgb(255, 255, 255)"
	},
	clientChat: {
		width: "fit-content",
		maxWidth: "60%",
		wordBreak: "break-all",
		backgroundColor: "#4f5d73",
		padding: "7px 10px",
		borderRadius: "10px 10px 10px 0",
		margin: "5px auto 5px 0",
		fontSize: "16px",
		lineHeight: "1.4",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start"
	},
	clientDatetime: {
		fontSize: "12px",
		fontWeight: "lighter",
		width: "100%",
		display: "flex",
		justifyContent: "start",
		marginTop: "4px",
		color: "rgb(255, 255, 255)"
	}
};

const dateFormat = "YYYY/MM/DD";
const dateTimeFormat = dateFormat + " - hh:mm:ss a";

const Chats = () => {

	useEffect(() => {
		document.title = "Chats";
	}, []);

	return (
		<>
			<MainContainer>
				<ChatProvider>
					<ChatDashboard />
				</ChatProvider>
			</MainContainer>
		</>
	);
}

export default Chats;

const ChatDashboard = () => {

	const { idContact } = useParams();

	return (
		<Container maxWidth="xl" sx={{ overflow: "auto" }}>
			{
				(!idContact) ? <InboxDashboard /> : <PhoneChat />
			}
		</Container>
	);
}

const InboxDashboard = () => {

	const { isLoading, setIsLoading, cookies } = useContext(GlobalContext);
	const { setCurrentContact } = useContext(ChatContext);
	const navigate = useNavigate();
	const token = cookies.get("token");

	const columns = [
		{ field: "_id", headerName: "Id", hide: true },
		{
			field: "identity", headerName: "Identity", flex: 0.3, renderCell: (params) => {
				return (
					<div style={{ display: "flex", gap: "15px" }}>
						{
							(params.row.isImportant) ? <Star sx={{ color: COLORS.light.primary }} /> : <StarBorder sx={{ color: COLORS.light.primary }} />
						}
						{params.row.identity}
					</div>
				);
			}
		},
		{ field: "phone", headerName: "Phone", hide: true },
		{ field: "body", headerName: "Last message", flex: 1 },
		{ field: "createdAt", headerName: "Date", flex: 0.4 },
		{ field: "lastMessageReaded", hide: true }
	];

	const [pageNumber, setPageNumber] = useState(1);
	const [nPerPage, setNPerPage] = useState(10);
	const [total, setTotal] = useState(0);
	const [rowData, setRowData] = useState([]);

	const getContactsLastMessage = async () => {
		await axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/contacts/getContactsLastMessage`,
			{ headers: { "authorization": token }, params: { pageNumber, nPerPage } }
		)
			.then(({ data }) => {
				setTotal(data.message.total);
				let contactLastMessage = [];
				const contactList = data.message.result;
				for (let i in contactList) {
					let currentContact = contactList[i];
					let newRow = {};
					newRow._id = currentContact._id;
					if (currentContact.name && currentContact.lastname) {
						newRow.identity = currentContact.name + " " + currentContact.lastname;
					} else {
						newRow.identity = currentContact.phone;
					}
					newRow.isImportant = currentContact.isImportant;
					newRow.phone = currentContact.phone;
					newRow.body = currentContact.lastmessage.body;
					newRow.createdAt = currentContact.lastmessage.createdAt;
					newRow.createdAt = moment(currentContact.lastmessage.createdAt).tz(moment.tz.guess()).format(dateTimeFormat);
					newRow.lastMessageReaded = currentContact.lastMessageReaded;
					contactLastMessage.push(newRow);
				}
				setRowData(contactLastMessage);
			})
			.catch(error => {
				console.log("Chats.getContactsLastMessage-error:", error);
				notification("Error getting chats. Please try again later, if the problem persists contact to support.", "error");
			});
		setIsLoading(false);
	}

	useEffect(() => {
		getContactsLastMessage();
	}, [pageNumber, nPerPage]);

	return (
		<>
			{
				(isLoading)
					? <Loader />
					:
					<>

						<ContentContainer
							leftContent={
								<>
									<QuestionAnswer sx={{ color: COLORS.light.ternary }} />
									<Typography sx={{ color: COLORS.light.text, fontWeight: "800" }}>Chats</Typography>
								</>
							}
						>
							<DataGrid
								autoHeight
								checkboxSelection={false}
								columns={columns}
								disableSelectionOnClick
								getRowClassName={(params) => `super-app-theme--${params.row.lastMessageReaded}`}
								getRowId={(row) => row._id}
								page={pageNumber - 1}
								pageSize={nPerPage}
								pagination
								paginationMode={"server"}
								onPageChange={(newPageNumber) => { setPageNumber(newPageNumber + 1); }}
								onPageSizeChange={(newPageSize) => { setNPerPage(newPageSize); }}
								onRowClick={evt => { setCurrentContact(evt.row); navigate("/chats/" + evt.row._id); }}
								rowsPerPageOptions={[10, 30, 50]}
								rows={rowData}
								rowCount={total}
								sx={{
									width: "100%",
									'& .super-app-theme--false': {
										fontWeight: () => "bold",
										bgcolor: () => "rgb(240, 240, 240)",
										'&:hover': {
											bgcolor: () => "rgb(240, 240, 240)"
										}
									},
									backgroundColor: COLORS.light.ternary
								}}
							/>
						</ContentContainer>

					</>
			}
		</>
	);
}

const PhoneChat = () => {

	const [messages, setMessages] = useState([]);
	const [selectedFile, setSelectedFile] = useState(undefined);
	const [textFieldMessage, setTextFieldMessage] = useState("");
	const { isLoading, setIsLoading, cookies } = useContext(GlobalContext);
	const { currentContact, setCurrentContact, unreadMessagesCount, setUnreadMessagesCount } = useContext(ChatContext);
	const messageLengthLimit = 1600;
	const CHARACTERS_PER_MESSAGE = 160;
	const token = cookies.get("token");
	const navigate = useNavigate();

	const { idContact } = useParams();
	const [messageLength, setMessageLength] = useState(0);

	const messagesEndRef = useRef(null);

	const scrollToBottom = () => {
		if (messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}

	const updateLastMessageReaded = async (_id, lastMessageReaded) => {
		await axios.put(
			`${process.env.REACT_APP_BASE_URL}/api/contacts/updateLastMessageReadedApi`,
			{ _id, lastMessageReaded },
			{ headers: { "authorization": token } }
		)
			.then(result => {
				if (!lastMessageReaded) {
					navigate("/chats");
				}
			})
			.catch(error => {
				console.log("Error updating lastMessageReaded.", error);
				notification("Error updating the read status, please try again later. If the problem persists contact to support.", "error");
			});

	}

	const updateIsImportant = async (isImportant) => {
		await axios.put(
			`${process.env.REACT_APP_BASE_URL}/api/contacts/update`,
			{
				"filter": {
					"_id": currentContact._id
				},
				"update": {
					"isImportant": isImportant
				}
			},
			{ headers: { "authorization": token } }
		).then(result => {
			setCurrentContact(oldCurrentContact => ({ ...oldCurrentContact, isImportant }));
			if (isImportant) {
				notification("Chat marked as important.", "success");
			} else {
				notification("Chat unmarked as important.", "info");
			}
		}).catch(error => {
			console.log("Chats.PhoneChat.updateIsImportan-error:", error);
			notification("Error while updating the important status. Please try again later.", "error");
		});
	}

	const sendMessage = async () => {
		setIsLoading(true);
		let params = {};
		params.body = textFieldMessage;
		params.to = currentContact.phone;

		console.log("selectedFile:", selectedFile);

		if (selectedFile !== undefined) {
			let urlMediaPath = await uploadFile();
			if (urlMediaPath === null) {
				return;
			}
			params.urlMediaPath = urlMediaPath;
			console.log("urlMediaPath:", urlMediaPath);
		}

		console.log("params:", params);

		let messageWasSent = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/contactMessage/sendMessage`,
			params,
			{ headers: { "authorization": token } }
		)
			.then(({ data }) => {
				getContactMessages();
				setIsLoading(false);
				return data;
			})
			.catch(error => {
				console.log("Chats.PhoneChat-sendMessageError:", error);
				setIsLoading(false);
				let response = error.response.data;
				switch (response.message) {

					case "bodyAndPhoneRequired":
						notification("Message body could not be empty.", "error");
						break;

					case "errorGettingContactInfo":
						notification("Error while getting contact info, please try again or contact support.", "error");
						break;

					case "errorSavingContact":
						notification("Error while saving the new contact.", "error");
						break;

					// generalmente estos errores provienen de twilio
					case "errorSendingMessage":
						switch (response.error.code) {
							// error cuadno el numero no es valido
							case 21211:
								notification("The phone number'" + currentContact.phone + "' is not a valid.", "error");
								break;
							default:
								notification("Unexpected error while sending message, please try again or contact support.", "error");
								break;
						}
						break;

					case "errorSavingMessage":
						notification("Your message was sent, but it wasn't recorded on the database.", "error");
						break;

					default:
						notification("Unexpected error, please try again or contact support.", "error");
						break;

				}
				return null;
			});

		if (messageWasSent === null) {
			return;
		}

		notification("Your message was correctly sent.", "success");
		setTextFieldMessage("");
		setSelectedFile(undefined);
	}

	const uploadFile = async () => {

		let url = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/s3/getPostUrl`,
			{ name: selectedFile.name, type: selectedFile.type },
			{ headers: { "authorization": token } }
		)
			.then(({ data }) => {
				console.log("url:", data);
				return { OK: true, message: data.message };
			})
			.catch(error => {
				console.log("Chats-uploadFile.getPostUrlError:", error);
				return error.response.data;
			});

		if (!url.OK) {
			switch (url.message) {

				case "nameAndTypeRequired":
					notification("Application error: name and file type are required, please contact support.", "error");
					break;

				case "errorGettingFileExtension":
					notification("Invalid file name, error while getting the file extension, please try another file or contact support.", "error");
					break;

				case "invalidFileType":
					notification("Invalid file type, only (.jpg, .jpeg, .png and .gif) files are allowed, please try with another file.", "error");
					break;

				case "errorGettingPostUrl":
					notification("S3 error while getting the post url, please try again and if the problem isn't solved contact support.", "error");
					break;

				default:
					notification("Unable to upload image to server. Unknown error while getting post url. Try again or contact support.", "error");
					break;

			}
			return null;
		}

		let imageUrl = null;
		try {
			imageUrl = url.message.uploadUrl.split("?")[0];
		} catch (error) {
			console.log("Chats-PhoneChat.uploadFileError-imageUrl:", error);
			notification("Error trying to get the S3 image url.", "error");
			return null;
		}

		let uploadedFile = await axios(
			{
				method: "put",
				url: url.message.uploadUrl,
				data: selectedFile,
				headers: {
					"Content-Type": selectedFile.type
				}
			}
		)
			.then(result => {
				return { OK: true, message: result }
			})
			.catch(error => {
				console.log("Chats-PhoneChat.uploadFileError-catch:", error);
				return { OK: false, message: error };
			});
		console.log("uploadFile:", uploadedFile);

		if (!uploadedFile.OK) {
			notification("Error while uploading file, please try again later or contact support.", "error");
			return null;
		}

		return imageUrl;
	}

	const getContactById = async () => {
		await axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/contacts/getContactById/${idContact}`,
			{ headers: { "authorization": token } }
		)
			.then(({ data }) => {
				if (data.message.name && data.message.lastname) {
					data.message.identity = data.message.name + " " + data.message.lastname;
				} else {
					data.message.identity = data.message.phone;
				}
				setCurrentContact(data.message);
				if (!data.message.lastMessageReaded) {
					updateLastMessageReaded(data.message._id, true);
				}
			})
			.catch(error => {
				console.log("Chats.getContactById-error:", error);
				notification("Unable to get the contact information, please try again later. If the problem persists contact to support.", "error");
			});
	}

	const getContactMessages = async () => {
		await axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/contactMessage/getAllMessages/${idContact}`,
			{ headers: { "authorization": token } }
		)
			.then(({ data }) => {
				for (let i in data.message) {
					if (data.message[i].messages.urlMediaPath.length > 0) {
						const urlMediaPathArray = data.message[i].messages.urlMediaPath;
						for (let j in urlMediaPathArray) {
							if(!urlMediaPathArray[j].startsWith("https://")){
								urlMediaPathArray[j] = `${process.env.REACT_APP_IMAGE_SERVER}/${urlMediaPathArray[j]}`;
							}
						}
					}
				}
				setMessages(data.message);
			})
			.catch(error => {
				console.log("Chats.getContactMessages-error:", error);
				notification("Unable to get the chat messages, please try again later. If the problem persists contact to support.", "error");
			});
	}

	useEffect(() => {
		setIsLoading(true);
		getContactById();
		getContactMessages();
		setIsLoading(false);
	}, []);

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	return (
		<>
			{
				(isLoading)
					? <Loader />
					:
					<div style={{ width: "100%", border: "1px solid " + COLORS.light.primary, marginBottom: "30px", borderRadius: "5px", backgroundColor: COLORS.light.ternary }}>

						<div style={{ height: "50px", display: "flex", flexDirection: "row", backgroundColor: COLORS.light.primary, width: "100%", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>

							<div style={{ display: "flex", justifyContent: "left", width: "100%" }}>
								<CustomTooltip placement={"top"} title={"Return"}>
									<IconButton style={{ color: "#ffffff" }} size="large" component="label" onClick={() => { navigate("/chats"); }}>
										<KeyboardReturn fontSize="inherit" />
									</IconButton>
								</CustomTooltip>
							</div>

							<div style={{ width: "50%", marginRight: "15px", display: "flex", justifyContent: "right" }}>
								<CustomTooltip placement={"top"} title={"Mark as unread"}>
									<IconButton style={{ color: "#ffffff" }} size="large" component="label" onClick={() => { updateLastMessageReaded(currentContact._id, false); }}>
										<MarkUnreadChatAlt fontSize="inherit" />
									</IconButton>
								</CustomTooltip>
							</div>

						</div>

						<div style={{ width: "100%" }}>
							<div style={{ height: "50px", width: "100%", display: "flex", flexDirection: "row", justifyContent: "left", gap: "10px", alignItems: "center", borderBottom: "1px solid #1976D2", paddingLeft: "10px" }}>
								<div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", gap: "10px" }}>

									<IconButton sx={{ color: COLORS.light.primary }} size="small" onClick={() => { updateIsImportant(!currentContact.isImportant); }}>
										<CustomTooltip placement={"top"} title={(!currentContact.isImportant) ? "Mark as important" : "Unmark as important"}>
											{
												(currentContact.isImportant) ? <Star sx={{ color: COLORS.light.primary }} /> : <StarBorder sx={{ color: COLORS.light.primary }} />
											}
										</CustomTooltip>
									</IconButton>
									<CustomTooltip placement={"top"} title={"Edit contact"}>
										<div style={{ cursor: "pointer" }} onClick={() => { navigate("/contacts/" + currentContact._id); }}>
											<Typography>
												{currentContact.identity}
											</Typography>
										</div>
									</CustomTooltip>

								</div>

							</div>

							<Box id="chats" sx={styles.chats}>
								{
									messages.map(item => (
										<MessageItem
											key={item.messages._id}
											body={item.messages.body}
											class={(item.messages.inbound) ? styles.clientChat : styles.myChat}
											classDate={(item.messages.inbound) ? styles.clientDatetime : styles.myChatDatetime}
											date={moment(item.messages.createdAt).tz(moment.tz.guess()).format(dateTimeFormat)}
											urlMediaPath={item.messages.urlMediaPath}
										/>)
									)
								}
								<div ref={messagesEndRef} />
							</Box>

						</div>

						<div style={{ width: "100%" }}>

							<div style={{ margin: "15px", display: "flex", flexDirection: "column" }}>
								<div>
									<TextField
										id="textFieldMessage"
										autoComplete={"off"}
										inputProps={{ maxLength: 1600 }}
										label="Message"
										maxRows={5}
										multiline
										onChange={(evt) => { setTextFieldMessage(evt.target.value); setMessageLength(evt.target.value.length); }}
										placeholder='Enter your message...'
										style={{ width: "100%" }}
										value={textFieldMessage}
										variant="outlined"
									/>
								</div>
								<Box sx={styles.charCount}>
									<Typography>
										{Math.ceil(messageLength / CHARACTERS_PER_MESSAGE)} SMS, {messageLength}/{messageLengthLimit - messageLength} Characters left
									</Typography>
								</Box>
							</div>

							<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", margin: "15px", gap: "15px", height: "40px" }}>

								{
									(selectedFile) &&
									<Typography
										sx={{
											width: "fit-content"
										}}
									>
										{selectedFile.name}

									</Typography>
								}

								<CustomTooltip title={"Upload an image"} placement={"left"}>
									<IconButton style={{ color: COLORS.light.primary }} size="large" component="label">
										<input type="file" hidden accept="image/jpg, image/jpeg, image/png, image/gif" onChange={evt => { setSelectedFile(evt.target.files[0]); }} />
										<Image fontSize="inherit" />
									</IconButton>
								</CustomTooltip>

								<CustomTooltip placement={"top"} title={"Send message"}>
									<IconButton style={{ color: COLORS.light.primary }} size="large" component="label" onClick={() => { sendMessage(); }}>
										<Send fontSize="inherit" />
									</IconButton>
								</CustomTooltip>
							</div>

						</div>
					</div>
			}
		</>
	);
}

const MessageItem = (props) => {
	return (
		<Box sx={props.class}>
			<Typography>
				{props.body}
			</Typography>
			{
				(props.urlMediaPath.length > 0) &&
				<a href={props.urlMediaPath} target={"_blank"}>
					<img src={props.urlMediaPath} style={styles.chatImage} />
				</a>
			}
			<Typography
				sx={props.classDate}
			>
				{props.date}
			</Typography>
		</Box>
	);
}