import { Check, Close, StarBorder } from "@mui/icons-material";
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CardElement, useElements, useStripe, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import { COLORS } from "../colors";
import MainContainer from "../components/MainContainer";
import { GlobalContext } from "../context/GlobalContext";
import { notification } from "../helpers/Global";
import Forbidden from "./Forbidden";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../components/CheckoutForm";

const styles = {
  listItem: {
    color: COLORS.light.text,
    fontWeight: "500"
  },
  pricingButton: {
    backgroundColor: COLORS.light.ternary,
    color: COLORS.light.primary,
    border: "1px solid " + COLORS.light.primary,
    "&:hover": {
      color: COLORS.light.text,
      backgroundColor: COLORS.light.primary,
      border: "1px solid " + COLORS.light.primary
    }
  },
  pricingButtonPro: {
    backgroundColor: COLORS.light.primary,
    color: COLORS.light.text,
    border: "1px solid " + COLORS.light.primary,
    "&:hover": {
      color: COLORS.light.primary,
      backgroundColor: "transparent",
      border: "1px solid " + COLORS.light.primary
    }
  },
  sendMessage: {
    backgroundColor: "transparent",
    border: "2px solid " + COLORS.light.primary,
    color: COLORS.light.primary,
    "&:hover": {
      backgroundColor: COLORS.light.primary,
      color: COLORS.light.text
    }
  },
  homeTitle: {
    fontWeight: "700",
    textAlign: "center",
    width: "fit-content",
    color: COLORS.light.primary,
  },
  iconPricingStyle: {
    color: COLORS.light.primary
  }
};

const tiers = [
  {
    title: 'Lite',
    price: '1',
    code: 1,
    description: [
      { text: "Unlimited bulk messages", icon: <Check sx={styles.iconPricingStyle} /> },
      { text: "SMS support", icon: <Check sx={styles.iconPricingStyle} /> },
      { text: "MMS support", icon: <Close sx={styles.iconPricingStyle} /> },
      { text: "Per to per chat", icon: <Close sx={styles.iconPricingStyle} /> },
      { text: "Contacts administration module", icon: <Close sx={styles.iconPricingStyle} /> }
    ],
    buttonText: 'Buy'
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '2',
    code: 2,
    description: [
      { text: "Unlimited bulk messages", icon: <Check sx={styles.iconPricingStyle} /> },
      { text: "SMS support", icon: <Check sx={styles.iconPricingStyle} /> },
      { text: "MMS support", icon: <Check sx={styles.iconPricingStyle} /> },
      { text: "Per to per chat", icon: <Close sx={styles.iconPricingStyle} /> },
      { text: "Contacts administration module", icon: <Close sx={styles.iconPricingStyle} /> }
    ],
    buttonText: 'Buy',
  },
  {
    title: 'Enterprise',
    price: '3',
    code: 3,
    description: [
      { text: "Unlimited bulk messages", icon: <Check sx={styles.iconPricingStyle} /> },
      { text: "SMS support", icon: <Check sx={styles.iconPricingStyle} /> },
      { text: "MMS support", icon: <Check sx={styles.iconPricingStyle} /> },
      { text: "Per to per chat", icon: <Check sx={styles.iconPricingStyle} /> },
      { text: "Contacts administration module", icon: <Check sx={styles.iconPricingStyle} /> }
    ],
    buttonText: 'Buy',
  }
];

const Payment = () => {

  const navigate = useNavigate();
  const { code } = useParams();
  const cookies = new Cookies();
  const token = cookies.get("token");

  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    document.title = "Payment";
    setStripePromise(loadStripe(`${process.env.REACT_APP_STRIPE_PK}`));
  }, []);

  const createPaymentIntent = async () => {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/api/stripe/createPaymentIntent`,
      { code: 1 },
      { headers: { "Authorization": token } }
    )
      .then(({ data }) => {
        console.log("res:", data);
        setClientSecret(data.message.client_secret);
      })
      .catch(error => {
        console.log("error:", error);
      });
  }

  return (
    <MainContainer>
      {
        (stripePromise && clientSecret) &&
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm />
        </Elements>
      }

      {
        (!code)
          ?
          <Box
            id="pricing"
            sx={{
              borderRadius: "5px",
              border: "1px solid " + COLORS.light.primary,
              padding: "16px",
              backgroundColor: COLORS.light.ternary
            }}
          >

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%"
              }}
            >
              <Typography
                variant="h2"
                gutterBottom
                sx={styles.homeTitle}
              >
                Pricing
              </Typography>
            </Box>

            <Grid
              alignItems="flex-end"
              container spacing={5}
            >
              {tiers.map((tier) => (
                // Enterprise card is full width at sm breakpoint
                <Grid
                  item
                  key={tier.title}
                  xs={12}
                  sm={tier.title === 'Enterprise' ? 12 : 6}
                  md={4}
                  sx={{
                  }}
                >
                  <Card>
                    <CardHeader
                      title={tier.title}
                      subheader={tier.subheader}
                      titleTypographyProps={{ align: 'center' }}
                      action={tier.title === 'Pro' ? <StarBorder /> : null}
                      subheaderTypographyProps={{
                        align: 'center',
                        color: COLORS.light.text
                      }}
                      sx={{
                        backgroundColor: COLORS.light.primary,
                        color: COLORS.light.text
                      }}
                    />
                    <CardContent>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'baseline',
                          mb: 2,
                        }}
                      >
                        <Typography
                          component="h2"
                          variant="h3"
                          color={COLORS.black}
                        >
                          ${tier.price}
                        </Typography>
                        <Typography
                          variant="h6"
                          color={COLORS.light.secondary}
                        >
                          /mo
                        </Typography>
                      </Box>

                      <Divider
                        sx={{
                          width: "100%",
                          backgroundColor: COLORS.light.primary,
                          margin: "16px 0"
                        }}
                      />

                      {tier.description.map((value, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column"
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 2
                            }}
                          >
                            {value.icon}
                            <Typography
                              variant="subtitle1"
                              align="left"
                              key={index}
                            >
                              {value.text}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </CardContent>
                    <CardActions>
                      <Button
                        fullWidth
                        variant={tier.buttonVariant}
                        sx={(tier.title === "Pro") ? styles.pricingButtonPro : styles.pricingButton}
                        onClick={() => {
                          createPaymentIntent();
                          // navigate(`/payment/${tier.code}`);
                        }}
                      >
                        {tier.buttonText}
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>

          </Box>
          :
          <PlanSelection />
      }
    </MainContainer>
  );
}

const PlanSelection = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  const { code } = useParams();

  useEffect(() => {
    setStripePromise(process.env.REACT_APP_STRIPE_PK);
    console.log(process.env.REACT_APP_STRIPE_PK)
  }, []);

  return (
    <>
      {
        (code !== "1" && code !== "2" && code !== "3")
          ? <Forbidden text={"Invalid item"} navigateToPage={"/payment"} buttonNavigateText={"Return to payment"} />
          :
          <Box
            sx={{
              width: "100%"
            }}
          >
            hola
          </Box>
      }
    </>
  );
}

export default Payment;
