import { Group, GroupAdd } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import React from 'react'

const Card = (props) => {
	return (
		<Paper
			elevation={0}
			sx={{
				padding: 3,
				width: "100%",
				display: "flex",
				justifyContent: "left"
			}}
			style={{
				border: "2px solid #dde0ee",
				borderRadius: "5px"
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "left",
					gap: 2,
					alignItems: "center",
				}}
			>
				{
					props.icon
				}

				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography
						align={"left"}
						fontWeight={"800"}
						fontSize={"1.3em"}
						style={{
							color: "#94A4C4",
							whiteSpace: "nowrap"
						}}
					>
						{props.title}
					</Typography>

					<Box
						sx={{
							display: "flex"
						}}
					>
						<Typography
							align={"left"}
							fontWeight={"500"}
							fontSize={"1.4em"}
						>
							{props.value}
						</Typography>

						{
							(props.subValue) &&
							<Typography
								align={"left"}
								fontWeight={"500"}
								fontSize={"1.4em"}
							>
								{props.subValue}
							</Typography>
						}
					</Box>

				</Box>

			</Box>
		</Paper >

	);
}

export default Card;
