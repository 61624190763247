import React, { createContext, useState } from "react";

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
	const [currentContact, setCurrentContact] = useState({});
	const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

	return (
		<ChatContext.Provider value={{
			currentContact, setCurrentContact,
			unreadMessagesCount, setUnreadMessagesCount
		}}
		>
			{children}
		</ChatContext.Provider>
	);
}
