import React from 'react';
import { GridLoader } from "react-spinners";
import { COLORS } from '../colors';

const Loader = () => {
	return (
		<>
			<div style={{ height: "100vh", width: "100vw", position: "fixed", top: 0, left: 0, backgroundColor: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
				<GridLoader
					color={COLORS.light.primary}
					loading={true}
					size={30}
					speedMultiplier={0.7}
				/>
			</div>
		</>
	);
}

export default Loader;