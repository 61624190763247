export const COLORS = {
	light: {
		primary: "#38abae",
		secondary: "#000000",
		ternary: "#ffffff",
		text: "#ffffff",
		alert: "#d32f2f",
		test: "#29bb4f"
	},
	dark: {
		primary: ""
	},
};