import { Assessment, ContactPhone, Create, Dashboard, FormatListBulleted, GitHub, Group, Home, Payment, PhonelinkErase, QueryStats, QuestionAnswer, Settings, Sms } from "@mui/icons-material";
import { Drawer, List, ListItem, ListItemButton, ListItemIcon } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { COLORS } from "../colors";
import CustomTooltip from "./CustomTooltip";

import { useTranslation } from "react-i18next";

const DrawerMui = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [t] = useTranslation("global");

	const drawerWidth = 80;

	const drawerItems = [
		{ text: t("DrawerMui.home"), path: "/", icon: <Home /> },
		{ text: t("DrawerMui.dashboard"), path: "/dashboard", icon: <Dashboard /> },
		{ text: t("DrawerMui.bulkSms"), path: "/bulk", icon: <Create /> },
		{ text: t("DrawerMui.bulkCustomSms"), path: "/bulkcustom", icon: <FormatListBulleted /> },
		{ text: t("DrawerMui.bulkSmsSent"), path: "/list", icon: <Sms /> },
		{ text: t("DrawerMui.chats"), path: "/chats", icon: <QuestionAnswer /> },
		{ text: t("DrawerMui.contacts"), path: "/contacts", icon: <ContactPhone /> },
		{ text: t("DrawerMui.stats"), path: "/stats", icon: <QueryStats /> },
		{ text: t("DrawerMui.blacklist"), path: "/blacklist", icon: <PhonelinkErase /> },
		{ text: "Payment", path: "/payment", icon: <Payment /> },
		{ text: "Logs", path: "/logs", icon: <Assessment /> },
		{ text: t("DrawerMui.settings"), path: "/settings", icon: <Settings /> }
	];

	return (
		<Drawer
			sx={{
				width: drawerWidth,
				'& .MuiDrawer-paper': {
					width: drawerWidth,
					boxSizing: 'border-box',
				},
			}}
			variant="permanent"
			anchor="left"
		>
			<List
				sx={{
					// normal
					'& .MuiListItemButton-root': {
						bgcolor: COLORS.light.ternary,
						'&, & .MuiListItemIcon-root': {
							color: COLORS.light.primary,
						},
						transition: "none",
					},
					// hover
					'& .MuiListItemButton-root:hover': {
						bgcolor: COLORS.light.primary,
						'&, & .MuiListItemIcon-root': {
							color: COLORS.light.ternary,
						},
					}
				}}
			>

				{
					drawerItems.map((value, index) => (
						<ListItem
							key={index}
							disablePadding
							onClick={() => {
								if (value.path === location.pathname) {
									// si es la misma ruta se recarga
									navigate(0);
								} else {
									// sino se dirige a la ruta elegida
									navigate(value.path);
								}
							}}
							sx={{
								height: "48px"
							}}
						>
							<CustomTooltip
								placement={"right"}
								title={value.text}
							>

								<ListItemButton
									sx={{
										justifyContent: "center",
									}}
								>

									<ListItemIcon
										sx={{
											justifyContent: "center"
										}}
									>
										{value.icon}
									</ListItemIcon>

								</ListItemButton>

							</CustomTooltip>
						</ListItem>
					))
				}
			</List>

		</Drawer>
	);
}

export default DrawerMui;
