import { Download, PhonelinkErase } from '@mui/icons-material';
import { Container, IconButton, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import moment from "moment-timezone";

import { notification } from "../helpers/Global";
import Loader from '../components/Loader';
import { GlobalContext } from '../context/GlobalContext';
import CustomTooltip from '../components/CustomTooltip';

import "../css/BlackList.css";
import MainContainer from '../components/MainContainer';
import { COLORS } from '../colors';
import { download, generateCsv, mkConfig } from 'export-to-csv';

const dateFormat = "YYYY/MM/DD";
const dateTimeFormat = dateFormat + " - hh:mm:ss a";

const BlackList = () => {

	const { setIsLoading } = useContext(GlobalContext);

	useEffect(() => {
		document.title = "Blacklist - ACR Digitals";
		setIsLoading(false);
	}, []);

	return (
		<MainContainer>
			<BlackListTable />
		</MainContainer>
	);
}

export default BlackList;

const BlackListTable = () => {
	const columns = [
		{ field: "_id", headerName: "Id", hide: true },
		{ field: "number", headerName: "Number", flex: 1 },
		{ field: "createdAt", headerName: "Date", flex: 1 }
	];
	const { cookies, isLoading, setIsLoading } = useContext(GlobalContext);
	const token = cookies.get("token");
	const [pageNumber, setPageNumber] = useState(1);
	const [nPerPage, setNPerPage] = useState(10);
	const [total, setTotal] = useState(0);
	const [rowData, setRowData] = useState([]);
	const filename = "blacklist-" + moment().format("YYYYMMDDhhmmss");

	const getBlackList = async () => {
		setIsLoading(true);
		await axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/blacklist/getBlackList`,
			{ headers: { "authorization": token }, params: { pageNumber, nPerPage } }
		).then(({ data }) => {
			setTotal(data.message.total);
			const blacklist = data.message.result;
			for (let i in blacklist) {
				blacklist[i].createdAt = moment(blacklist[i].createdAt).tz(moment.tz.guess()).format(dateTimeFormat);
			}

			setRowData(blacklist);

		}).catch(error => {
			console.log(error);
			if (error.code === "ERR_NETWORK" || error.message === "Network Error") {
				notification("We can't connect to the server. Check your internet connection, if this doesn't work, please contact to support.", "error");
			}
		}).finally(() => {
			setIsLoading(false);
		});
	}

	const exportBlackList = async () => {
		setIsLoading(true);

		await axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/blacklist/getBlackList`,
			{ headers: { "authorization": token } }
		)
			.then(({ data }) => {
				const blacklist = data.message.result;
				for (let i in blacklist) {
					blacklist[i].createdAt = moment(blacklist[i].createdAt).tz(moment.tz.guess()).format(dateTimeFormat);
				}

				const results = (blacklist.map(element => ({
					"Phone number": element.number,
					"Opt-Out Date": element.createdAt
				})));

				const csvConfig = mkConfig({ useKeysAsHeaders: true, filename });
				const csv = generateCsv(csvConfig)(results);
				download(csvConfig)(csv);
			})
			.catch((error) => {
				console.log(error);
				notification("We can't export the blacklist right now, please try again later.", "error");
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	useEffect(() => {
		getBlackList();
	}, [pageNumber, nPerPage]);

	return (
		<>
			{
				(isLoading)
					? <Loader />
					:
					<Container maxWidth="xl" sx={{ overflow: "auto" }}>
						<div style={{ height: "50px", display: "flex", flexDirection: "row", backgroundColor: COLORS.light.primary, width: "100%", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>

							<div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", gap: "10px", paddingLeft: "16px" }}>
								<PhonelinkErase sx={{ color: COLORS.light.ternary }} />
								<Typography sx={{ color: COLORS.light.text, fontWeight: "800" }}>BlackList</Typography>
							</div>

							<div style={{ display: "flex", justifyContent: "right", width: "100%" }}>
								<CustomTooltip placement={"top"} title={"Export blacklist"}>
									<IconButton
										style={{ color: "#ffffff" }}
										size="large"
										component="label"
										onClick={exportBlackList}
									>
										<Download fontSize="inherit" />
									</IconButton>
								</CustomTooltip>
							</div>

						</div>

						<DataGrid
							autoHeight
							columns={columns}
							disableSelectionOnClick
							getRowId={(row) => row._id}
							initialState={{
								sorting: {
									sortModel: [{ field: "date", sort: "desc" }]
								}
							}}
							onPageChange={(newPageNumber) => { setPageNumber(newPageNumber + 1); console.log("sss") }}
							onPageSizeChange={(newPageSize) => { setNPerPage(newPageSize); }}
							page={pageNumber - 1}
							pageSize={nPerPage}
							pagination
							paginationMode={"server"}
							rows={rowData}
							rowCount={total}
							rowsPerPageOptions={[10, 30, 50]}
							sx={{
								border: "1px solid " + COLORS.light.primary,
								borderRadius: "5px",
								marginBottom: "30px",
								borderTopLeftRadius: "0px",
								borderTopRightRadius: "0px",
								backgroundColor: COLORS.light.ternary
							}}
						/>
					</Container>
			}
		</>
	);
}