import { Mms, Sms } from "@mui/icons-material";
import { Box, Container } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { COLORS } from "../colors";
import Loader from "../components/Loader";
import MainContainer from "../components/MainContainer";
import { GlobalContext } from "../context/GlobalContext";
import { notification } from "../helpers/Global";
import moment from "moment-timezone";

const dateFormat = "YYYY-MM-DD";
const dateTimeFormat = dateFormat + " - hh:mm:ss a";

const Logs = () => {

	const [logData, setLogData] = useState([]);
	const { cookies, isLoading, setIsLoading } = useContext(GlobalContext);
	const token = cookies.get("token");

	const [pageNumber, setPageNumber] = useState(1);
	const [nPerPage, setNPerPage] = useState(10);
	const [total, setTotal] = useState(0);

	const logColumns = [
		{ field: "_id", headerName: "Id", hide: true },
		{ field: "createdAt", headerName: "Date", flex: 1 },
		{ field: "messagingServiceSid", headerName: "Service", flex: 1 },
		{ field: "From", headerName: "From", flex: 1 },
		{ field: "To", headerName: "To", flex: 1 },
		{ field: "MessageStatus", headerName: "Status", flex: 1 },
		{ field: "body", headerName: "Body", flex: 1 },
		{
			field: "mediaUrl", headerName: "SMS / MMS", renderCell: (params) => {
				return (
					<Box sx={{
						display: "flex",
						alignItems: "center",
						gap: "15px",
						justifyContent: "center",
						width: "100%"
					}}>
						<Sms sx={{ color: COLORS.light.primary }} />
						{(params.row.mediaUrl) && <Mms sx={{ color: COLORS.light.primary }} />}
					</Box>
				);
			}
		}
	];

	const getMessages = async () => {
		setIsLoading(true);
		await axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/sms/getMessages`,
			{ headers: { "Authorization": token }, params: { pageNumber, nPerPage } }
		)
			.then(({ data }) => {
				console.log(data)
				setTotal(data.message.total);
				const messagesList = data.message.result;

				for (let i in messagesList) {
					messagesList[i].createdAt = moment(messagesList[i].createdAt).tz(moment.tz.guess()).format(dateTimeFormat);
				}

				setLogData(data.message.result);
			})
			.catch(error => {
				notification("error");
			});
		setIsLoading(false);
	}

	useEffect(() => {
		getMessages();
	}, [pageNumber, nPerPage]);

	return (
		<>
			<MainContainer>
				{
					(isLoading)
						?
						<Loader />
						:
						<Container maxWidth={"xl"}>
							<Box
								sx={{
									width: "100%",
									backgroundColor: "yellow"
								}}
							>
								<DataGrid
									autoHeight
									getRowId={(row) => row._id}
									columns={logColumns}
									onPageChange={(newPageNumber) => { setPageNumber(newPageNumber + 1); }}
									onPageSizeChange={(newPageSize) => { setNPerPage(newPageSize); }}
									page={pageNumber - 1}
									pageSize={nPerPage}
									pagination
									paginationMode={"server"}
									rows={logData}
									rowCount={total}
									rowsPerPageOptions={[10, 30, 50]}
									sx={{
										backgroundColor: COLORS.light.ternary
									}}
								/>
							</Box>
						</Container>
				}
			</MainContainer>
		</>
	);
}

export default Logs;
