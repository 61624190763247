import { COLORS } from "../colors";

export const BUTTON = {
  buttonPrimary: {
    backgroundColor: COLORS.light.primary,
    color: COLORS.light.text,
    border: "2px solid " + COLORS.light.primary,
    "&:hover": {
      color: COLORS.light.primary,
      backgroundColor: "transparent",
    }
  }
}