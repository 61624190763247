import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useState } from "react";

const ConfirmationDialog = (props) => {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Button
				variant='contained'
				size={(props.size) ? props.size : "medium"}
				// color="error"
				onClick={handleOpen}
				startIcon={props.startIcon}
				sx={
					(props.sx) && props.sx
				}
			>
				{props.textButton}
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
			>
				<DialogTitle>
					{props.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{props.text}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{/* Accept button */}
					<Button
						color={(props.acceptButtonColor) ? props.acceptButtonColor : "primary"}
						onClick={() => { props.onClickAccept(); handleClose(); }}
						startIcon={(props.accepButtonStartIcon) && props.accepButtonStartIcon}
						variant='contained'
					>
						{(props.accepText) ? props.accepText : "Accept"}
					</Button>
					{/* Cancel button */}
					<Button
						color={(props.cancelButtonColor) ? props.cancelButtonColor : "primary"}
						onClick={handleClose}
						startIcon={(props.cancelButtonStartIcon) && props.cancelButtonStartIcon}
						variant='contained'
					>
						{(props.cancelText) ? props.cancelText : "Cancel"}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default ConfirmationDialog;
