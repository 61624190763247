import { AccountCircle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { COLORS } from "../colors";

const ContentContainer = (props) => {
	return (
		<Box
			sx={{
				backgroundColor: COLORS.light.ternary,
				border: "1px solid " + COLORS.light.primary,
				borderRadius: "5px",
				marginBottom: "30px",
				width: "100%"
			}}>
			<Box
				sx={{
					width: "100%"
				}}
			>
				{/* header */}
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
						justifyContent: "left",
						gap: 2,
						height: "48px",
						alignItems: "center",
						padding: "8px 16px",
						backgroundColor: COLORS.light.primary
					}}>

					{/* left content */}
					<Box
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "left",
							alignItems: "center",
							gap: 2,
						}}>
						{
							(props.leftContent) && props.leftContent
						}
					</Box>

					{/* right content */}
					<Box
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: "row",
							justifyContent: "right",
							alignItems: "center",
							gap: 2
						}}>

						{
							(props.rightContent) && props.rightContent
						}
					</Box>

				</Box>

				{/* body */}
				{props.children}

			</Box>

		</Box>
	);
}

export default ContentContainer;
