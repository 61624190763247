import { LockOpen, PersonAdd } from "@mui/icons-material";
import { Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notification, VerifyAuth } from "../helpers/Global";

import 'react-toastify/dist/ReactToastify.css';
import Loader from "../components/Loader";
import "../css/Login.css";
import { GlobalContext } from "../context/GlobalContext";

import { useTranslation } from "react-i18next";
import { BUTTON } from "../helpers/styles";

const Login = () => {
	const TIME_TO_EXPIRE = new Date().getTime() + 7 * 24 * 60 * 60 * 1000; // expire cookie in 7 days
	const navigate = useNavigate();
	const { cookies } = useContext(GlobalContext);

	const [isLoading, setIsLoading] = useState(true);

	const token = cookies.get("token");

	const [inputs, setInputs] = useState({ email: "", password: "" });
	const { email, password } = inputs;
	const [timeZone, setTimeZone] = useState("");
	const [t] = useTranslation("global");

	useEffect(() => {
		document.title = t("Login.title");
		if (token !== undefined) {
			const verifyToken = async () => {
				let result = await VerifyAuth(token);
				if (result.OK) {
					navigate("/dashboard");
				}
				else {
					console.log("Login.verifyToken-error:", result);
					switch (result.message) {
						case "ERR_NETWORK":
							notification(t("Axios.errors.ERR_NETWORK"), "error");
							break;
						case "WithoutToken":
							notification(t("Global.VerifyAuth.WithoutToken"), "error");
							break;
						case "TokenExpiredError":
							notification(t("Global.VerifyAuth.TokenExpiredError"), "error");
							cookies.remove("token", { path: "/" });
							localStorage.clear();
							break;
						case "JsonWebTokenError":
							notification(t("Global.VerifyAuth.JsonWebTokenError"), "error");
							cookies.remove("token", { path: "/" });
							localStorage.clear();
							break;
						default:
							notification(t("Global.VerifyAuth.default"), "error");
							cookies.remove("token", { path: "/" });
							localStorage.clear();
							break;
					}
				}
			}
			verifyToken();
		}
		setIsLoading(false);
	}, []);

	const onChange = (evt) => {
		setInputs({ ...inputs, [evt.target.name]: evt.target.value });
	}

	const startSession = async (evt) => {
		evt.preventDefault();
		setIsLoading(true);
		await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/users/login`,
			{ email, password, timeZone },
			{ headers: { "authorization": token } }
		).then(async ({ data }) => {
			console.log("login:", data);
			setIsLoading(false);

			if (!data.message.isActive) {
				await axios.put(
					`${process.env.REACT_APP_BASE_URL}/api/users/updateIsActive`,
					{ isActive: true },
					{ headers: { "authorization": data.message.token } }
				)
					.then(result => {
						notification(t("Login.startSession.accountEnabled"), "success");
						return;
					})
					.catch(error => {
						console.log("Settings.disableAccount-error:", error);
						notification(t("Login.startSession.accountEnabledError"));
						return;
					});
			}
			cookies.set("token", data.message.token, { maxAge: TIME_TO_EXPIRE, path: "/", sameSite: "strict" });

			localStorage.setItem("userInfo", JSON.stringify({
				email: data.message.email,
				expirationTime: data.message.expirationTime,
				name: data.message.name
			}));

			navigate("/dashboard");

		}).catch((error) => {
			setIsLoading(false);
			console.log("Login.onSubmit-error:", error);
			if (error.name === "AxiosError" && error.code === "ERR_NETWORK") {
				notification(t("Axios.errors.ERR_NETWORK"), "error");
				return;
			}

			switch (error.response.data.message) {
				case "userNotFound":
					notification(t("Login.errors.userNotFound"), "error");
					break;

				case "invalidPassword":
					notification(t("Login.errors.invalidPassword"), "error");
					break;

				case "bcryptError":
					notification(t("Login.errors.bcryptError"), "error");
					break;

				case "gettingUserError":
					notification(t("Login.errors.gettingUserError"), "error");
					break;

				default:
					notification(t("Login.errors.default"), "error");
					break;
			}
		});
	}

	return (
		<>
			{
				(isLoading)
					?
					<Loader />
					:
					<>
						<div className="container-login">
							<div className="login-form">
								<div className="login-form-left">

									<div className="login-form-left-title">
										<Typography variant="h5" fontWeight={"bold"}>
											{t("Login.form.welcomeBack")}
										</Typography>
										<Typography>
											{t("Login.form.enterCredentials")}
										</Typography>
									</div>

									<form>
										<div className="login-form-left-fields">
											<div className="login-textfield">
												<TextField
													required
													fullWidth
													id="email"
													label={t("Login.form.email")}
													name="email"
													autoComplete="email"
													onChange={(evt) => onChange(evt)}
													value={email}
												/>
											</div>

											<div className="login-textfield">
												<TextField
													required
													fullWidth
													name="password"
													label={t("Login.form.password")}
													type="password"
													id="password"
													autoComplete="password"
													onChange={(evt) => onChange(evt)}
													sx={{
														borderRadius: "8px"
													}}
													value={password}
												/>
											</div>

											<Button
												className={"login-button"}
												fullWidth
												onClick={(evt) => { startSession(evt); }}
												startIcon={<LockOpen />}
												sx={BUTTON.buttonPrimary}
												type={"submit"}
											>
												{t("Login.form.loginButton")}
											</Button>

											<Typography
												sx={{
													textAlign: "center"
												}}
											>
												{t("Login.form.or")}
											</Typography>

											<Button
												className={"login-button"}
												fullWidth
												onClick={() => { navigate("/signup"); }}
												startIcon={<PersonAdd />}
												sx={BUTTON.buttonPrimary}
											>
												{t("Login.form.signupButton")}
											</Button>
										</div>
									</form>

								</div>

								<div className="login-form-right">
									<div className="login-form-right-container">
										<Typography variant="h5">
											{t("Login.modernInterface")}
										</Typography>
									</div>
								</div>
							</div>
						</div>
					</>
			}

		</>
	);
}

export default Login;