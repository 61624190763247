import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Container } from '@mui/system';
import { Alert, Box, Button, Checkbox, Divider, FormControlLabel, FormGroup, IconButton, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { FileUpload, Delete, Paid, People, AttachFile, Create, ExitToApp, Link, CloudUpload, KeyboardReturn } from '@mui/icons-material';
import * as XLSX from "xlsx";
import Papa from "papaparse";

import { GlobalContext } from "../context/GlobalContext";
import { notification, uploadFile, verifyE164Format, verifyValidFileExtension } from "../helpers/Global";
import MainContainer from "../components/MainContainer";
import { COLORS } from "../colors";

import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import CustomTooltip from "../components/CustomTooltip";
import Card from "../components/Card";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

const styles = {
	button: {
		backgroundColor: COLORS.light.primary,
		color: "#ffffff",
		border: "2px solid " + COLORS.light.primary,
		"&:hover": {
			backgroundColor: "transparent",
			color: COLORS.light.primary
		}
	},
	buttonAlert: {
		backgroundColor: COLORS.light.alert,
		color: "#ffffff",
		border: "2px solid " + COLORS.light.alert,
		"&:hover": {
			backgroundColor: "transparent",
			color: COLORS.light.alert
		}
	},
	buttonTest: {
		backgroundColor: COLORS.light.test,
		color: "#ffffff",
		border: "2px solid " + COLORS.light.test,
		"&:hover": {
			backgroundColor: "transparent",
			color: COLORS.light.test
		}
	}
};

const BulkSms = () => {
	const { cookies, isLoading, setIsLoading } = useContext(GlobalContext);
	const token = cookies.get("token");
	const [activeStep, setActiveStep] = useState(0);
	const [t] = useTranslation("global");
	const [addCountryCode, setAddCountryCode] = useState(false);
	const [addContactWay, setAddContactWay] = useState("showButtons");
	const [selectedFile, setSelectedFile] = useState(undefined);

	const [textareaSubject, setTextareaSubject] = useState("");
	const [textareaList, setTextareaList] = useState("");
	const [textareaMessage, setTextareaMessage] = useState("");
	const [imageLink, setImageLink] = useState("");
	const [addImageWay, setAddImageWay] = useState("");
	const [balanceAccount, setBalanceAccount] = useState(0);
	const [currencyAccount, setCurrencyAccount] = useState("");
	const [newBalanceAccount, setNewBalanceAccount] = useState(0);
	const [fullfilled, setFullfilled] = useState([]);
	const [rejected, setRejected] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");

	const columnsValidPhones = [
		{ field: "id", headerName: "Id", hide: true },
		{ field: "phone", headerName: "Phone", flex: 1 },
	];

	const columnsInvalidPhones = [
		{ field: "id", headerName: "Id", hide: true },
		{ field: "phone", headerName: "Phone", flex: 0.5 },
		{ field: "reason", headerName: "Reason", flex: 1 }
	];

	const columnsFullfilled = [
		{ field: "id", headerName: "Id", hide: true },
		{ field: "to", headerName: "Number", flex: 1 },
		{ field: "status", headerName: "Status", flex: 1 }
	];

	const columnsRejected = [
		{ field: "id", headerName: "Id", hide: true },
		{ field: "to", headerName: "Number", flex: 0.5 },
		{ field: "errorCode", headerName: "Error Code", flex: 0.5 },
		{ field: "reason", headerName: "Reason", flex: 1 }
	];

	const [validPhones, setValidPhones] = useState([]);
	const [invalidPhones, setInvalidPhones] = useState([]);
	const [blacklistPhones, setBlacklistPhones] = useState([]);
	const [messageLength, setMessageLength] = useState(0);
	const navigate = useNavigate();

	const [isMMS, setIsMMS] = useState(false);
	const MESSAGE_LENGTH_LIMIT = 1600;
	const CHARACTERS_PER_MESSAGE = 160;
	const SMS_COST = 0.0079;
	const MMS_COST = 0.02;
	const MMS_MAX_FILE_SIZE = 5;

	const readExcelFile = (reader, file, valids, invalids) => {
		reader.readAsBinaryString(file);

		reader.onload = (evt) => {
			const workBook = XLSX.read(evt.target.result, { type: "binary" });
			const workSheetName = workBook.SheetNames[0];
			const workSheet = workBook.Sheets[workSheetName];
			const column = XLSX.utils.sheet_to_json(workSheet, { header: 1 });

			for (let i in column) {
				let tempRow = column[i][0].toString();

				if (tempRow === "") {
					continue;
				}

				if (addCountryCode) {
					tempRow = "1" + tempRow;
				}

				if (!tempRow.startsWith("+")) {
					tempRow = "+" + tempRow;
				}

				if (verifyE164Format(tempRow)) {
					valids.push({ id: i, phone: tempRow });
				} else {
					invalids.push({ id: i, phone: tempRow, reason: "Phones should be on E164 format." });
				}

			}

			if (valids.length < 1) {
				notification("Not valid phone numbers found on " + file.name + " file. Phone numbers should be on E164 format.", "error");
			}

			setValidPhones(valids);
			setInvalidPhones(invalids);
		}

		reader.onerror = () => {
			notification("Error while reading " + file.name + " file, please select another file or try again. If the problem persists contact to support");
		}

	}

	const loadPhonesFromFile = async (evt) => {
		setIsLoading(true);

		evt.preventDefault();
		let file = evt.target.files[0];

		// verifica si el archivo no es undefined
		if (file?.name === undefined) {
			notification("No file selected.", "error");
			return;
		}

		// revisa la extension del archivo
		const fileExtension = file.name.split(".").pop();
		const validExtensions = ["csv", "xls", "xlsx", "txt"];

		if (!validExtensions.includes(fileExtension)) {
			notification("You've provided a file with extension: '" + fileExtension + "'. Only csv, xls, xslx or txt files are allowed.", "error");
			return;
		}

		setValidPhones([]);
		setInvalidPhones([]);

		const reader = new FileReader();

		let valids = [];
		let invalids = [];

		switch (fileExtension) {
			case "csv":
				reader.readAsText(file);

				reader.onload = () => {
					Papa.parse(reader.result, {
						skipEmptyLines: true,
						complete: (results) => {
							for (let i in results.data) {
								const row = results.data[i][0];
								let tempRow = row.replaceAll(/[^0-9\n]/g, " ");
								tempRow = tempRow.replaceAll(/\s+/g, "");

								if (tempRow === "") {
									continue;
								}

								if (addCountryCode) {
									tempRow = "1" + tempRow;
								}

								if (!tempRow.startsWith("+")) {
									tempRow = "+" + tempRow;
								}

								if (verifyE164Format(tempRow)) {
									valids.push({ id: i, phone: tempRow });
								} else {
									invalids.push({ id: i, phone: tempRow, reason: "Phones should be on E164 format." });
								}

							}

							if (valids.length < 1) {
								notification("Not valid phone numbers found on " + file.name + " file. Phone numbers should be on the first columnd and be on E164 format.", "error");
							}

							setValidPhones(valids);
							setInvalidPhones(invalids);
						},
						error: (error) => {
							console.log("BulkSms.loadPhonesFromFile-onload:", error);
							notification("Error getting the csv data, please try again later. If the problem persists contact to support.", "error");
						}
					})
				};

				reader.onerror = () => {
					console.log("BulkSms.loadPhonesFromFile-onerror:", reader.error);
					notification("Error reading csv file, please try again later. If the problem persists contact to support.", "error");
				}

				break;

			case "txt":
				reader.readAsText(file);

				reader.onload = () => {
					let tempList = reader.result.split("\n");
					for (let i in tempList) {
						let tempRow = tempList[i].replaceAll(/[^0-9\n]/g, " ");
						tempRow = tempRow.replaceAll(/\s+/g, "");

						if (tempRow === "") {
							continue;
						}

						if (addCountryCode) {
							tempRow = "1" + tempRow;
						}

						if (!tempRow.startsWith("+")) {
							tempRow = "+" + tempRow;
						}

						if (verifyE164Format(tempRow)) {
							valids.push({ id: i, phone: tempRow });
						} else {
							invalids.push({ id: i, phone: tempRow, reason: "Phones should be on E164 format." });
						}

					}

					if (valids.length < 1) {
						notification("Not valid phone numbers found on " + file.name + " file. Phone numbers should be on E164 format.", "error");
					}

					setValidPhones(valids);
					setInvalidPhones(invalids);

				}

				reader.onerror = () => {
					console.log("BulkSms.loadPhonesFromFile.txt-error:", reader.error);
					notification("Reading txt file error, please try again. If the problem persists contact to support.", "error");
				}

				break;

			case "xls":
				readExcelFile(reader, file, valids, invalids);
				break;

			case "xlsx":
				readExcelFile(reader, file, valids, invalids);
				break;
		}

		evt.target.value = null;
		setIsLoading(false);
	}

	const loadPhonesFromTextArea = () => {
		setIsLoading(true);

		let tempList = textareaList.split("\n");
		let valids = [];
		let invalids = [];
		let tempNumber = "";

		for (let i in tempList) {
			tempNumber = tempList[i].replaceAll(/[^0-9]/g, "");
			if (tempNumber !== "" || tempNumber.length !== 0) {

				if (addCountryCode) {
					tempNumber = "1" + tempNumber;
				}

				if (!tempNumber.startsWith("+")) {
					tempNumber = "+" + tempNumber;
				}

				if (verifyE164Format(tempNumber)) {
					valids.push({ id: i, phone: tempNumber });
				} else {
					invalids.push({ id: i, phone: tempNumber, reason: "Phones should be on E164 format." });
				}
			}
		}

		if (valids.length < 1) {
			notification("Not valid phone numbers found in the textarea. Phone numbers should be on the first columnd and be on E164 format.", "error");
			setIsLoading(false);
			return;
		}

		setValidPhones(valids);
		setInvalidPhones(invalids);
		setIsLoading(false);
	}

	const isOnBlackList = async () => {
		setIsLoading(true);
		const numberList = [];
		const blacklist = [];

		for (let i in validPhones) {
			numberList.push(validPhones[i].phone);
		}

		await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/blacklist/isOnBlackList`,
			{ numberList },
			{ headers: { "authorization": token } }
		)
			.then(({ data }) => {

				for (let i in data.message) {
					blacklist.push({ id: i, phone: data.message[i].number, reason: "Is on the black list." });
				}

				setBlacklistPhones(blacklist);
				if (blacklist.length === 0) {
					notification("There is no phone numbers on the blacklist.", "success");
				}

				if (blacklist.length === 1) {
					notification(`${blacklist.length} phone number have been found on the blacklist.`, "info");
				}

				if (blacklist.length > 1) {
					notification(`${blacklist.length} phone numbers have been found on the blacklist.`, "info");
				}

				let tempValidPhones = [];
				for (let i in validPhones) {
					tempValidPhones.push(validPhones[i]);
				}

				for (let i in tempValidPhones) {
					for (let j in blacklist) {
						if (tempValidPhones[i].phone === blacklist[j].phone) {
							tempValidPhones.splice(i, 1);
						}
					}
				}
				setValidPhones(tempValidPhones);
				setActiveStep(1);
			})
			.catch(error => {
				if (error.name === "AxiosError" && error.code === "ERR_NETWORK") {
					notification(t("Axios.errors.ERR_NETWORK"), "error");
					return;
				}

				notification("Error getting blacklist error, please try again. If the problen persists contact to support.");
			}).finally(() => {
				setIsLoading(false);
			});
	}

	const isValidUrlImage = async (url) => {
		return new Promise((resolve, reject) => {
			const image = new Image();

			image.src = url;

			image.onload = () => resolve(url);
			image.onerror = () => reject(`Invalid url, please try with another.`);
		});
	}

	const onSubmitMessage = async (evt) => {
		evt.preventDefault();

		if (textareaMessage.trim().length === 0 && imageLink.trim().length === 0) {
			notification(t("BulkSms.info.textOrImage"), "error");
			return;
		}

		const json = {};
		json.subject = textareaSubject;

		let numberList = [];
		for (let i in validPhones) {
			numberList.push(validPhones[i].phone);
		}

		json.to = numberList;
		json.body = textareaMessage;

		if (addImageWay === "link") {
			if (imageLink.trim().length !== 0) {
				if (!verifyValidFileExtension(imageLink.trim(), ["jpg", "jpeg", "png", "gif"])) {
					notification("You've provided a file with extension: '" + imageLink.trim().split(".").pop() + "'. Only jpg. jpeg, png or gif files are allowed.", "error");
					return;
				} else {

					try {
						await isValidUrlImage(imageLink);
					} catch (error) {
						notification(error, "error");
						return;
					}

					json.mediaUrl = imageLink;
					json.addImageWay = "link";
				}
			}
		}

		setIsLoading(true);

		if (addImageWay === "upload" && imageLink.trim().length !== 0 && selectedFile !== undefined) {
			let urlMediaPath = await uploadFile(selectedFile, token);
			console.log("urlMediaPath:", urlMediaPath);
			if (urlMediaPath === null) {
				setIsLoading(false);
				return;
			}

			json.mediaUrl = urlMediaPath;
			json.addImageWay = "upload";
		}

		console.log("json:", json);

		await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/sms/bulkSms`,
			json,
			{ headers: { "authorization": token } }
		).then(({ data }) => {
			let results = data.message;

			let tempFulfilled = [];
			let tempRejected = [];

			for (let i in results) {
				if (results[i].status === "fulfilled") {
					const currentResult = results[i].value.message;
					// console.log("currentResultF:", currentResult)
					tempFulfilled.push({ id: i, to: currentResult.to, status: currentResult.status });
				} else {
					const currentResult = results[i].reason;
					// console.log("currentResultR:", currentResult)
					let resultRejected = {};
					resultRejected.id = i;
					resultRejected.to = currentResult.number;
					resultRejected.errorCode = currentResult.errorCode;

					switch (resultRejected.errorCode) {
						case 21609:
							resultRejected.reason = "The StatusCallback URL provided is not a valid URL.";
							break;
					}

					tempRejected.push(resultRejected);
				}
			}

			setFullfilled(tempFulfilled);
			setRejected(tempRejected);

			setActiveStep(3);

		}).catch((error) => {
			console.log("BulkSms.sendMessage-error:", error);
			if (error.name === "AxiosError" && error.code === "ERR_NETWORK") {
				notification(t("Axios.errors.ERR_NETWORK"), "error");
				return;
			}

			switch (error.response.data.message) {
				case "impossibleGetFileSize":
					notification("Image link not accesible, please try again later.", "");
					break;
				case "excededFileSize":
					notification("Only images up to 5MB are allowed, please select another image.", "error");
					break;
				case "":

					break;
			}

		});
		setIsLoading(false);
	}

	useEffect(() => {
		setIsLoading(true);
		document.title = t("BulkSms.title");

		const getBalanceAccount = async () => {
			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/twilio/getBalanceAccount`,
				{ headers: { "Authorization": token } }
			)
				.then(({ data }) => {
					setBalanceAccount(data.message.balance);
					setCurrencyAccount("[" + data.message.currency + "]");
				})
				.catch(error => {
					console.log("Dashboard.getBalanceAccount-error:", error);
					switch (error.response.data.message) {
						case "incompleteCredentials":
							setErrorMessage("You haven't saved your credentials.");
							notification("You haven't saved your credentials yet, please save them in order to get your balance account.", "error");
							break;

						case "gettingAccountBalanceError":
							if (error.response.data.error.code === 20003) {
								setErrorMessage("Wrong credentials or negative balance on your Twilio account.");
								notification("We couldn't get your balance account. This usually happens when your Twilio account is suspended or your credentials are incorrect.", "error");
							}
							break;
					}
				});
		}
		getBalanceAccount();
		setIsLoading(false);
	}, []);

	return (
		<>
			{
				(isLoading)
					? <Loader />
					:
					<MainContainer>

						{
							(errorMessage !== "") ?
								<Alert variant="filled" severity="warning">
									{errorMessage}
								</Alert>
								:
								<>
									{/* Stepper */}
									<Box
										sx={{
											width: "100%",
											backgroundColor: COLORS.light.ternary,
											padding: "16px",
											borderRadius: "5px",
											border: "2px solid " + COLORS.light.primary
										}}
									>
										<Stepper
											alternativeLabel
											activeStep={activeStep}
											sx={{
												paddingBottom: "16px",
												'& .MuiStepLabel-root .Mui-completed': {
													color: COLORS.light.primary, // circle color (COMPLETED)
												},
												'& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
												{
													color: "#000", // Just text label (COMPLETED)
												},
												'& .MuiStepLabel-root .Mui-active': {
													color: COLORS.light.primary, // circle color (ACTIVE)
												},
												'& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
												{
													color: "#000", // Just text label (ACTIVE)
												},
												'& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
													fill: COLORS.light.ternary, // circle's number (ACTIVE)
												},
											}}
										>

											{/* Add phone numbers */}
											<Step>
												<StepLabel>
													Add phone numbers
												</StepLabel>
											</Step>

											{/* Check if there is somebody on the blacklist */}
											<Step>
												<StepLabel>
													Check if there is somebody on the blacklist
												</StepLabel>
											</Step>

											{/* Bulk message Data */}
											<Step>
												<StepLabel>
													Send your bulk message
												</StepLabel>
											</Step>

											{/* Results */}
											<Step>
												<StepLabel>
													Results
												</StepLabel>
											</Step>
										</Stepper>

										{
											(activeStep === 0) &&
											<Container
												maxWidth={"sm"}
											>
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: 2,
														marginTop: "16px"
													}}
												>

													<Divider
														style={{
															background: COLORS.light.primary,
															width: "100%"
														}}
													/>

													{
														(addContactWay === "showButtons") &&
														<>
															<Typography>
																1. Let's get started adding some phone numbers to send them a message.
															</Typography>
															<Typography>
																How would you like to import the phone numbers?
															</Typography>

															<Box
																sx={{
																	display: "flex",
																	flexDirection: "row",
																	gap: 2,
																	justifyContent: "center"
																}}
															>

																<CustomTooltip placement={"top"} title={"From file"}>
																	<IconButton
																		sx={{
																			backgroundColor: COLORS.light.primary,
																			color: COLORS.light.ternary,
																			borderRadius: "5px",
																			border: "2px solid " + COLORS.light.primary,
																			"&:hover": {
																				backgroundColor: "transparent",
																				color: COLORS.light.primary
																			}
																		}}
																		onClick={() => {
																			setAddContactWay("fromFile");
																		}}
																	>
																		<AttachFile
																			sx={{
																				fontSize: "64px"
																			}}
																		/>
																	</IconButton>
																</CustomTooltip>

																<CustomTooltip placement={"top"} title={"Enter manually"}>
																	<IconButton
																		sx={{
																			backgroundColor: COLORS.light.primary,
																			color: COLORS.light.ternary,
																			borderRadius: "5px",
																			border: "2px solid " + COLORS.light.primary,
																			"&:hover": {
																				backgroundColor: "transparent",
																				color: COLORS.light.primary
																			}
																		}}
																		onClick={() => {
																			setAddContactWay("manually");
																		}}
																	>
																		<Create
																			sx={{
																				fontSize: "64px"
																			}}
																		/>
																	</IconButton>
																</CustomTooltip>

															</Box>


														</>
													}

													{
														(addContactWay === "fromFile") &&
														<>
															<Box
																sx={{
																	display: "flex",
																	gap: 2
																}}
															>
																<Button
																	variant='contained'
																	component="label"
																	startIcon={<FileUpload />}
																	sx={{
																		width: "100%",
																		...styles.button
																	}}
																>
																	Upload Text File
																	<input type="file" onChange={(e) => { loadPhonesFromFile(e); }} hidden accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain" />
																</Button>
															</Box>

															<FormGroup>
																<FormControlLabel control={<Checkbox checked={addCountryCode} onChange={() => { setAddCountryCode(!addCountryCode); }} />} label="Add '1' prefix country code before import?" />
															</FormGroup>

															{
																(validPhones.length > 0) &&
																<>
																	<Box
																		sx={{
																			backgroundColor: COLORS.light.primary,
																			borderRadius: "5px"
																		}}
																	>
																		<Typography
																			sx={{
																				color: COLORS.light.text,
																				textAlign: "center",
																				padding: "8px",
																				fontWeight: "800"
																			}}
																		>
																			Valid phone numbers
																		</Typography>
																		<DataGrid
																			autoHeight
																			rows={validPhones}
																			columns={columnsValidPhones}
																			pageSize={10}
																			rowsPerPageOptions={[10]}
																			sx={{
																				border: "1px solid " + COLORS.light.primary,
																				backgroundColor: COLORS.light.ternary,
																				borderTopLeftRadius: 0,
																				borderTopRightRadius: 0
																			}}
																		/>
																	</Box>

																	{
																		(invalidPhones.length > 0) &&
																		<Box
																			sx={{
																				backgroundColor: COLORS.light.primary,
																				borderRadius: "5px"
																			}}
																		>
																			<Typography
																				sx={{
																					color: COLORS.light.text,
																					textAlign: "center",
																					padding: "8px",
																					fontWeight: "800"
																				}}
																			>
																				Invalid phone numbers
																			</Typography>
																			<DataGrid
																				autoHeight
																				rows={invalidPhones}
																				columns={columnsInvalidPhones}
																				pageSize={10}
																				rowsPerPageOptions={[10]}
																				sx={{
																					border: "1px solid " + COLORS.light.primary,
																					backgroundColor: COLORS.light.ternary,
																					borderTopLeftRadius: 0,
																					borderTopRightRadius: 0
																				}}
																			/>
																		</Box>
																	}

																</>
															}

															<Box
																sx={{
																	display: "flex",
																	flexDirection: "row",
																	gap: 2
																}}
															>
																<Button
																	variant='contained'
																	onClick={() => {
																		setValidPhones([]);
																		setInvalidPhones([]);
																		setAddContactWay("showButtons");
																	}}
																	startIcon={<KeyboardReturn />}
																	sx={{
																		width: "100%",
																		...styles.button
																	}}
																>
																	Return
																</Button>

																{
																	(validPhones.length > 0) &&
																	<Button
																		variant="contained"
																		onClick={() => {
																			isOnBlackList();
																		}}
																		sx={{
																			width: "100%",
																			...styles.button
																		}}
																	>
																		Next
																	</Button>
																}

															</Box>

														</>
													}

													{
														(addContactWay === "manually") &&
														<>
															{
																(validPhones.length < 1 && invalidPhones.length < 1) &&
																<>
																	<Typography>
																		Let's add some phone numbers in the textarea.
																	</Typography>
																	<FormGroup>
																		<FormControlLabel control={<Checkbox checked={addCountryCode} onChange={() => { setAddCountryCode(!addCountryCode); }} />} label="Add '1' prefix country code?" />
																	</FormGroup>
																	<TextField
																		id="textareaList"
																		multiline
																		placeholder={"Example: +59398300000"}
																		inputProps={{
																			style: {
																				height: "50vh"
																			}
																		}}
																		required
																		onChange={(evt) => { setTextareaList(evt.target.value); }}
																		value={textareaList}
																	/>

																	<Box
																		sx={{
																			display: "flex",
																			flexDirection: "row",
																			gap: 2
																		}}
																	>
																		<Button
																			onClick={() => {
																				setTextareaList("");
																				setAddContactWay("showButtons");
																			}}
																			variant={"contained"}
																			sx={{
																				width: "100%",
																				...styles.button
																			}}
																		>
																			Return
																		</Button>

																		<Button
																			onClick={() => {
																				loadPhonesFromTextArea();
																			}}
																			variant={"contained"}
																			sx={{
																				width: "100%",
																				...styles.button
																			}}
																		>
																			Next
																		</Button>
																	</Box>
																</>
															}

															{
																(validPhones.length > 0) &&
																<>
																	<Box
																		sx={{
																			backgroundColor: COLORS.light.primary,
																			borderRadius: "5px"
																		}}
																	>
																		<Typography
																			sx={{
																				color: COLORS.light.text,
																				textAlign: "center",
																				padding: "8px",
																				fontWeight: "800"
																			}}
																		>
																			Valid phone numbers
																		</Typography>
																		<DataGrid
																			autoHeight
																			rows={validPhones}
																			columns={columnsValidPhones}
																			pageSize={10}
																			rowsPerPageOptions={[10]}
																			sx={{
																				border: "1px solid " + COLORS.light.primary,
																				backgroundColor: COLORS.light.ternary,
																				borderTopLeftRadius: 0,
																				borderTopRightRadius: 0
																			}}
																		/>
																	</Box>

																	{
																		(invalidPhones.length > 0) &&
																		<Box
																			sx={{
																				backgroundColor: COLORS.light.primary,
																				borderRadius: "5px"
																			}}
																		>
																			<Typography
																				sx={{
																					color: COLORS.light.text,
																					textAlign: "center",
																					padding: "8px",
																					fontWeight: "800"
																				}}
																			>
																				Invalid phone numbers
																			</Typography>
																			<DataGrid
																				autoHeight
																				rows={invalidPhones}
																				columns={columnsInvalidPhones}
																				pageSize={10}
																				rowsPerPageOptions={[10]}
																				sx={{
																					border: "1px solid " + COLORS.light.primary,
																					backgroundColor: COLORS.light.ternary,
																					borderTopLeftRadius: 0,
																					borderTopRightRadius: 0
																				}}
																			/>
																		</Box>
																	}

																	<Box
																		sx={{
																			display: "flex",
																			flexDirection: "row",
																			gap: 2
																		}}
																	>
																		<Button
																			variant="contained"
																			onClick={() => {
																				setValidPhones([]);
																				setInvalidPhones([]);
																				setAddContactWay("manually");
																			}}
																			sx={{
																				width: "100%",
																				...styles.button
																			}}
																		>
																			return
																		</Button>

																		<Button
																			variant="contained"
																			onClick={() => {
																				isOnBlackList();
																			}}
																			sx={{
																				width: "100%",
																				...styles.button
																			}}
																		>
																			Next
																		</Button>
																	</Box>

																</>
															}

														</>
													}
												</Box>
											</Container>
										}

										{
											(activeStep === 1) &&
											<Container
												maxWidth={"sm"}
											>

												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: 2
													}}
												>
													<Divider
														style={{
															background: COLORS.light.primary,
															width: "100%"
														}}
													/>
													<Box
														sx={{
															backgroundColor: COLORS.light.primary,
															borderRadius: "5px"
														}}
													>
														<Typography
															sx={{
																color: COLORS.light.text,
																textAlign: "center",
																padding: "8px",
																fontWeight: "800"
															}}
														>
															Valid phone numbers
														</Typography>
														<DataGrid
															autoHeight
															rows={validPhones}
															columns={columnsValidPhones}
															pageSize={10}
															rowsPerPageOptions={[10]}
															sx={{
																border: "1px solid " + COLORS.light.primary,
																backgroundColor: COLORS.light.ternary,
																borderTopLeftRadius: 0,
																borderTopRightRadius: 0
															}}
														/>
													</Box>

													{
														(blacklistPhones.length > 0) &&
														<Box
															sx={{
																backgroundColor: COLORS.light.primary,
																borderRadius: "5px"
															}}
														>
															<Typography
																sx={{
																	color: COLORS.light.text,
																	textAlign: "center",
																	padding: "8px",
																	fontWeight: "800"
																}}
															>
																Phone numbers on blacklist
															</Typography>
															<DataGrid
																autoHeight
																rows={blacklistPhones}
																columns={columnsInvalidPhones}
																pageSize={10}
																rowsPerPageOptions={[10]}
																sx={{
																	border: "1px solid " + COLORS.light.primary,
																	backgroundColor: COLORS.light.ternary,
																	borderTopLeftRadius: 0,
																	borderTopRightRadius: 0
																}}
															/>
														</Box>
													}

													<Box
														sx={{
															display: "flex",
															flexDirection: "row",
															gap: 2
														}}
													>
														<Button
															onClick={() => {
																setActiveStep(0);
															}}
															variant={"contained"}
															sx={{
																width: "100%",
																...styles.button
															}}
														>
															Return
														</Button>

														<Button
															onClick={() => {
																setActiveStep(2);
															}}
															variant={"contained"}
															sx={{
																width: "100%",
																...styles.button
															}}
														>
															Next
														</Button>
													</Box>

												</Box>

											</Container>
										}

										{
											(activeStep === 2) &&
											<Container
												maxWidth={"xl"}
											>

												<Divider
													style={{
														background: COLORS.light.primary,
														width: "100%",
													}}
												/>

												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: 2,
														paddingTop: "16px"
													}}
												>

													{/* Status account */}
													<Box
														sx={{
															borderRadius: "5px",
															width: "100%",
															display: "flex",
															flexDirection: "row",
															gap: 2,
														}}
													>

														<Card
															icon={<Paid
																sx={{
																	height: "64px",
																	width: "64px",
																	color: COLORS.light.primary
																}}
															/>}
															title={t("Dashboard.cards.twilioBalance")}
															value={balanceAccount}
															subValue={currencyAccount}
														/>

														<Card
															icon={<People
																sx={{
																	height: "64px",
																	width: "64px",
																	color: COLORS.light.primary
																}}
															/>}
															title={"Phone numbers to send"}
															value={validPhones.length}
														/>

														<Card
															icon={<Paid
																sx={{
																	height: "64px",
																	width: "64px",
																	color: "red"
																}}
															/>}
															title={"Estimated new balance"}
															value={newBalanceAccount}
														/>
													</Box>

													<form
														onSubmit={onSubmitMessage}
														style={{
															display: "flex",
															flexDirection: "column",
															gap: 2,
															width: "100%",
															backgroundColor: COLORS.light.ternary,
															borderRadius: "5px",
														}}
													>
														{/* Data message */}
														<Box
															sx={{
																display: "flex",
																flexDirection: "column",
																gap: 2,
																width: "100%",
																backgroundColor: COLORS.light.ternary,
																borderRadius: "5px",
															}}
														>

															{/* Subject */}
															<Typography>
																Enter some subject to your bulk message.
															</Typography>

															<TextField
																id="textareaSubject"
																onChange={(evt) => { setTextareaSubject(evt.target.value); }}
																placeholder={"Example: Christmas message"}
																style={{ width: "100%", height: "100%", backgroundColor: COLORS.light.ternary }}
																value={textareaSubject}
																label={"Subject"}
																fullWidth
																required
															/>

															{/* Text message */}
															<Box
																sx={{
																	display: "flex",
																	flexDirection: "row",
																	gap: 2
																}}
															>

																{/* Textarea message */}
																<Box
																	sx={{
																		display: "flex",
																		flexDirection: "column",
																		width: "100%",
																		gap: 2
																	}}
																>
																	<Typography>
																		Let's add some text to send to your contacts.
																	</Typography>

																	<TextField
																		id="textareaMessage"
																		minRows={10}
																		maxRows={10}
																		multiline
																		onChange={(evt) => {
																			setTextareaMessage(evt.target.value);
																			setMessageLength(evt.target.value.length);
																			setNewBalanceAccount(() => {
																				if (isMMS) {
																					return (balanceAccount - ((Math.ceil(messageLength / CHARACTERS_PER_MESSAGE)) * MMS_COST * validPhones.length)).toFixed(5);
																				} else {
																					return (balanceAccount - ((Math.ceil(messageLength / CHARACTERS_PER_MESSAGE)) * SMS_COST * validPhones.length)).toFixed(5);
																				}
																			});
																		}}
																		placeholder={"Example: Hello World"}
																		style={{
																			width: "100%",
																			backgroundColor: COLORS.light.ternary
																		}}
																		inputProps={{
																			maxLength: MESSAGE_LENGTH_LIMIT
																		}}
																		value={textareaMessage}
																	/>

																	<Typography>
																		{Math.ceil(messageLength / CHARACTERS_PER_MESSAGE)} {(isMMS) ? "MMS" : "SMS"}, {messageLength} / {MESSAGE_LENGTH_LIMIT - messageLength} Characters left
																	</Typography>
																</Box>

																{/* Image Link */}
																<Box
																	sx={{
																		display: "flex",
																		flexDirection: "column",
																		width: "50%",
																	}}
																>
																	<Typography>
																		Would you like to send them an image?
																	</Typography>

																	<Box
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																			gap: 2,
																			height: "100%",
																			paddingTop: "16px"
																		}}
																	>
																		{
																			(addImageWay === "") &&
																			<Box
																				sx={{
																					display: "flex",
																					justifyContent: "center",
																					alignItems: "center",
																					width: "100%",
																					gap: 2
																				}}
																			>

																				<CustomTooltip placement={"bottom"} title={"From a link"}>
																					<IconButton
																						sx={{
																							backgroundColor: COLORS.light.primary,
																							color: COLORS.light.ternary,
																							borderRadius: "5px",
																							border: "2px solid " + COLORS.light.primary,
																							"&:hover": {
																								backgroundColor: "transparent",
																								color: COLORS.light.primary
																							}
																						}}
																						onClick={() => {
																							setAddImageWay("link");
																						}}
																					>
																						<Link
																							sx={{
																								fontSize: "64px"
																							}}
																						/>
																					</IconButton>
																				</CustomTooltip>

																				<CustomTooltip placement={"bottom"} title={"Upload image"}>
																					<IconButton
																						component="label"
																						sx={{
																							backgroundColor: COLORS.light.primary,
																							color: COLORS.light.ternary,
																							borderRadius: "5px",
																							border: "2px solid " + COLORS.light.primary,
																							"&:hover": {
																								backgroundColor: "transparent",
																								color: COLORS.light.primary
																							}
																						}}
																					>
																						<input
																							type="file"
																							hidden
																							accept="image/jpg, image/jpeg, image/png, image/gif"
																							onChange={evt => {
																								const fileSize = (evt.target.files[0].size / (1024 * 1024)).toFixed(2);
																								if (fileSize >= MMS_MAX_FILE_SIZE) {
																									notification("Only images up to 5MB are allowed, please select another image.", "error");
																									return;
																								}
																								setSelectedFile(evt.target.files[0]);
																								setImageLink(URL.createObjectURL(evt.target.files[0])); setAddImageWay("upload");
																							}}
																						/>
																						<CloudUpload
																							sx={{
																								fontSize: "64px"
																							}}
																						/>
																					</IconButton>
																				</CustomTooltip>

																			</Box>
																		}

																		{
																			(addImageWay === "link") &&
																			<Box
																				sx={{
																					display: "flex",
																					flexDirection: "column",
																					gap: 2,
																				}}
																			>
																				<Box
																					sx={{
																						display: "flex",
																						gap: 2
																					}}
																				>

																					<TextField
																						id="imageLink"
																						label="Image Link"
																						onChange={(evt) => {

																							setImageLink(evt.target.value);
																							if (evt.target.value.toString().length > 0) {
																								setIsMMS(true);
																							} else {
																								setIsMMS(false);
																							}

																							setNewBalanceAccount(
																								(isMMS)
																									?
																									balanceAccount - ((Math.ceil(messageLength / CHARACTERS_PER_MESSAGE)) * MMS_COST * validPhones.length)
																									:
																									balanceAccount - ((Math.ceil(messageLength / CHARACTERS_PER_MESSAGE)) * SMS_COST * validPhones.length)
																							);

																						}}
																						placeholder='Example: https://acr.com/logo.png'
																						sx={{ width: "100%", backgroundColor: COLORS.light.ternary }}
																						value={imageLink}
																						variant="outlined"
																					/>

																					<CustomTooltip placement={"top"} title={"Remove image"}>
																						<IconButton
																							onClick={() => {
																								setImageLink("");
																								setAddImageWay("");
																							}}
																							sx={{
																								backgroundColor: COLORS.light.alert,
																								color: COLORS.light.ternary,
																								borderRadius: "5px",
																								border: "2px solid " + COLORS.light.alert,
																								"&:hover": {
																									backgroundColor: "transparent",
																									color: COLORS.light.alert
																								}
																							}}
																						>
																							<Delete
																								sx={{
																									fontSize: "32px"
																								}}
																							/>
																						</IconButton>
																					</CustomTooltip>
																				</Box>

																				{
																					(imageLink !== "") &&
																					<Box
																						sx={{
																							display: "flex",
																							flexDirection: "row",
																							justifyContent: "center",
																							gap: 2,
																						}}
																					>

																						<a
																							href={`${imageLink}`}
																							rel="noopener noreferrer"
																							target={"_blank"}
																						>
																							<img
																								alt={"Invalid image link."}
																								src={imageLink}
																								style={{
																									maxHeight: "200px",
																									maxWidth: "200px",
																									objectFit: "contain",
																								}}
																							/>
																						</a>

																					</Box>
																				}

																			</Box>
																		}

																		{
																			(addImageWay === "upload") &&
																			<Box
																				sx={{
																					display: "flex",
																					flexDirection: "column",
																					gap: 2,
																				}}
																			>
																				<Box
																					sx={{
																						display: "flex",
																						gap: 2,
																						justifyContent: "space-between",
																						alignItems: "center",
																					}}
																				>
																					<Typography
																						sx={{
																							wordBreak: "break-all"
																						}}
																					>
																						{selectedFile.name}
																					</Typography>

																					<CustomTooltip placement={"top"} title={"Remove image"}>
																						<IconButton
																							onClick={() => {
																								setImageLink("");
																								setAddImageWay("");
																							}}
																							sx={{
																								backgroundColor: COLORS.light.alert,
																								color: COLORS.light.ternary,
																								borderRadius: "5px",
																								border: "2px solid " + COLORS.light.alert,
																								"&:hover": {
																									backgroundColor: "transparent",
																									color: COLORS.light.alert
																								}
																							}}
																						>
																							<Delete
																								sx={{
																									fontSize: "32px"
																								}}
																							/>
																						</IconButton>
																					</CustomTooltip>
																				</Box>

																				{
																					(imageLink !== "") &&
																					<Box
																						sx={{
																							display: "flex",
																							flexDirection: "row",
																							justifyContent: "center",
																							gap: 2,
																						}}
																					>

																						<a
																							href={`${imageLink}`}
																							rel="noopener noreferrer"
																							target={"_blank"}
																						>
																							<img
																								alt={"Invalid image link."}
																								src={imageLink}
																								style={{
																									maxHeight: "200px",
																									maxWidth: "200px",
																									objectFit: "contain",
																								}}
																							/>
																						</a>

																					</Box>
																				}

																			</Box>


																		}

																	</Box>

																</Box>

															</Box>

															<Container
																maxWidth={"sm"}
															>
																<Box
																	sx={{
																		display: "flex",
																		gap: 2
																	}}
																>
																	<Button
																		variant={"contained"}
																		sx={{ width: "100%", ...styles.button }}
																		onClick={() => {
																			setActiveStep(1);
																		}}
																	>
																		Return
																	</Button>

																	<Button
																		variant={"contained"}
																		sx={{ width: "100%", ...styles.button }}
																		type={"submit"}
																	>
																		Send message
																	</Button>
																</Box>
															</Container>

														</Box>
													</form>

												</Box>
											</Container>
										}

										{
											(activeStep === 3) &&
											<Container
												maxWidth={"sm"}
											>
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: 2
													}}
												>
													{
														(fullfilled.length > 0) &&
														<Box
															sx={{
																backgroundColor: COLORS.light.primary,
																borderRadius: "5px"
															}}
														>
															<Typography
																sx={{
																	color: COLORS.light.text,
																	textAlign: "center",
																	padding: "8px",
																	fontWeight: "800"
																}}
															>
																Fullfilled
															</Typography>
															<DataGrid
																autoHeight
																rows={fullfilled}
																columns={columnsFullfilled}
																pageSize={10}
																rowsPerPageOptions={[10]}
																sx={{
																	border: "1px solid " + COLORS.light.primary,
																	backgroundColor: COLORS.light.ternary,
																	borderTopLeftRadius: 0,
																	borderTopRightRadius: 0
																}}
															/>
															<Box
																sx={{
																	paddingTop: "16px",
																	backgroundColor: COLORS.light.ternary,
																	display: "flex",
																	flexDirection: "column",
																	gap: 2,
																	justifyContent: "center"
																}}
															>
																<Typography>
																	Your messages has been sent to Twilio, en a few minutes check the menssages status in the "Bulk Sms Sent" screen.
																</Typography>

																<Button
																	onClick={() => {
																		navigate("/list");
																	}}
																	startIcon={<ExitToApp />}
																	variant={"contained"}
																	sx={{
																		width: "100%",
																		...styles.button
																	}}
																>
																	Check messages status
																</Button>

															</Box>

														</Box>
													}

													{
														(rejected.length > 0) &&
														<Box
															sx={{
																backgroundColor: COLORS.light.primary,
																borderRadius: "5px"
															}}
														>
															<Typography
																sx={{
																	color: COLORS.light.text,
																	textAlign: "center",
																	padding: "8px",
																	fontWeight: "800"
																}}
															>
																Rejected
															</Typography>
															<DataGrid
																autoHeight
																rows={rejected}
																columns={columnsRejected}
																pageSize={10}
																rowsPerPageOptions={[10]}
																sx={{
																	border: "1px solid " + COLORS.light.primary,
																	backgroundColor: COLORS.light.ternary,
																	borderTopLeftRadius: 0,
																	borderTopRightRadius: 0
																}}
															/>
														</Box>
													}
												</Box>
											</Container>
										}

									</Box>
								</>
						}

					</MainContainer>
			}

		</>
	);

}

export default BulkSms;
