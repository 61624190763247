import axios from "axios";
import { toast } from "react-toastify";

export const TwilioConstants = {
	MESSAGE_LENGTH_LIMIT: 1600,
	CHARACTERS_PER_MESSAGE: 160,
	SMS_COST: 0.0079,
	MMS_COST: 0.02,
	MMS_MAX_FILE_SIZE: 5
}

export const notification = (text, type) => {
	switch (type) {
		case "error":
			toast.error(text);
			break;
		case "success":
			toast.success(text);
			break;
		default:
			toast.info(text);
			break;
	}
}

export const VerifyAuth = async (token) => {

	const verifyResult = await axios.get(
		`${process.env.REACT_APP_BASE_URL}/api/auth/verifyAuth`,
		{ headers: { "authorization": token } }
	).then(({ data }) => {
		return data;
	}).catch((error) => {
		console.log("Global.VerifyAuth-error:", error);
		if (error.name === "AxiosError" && error.code === "ERR_NETWORK") {
			return { OK: false, message: error.code };
		}
		return error.response.data;
	});

	return verifyResult;
}

export const verifyE164Format = (number) => {
	const regex = /^\+[1-9]\d{4,14}$/;
	return regex.test(number);
}

export const verifyValidFileExtension = (fileName, validExtensions) => {
	// revisa la extension del archivo
	const fileExtension = fileName.split(".").pop();

	return validExtensions.includes(fileExtension);
}

export const getDrawerWidth = () => {
	const drawerWidth = 80;
	return drawerWidth;
}

export const uploadFile = async (selectedFile, token) => {
	let url = await axios.post(
		`${process.env.REACT_APP_BASE_URL}/api/s3/getPostUrl`,
		{ name: selectedFile.name, type: selectedFile.type },
		{ headers: { "authorization": token } }
	)
		.then(({ data }) => {
			console.log("url:", data);
			return {
				OK: true,
				message: data.message.uploadUrl,
				selectedFileName: data.message.selectedFileName
			};
		})
		.catch(error => {
			console.log("Chats-uploadFile.getPostUrlError:", error);
			return error.response.data;
		});

	if (!url.OK) {
		switch (url.message) {

			case "nameAndTypeRequired":
				notification("Application error: name and file type are required, please contact support.", "error");
				break;

			case "errorGettingFileExtension":
				notification("Invalid file name, error while getting the file extension, please try another file or contact support.", "error");
				break;

			case "invalidFileType":
				notification("Invalid file type, only (.jpg, .jpeg, .png and .gif) files are allowed, please try with another file.", "error");
				break;

			case "errorGettingPostUrl":
				notification("S3 error while getting the post url, please try again and if the problem isn't solved contact support.", "error");
				break;

			default:
				notification("Unable to upload image to server. Unknown error while getting post url. Try again or contact support.", "error");
				break;

		}
		return null;
	}

	let imageUrl = null;
	try {
		imageUrl = url.message.split("?")[0];
	} catch (error) {
		console.log("Chats-PhoneChat.uploadFileError-imageUrl:", error);
		notification("Error trying to get the S3 image url.", "error");
		return null;
	}

	let uploadedFile = await axios(
		{
			method: "put",
			url: url.message,
			data: selectedFile,
			headers: {
				"Content-Type": selectedFile.type
			}
		}
	)
		.then(result => {
			return {
				OK: true,
				message: result
			}
		})
		.catch(error => {
			console.log("Chats-PhoneChat.uploadFileError-catch:", error);
			return { OK: false, message: error };
		});
	console.log("uploadFile:", uploadedFile);

	if (!uploadedFile.OK) {
		notification("Error while uploading file, please try again later or contact support.", "error");
		return null;
	}

	return imageUrl;
}
