import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { Archive, Download, KeyboardReturn, Mms, Search, Sms, Unarchive } from '@mui/icons-material';
import { CSVLink } from 'react-csv';
import moment from "moment-timezone";

import Loader from "../components/Loader";
import { GlobalContext } from '../context/GlobalContext';
import { notification } from "../helpers/Global";
import CustomTooltip from '../components/CustomTooltip';

import MainContainer from '../components/MainContainer';
import { Container } from '@mui/system';
import { COLORS } from '../colors';

import { useTranslation } from "react-i18next";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ContentContainer from '../components/ContentContainer';

const styles = {
	buttonView: {
		backgroundColor: COLORS.light.primary,
		border: "2px solid transparent",
		color: COLORS.light.text,
		"&:hover": {
			backgroundColor: COLORS.light.ternary,
			border: "2px solid " + COLORS.light.primary,
			color: COLORS.light.primary
		}
	},
	renderCellList: {
		display: "flex",
		alignItems: "center",
		gap: "15px",
		justifyContent: "center",
		width: "100%"
	}
};

const dateFormat = "YYYY-MM-DD";
const dateTimeFormat = dateFormat + " - hh:mm:ss a";

export const List = () => {
	const [t] = useTranslation("global");
	const { setIsLoading } = useContext(GlobalContext);

	useEffect(() => {
		document.title = t("List.title");
	}, []);

	return (
		<>
			<MainContainer>
				<BulkMessageTable />
			</MainContainer>
		</>
	);
}

const BulkMessageTable = () => {
	const [t] = useTranslation("global");
	const { isLoading, setIsLoading, cookies } = useContext(GlobalContext);
	const [rowData, setRowData] = useState([]);
	const [details, setDetails] = useState("");
	const [selectedRows, setSelectedRows] = useState([]);
	const navigate = useNavigate();
	const token = cookies.get("token");
	const { idBulkMessage } = useParams();
	const [statusValue, setStatusValue] = useState("available");
	const [fromDate, setFromDate] = useState(moment().subtract(7, "days").format(dateFormat));
	const [toDate, setToDate] = useState(moment().format(dateFormat));
	const [pageNumber, setPageNumber] = useState(1);
	const [nPerPage, setNPerPage] = useState(10);
	const [total, setTotal] = useState(0);
	const offset = moment().utcOffset();

	const columns = [
		{ field: "_id", headerName: "Id", hide: true },
		{ field: "subject", headerName: t("List.subject"), flex: 0.5 },
		{ field: "body", headerName: t("List.body"), flex: 1 },
		{
			field: "mediaUrl", headerName: "SMS / MMS", renderCell: (params) => {
				return (
					<Box sx={styles.renderCellList}>
						{(params.row.body !== "") && <Sms sx={{ color: COLORS.light.primary }} />}
						{(params.row.mediaUrl) && <Mms sx={{ color: COLORS.light.primary }} />}
					</Box>
				);
			}
		},
		{
			field: "createdAt", headerName: t("List.date"), flex: 1, renderCell: (params) => {
				return (
					<Box sx={styles.renderCellList}>
						{
							<label>{params.row.createdAt}</label>
						}
					</Box>
				);
			}
		},
		{ field: "status", headerName: t("List.status"), hide: true },
		{
			field: "actions", headerName: t("List.actions"), renderCell: (params) => {
				return (
					<Box sx={styles.renderCellList}>
						<Button
							onClick={() => {
								navigate("/list/" + params.row._id);
							}}
							sx={styles.buttonView}
						>
							View
						</Button>
					</Box>
				);
			}
		}
	];

	const getBulkMessageList = async () => {

		if (fromDate > toDate) {
			notification("Invalid date, please select another date range.", "error");
			return;
		}

		setIsLoading(true);
		await axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/bulk/getBulkMessageList`,
			{ headers: { "Authorization": token }, params: { status: statusValue, fromDate, toDate, offset, pageNumber, nPerPage } }
		)
			.then(({ data }) => {
				setTotal(data.message.total);
				const messagesList = data.message.result;
				for (let i in messagesList) {
					messagesList[i].createdAt = moment(messagesList[i].createdAt).tz(moment.tz.guess()).format(dateTimeFormat);
				}
				setRowData(messagesList);
			})
			.catch(error => {
				console.log("List.BulkMessageTable.useEffect-error:", error);
				if (error.name === "AxiosError" && error.code === "ERR_NETWORK") {
					notification(t("Axios.errors.ERR_NETWORK"), "error");
					return;
				}

				notification(t("List.errors.getBulkMessageList"), "error");
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	const setStatusBulkMessage = async (status) => {
		setIsLoading(true);
		await axios.put(
			`${process.env.REACT_APP_BASE_URL}/api/bulk/updateBulkMessageStatus`,
			{ idList: selectedRows, status },
			{ headers: { "authorization": token } }
		)
			.then(({ data }) => {
				navigate(0);
			}).catch(error => {
				console.log("List.archiveBulkMessage-error:", error);
				if (error.name === "AxiosError" && error.code === "ERR_NETWORK") {
					notification(t("Axios.errors.ERR_NETWORK"), "error");
					return false;
				}
				notification(t("List.errors.archiveBulkMessage"), "error");
			})
			.finally(() => {
				setIsLoading(false);
			});
	}

	useEffect(() => {
		getBulkMessageList();
	}, [pageNumber, nPerPage]);

	return (
		<>
			{
				(isLoading)
					? <Loader />
					:
					<>
						{
							(!idBulkMessage)
								?
								<Container maxWidth="xl" sx={{ overflow: "auto" }}>

									{/* filter */}
									<Box
										sx={{
											backgroundColor: COLORS.light.ternary,
											border: "1px solid " + COLORS.light.primary,
											marginBottom: "16px",
											borderRadius: "5px",
											padding: "16px",
											display: "flex",
											width: "100%",
										}}
									>
										<Box
											sx={{
												display: "flex",
												width: "100%",
												gap: 2
											}}
										>
											<FormControl sx={{ minWidth: "120px" }}>
												<InputLabel>Status</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													label="Status"
													value={statusValue}
													onChange={(evt) => {
														setStatusValue(evt.target.value);
													}}
												>
													<MenuItem value={"available"}>Available</MenuItem>
													<MenuItem value={"archived"}>Archived</MenuItem>
													<MenuItem value={"all"}>All</MenuItem>
												</Select>
											</FormControl>
											<LocalizationProvider dateAdapter={AdapterMoment}>
												<DesktopDatePicker
													inputFormat={dateFormat}
													label={"From"}
													value={fromDate}
													onChange={newFromDate => {
														// setFromDate(moment(newFromDate).format(dateFormat));
														// const offset = moment().utcOffset();
														// setFromDate(moment(newFromDate).add(-offset, "minutes").format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
														setFromDate(moment(newFromDate).format(dateFormat));
													}}
													renderInput={(params) => <TextField {...params} />}
												/>
											</LocalizationProvider>

											<LocalizationProvider dateAdapter={AdapterMoment}>
												<DesktopDatePicker
													label="To"
													inputFormat={dateFormat}
													value={toDate}
													onChange={newToDate => {
														// setToDate(moment(newToDate).format(dateFormat));
														// const offset = moment().utcOffset();
														// setToDate(moment(newToDate).add(-offset, "minutes").format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
														setToDate(moment(newToDate).format(dateFormat));
													}}
													renderInput={(params) => <TextField {...params} />}
												/>
											</LocalizationProvider>

										</Box>

										<Box
											sx={{
												display: "flex",
												width: "100%",
												justifyContent: "right"
											}}
										>
											<Button
												onClick={() => {
													getBulkMessageList();
												}}
												startIcon={<Search />}
												sx={{
													border: "2px solid " + COLORS.light.primary,
													backgroundColor: COLORS.light.primary,
													color: COLORS.light.text,
													minWidth: "80px",
													"&:hover": {
														backgroundColor: "transparent",
														color: COLORS.light.primary
													}
												}}
											>
												Search
											</Button>
										</Box>

									</Box>


									<ContentContainer
										leftContent={
											<>
												<Sms sx={{ color: COLORS.light.ternary }} />
												<Typography sx={{ color: COLORS.light.text, fontWeight: "800" }}>Bulk messages</Typography>
											</>
										}
										rightContent={
											<>
												{
													(statusValue === "available") &&
													<CustomTooltip placement={"top"} title={t("List.buttonArchive")}>
														<IconButton style={{ color: "#ffffff" }} component="label" onClick={() => { setStatusBulkMessage("archived"); }}>
															<Archive />
														</IconButton>
													</CustomTooltip>
												}

												{
													(statusValue === "archived") &&
													<CustomTooltip placement={"top"} title={t("List.buttonUnarchive")}>
														<IconButton style={{ color: "#ffffff" }} component="label" onClick={() => { setStatusBulkMessage("available"); }}>
															<Unarchive />
														</IconButton>
													</CustomTooltip>
												}
											</>
										}
									>

										<DataGrid
											autoHeight
											checkboxSelection
											disableSelectionOnClick
											columns={columns}
											getRowId={(row) => row._id}
											onPageChange={(newPageNumber) => { setPageNumber(newPageNumber + 1); }}
											onPageSizeChange={(newPageSize) => { setNPerPage(newPageSize); }}
											onRowClick={evt => {
												setDetails(evt.row);
											}}
											onSelectionModelChange={(rowIDs) => {
												setSelectedRows(rowIDs);
											}}
											page={pageNumber - 1}
											pageSize={nPerPage}
											pagination
											paginationMode={"server"}
											rows={rowData}
											rowCount={total}
											rowsPerPageOptions={[10, 30, 50]}
											sx={{
												backgroundColor: COLORS.light.ternary
											}}
										/>
									</ContentContainer>

								</Container>
								:
								<MessageReceivers id={details} returnPage={"/list"} />
						}
					</>
			}
		</>

	);
}

export const MessageReceivers = () => {

	const { cookies, isLoading, setIsLoading } = useContext(GlobalContext);
	const navigate = useNavigate();
	const [pageNumber, setPageNumber] = useState(1);
	const [nPerPage, setNPerPage] = useState(10);
	const [total, setTotal] = useState(0);
	const [rowData, setRowData] = useState([]);
	const [exportToCsv, setexportToCsv] = useState([]);
	const token = cookies.get("token");
	const [messageDetails, setMessageDetails] = useState({
		body: "", createdAt: "", messagingServiceSid: "", status: "", subject: "", userId: "", _id: ""
	});
	const { idBulkMessage } = useParams();
	const fileName = `bulkmessage-${idBulkMessage}-${moment().format("MMDDYYYYHHmmss")}.csv`;
	const csvLink = React.createRef();
	const [t] = useTranslation("global");

	const columns = [
		{ field: "_id", headerName: "Id", hide: true },
		{ field: "To", headerName: "Phone", minWidth: 150, flex: 1 },
		{ field: "MessageStatus", headerName: t("List.messageStatus"), minWidth: 150, flex: 1 },
		{ field: "ErrorCode", headerName: t("List.errorCode"), minWidth: 100, flex: 1 },
		{ field: "updatedAt", headerName: "Updated at", minWidth: 200, flex: 1 }
	];

	const columnsCustom = [
		{ field: "_id", headerName: "Id", hide: true },
		{ field: "To", headerName: "Phone", minWidth: 150 },
		{ field: "body", headerName: "Message", flex: 1 },
		{ field: "mediaUrl", headerName: "Media url", flex: 1 },
		{ field: "MessageStatus", headerName: t("List.messageStatus"), minWidth: 150 },
		{ field: "ErrorCode", headerName: t("List.errorCode"), minWidth: 100 },
		{ field: "updatedAt", headerName: "Updated at", minWidth: 200 }
	];

	useEffect(() => {
		const getMessagesListById = async () => {
			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/sms/getMessagesListById`,
				{ headers: { "authorization": token }, params: { uuidBulkMessage: idBulkMessage } }
			)
				.then(({ data }) => {
					console.log(data);

					if (data.message.result.length === 0) {
						notification("Invalid url.", "error");
						navigate("/list");
						return;
					}

					data.message.result[0].createdAt = moment(data.message.result[0].createdAt).tz(moment.tz.guess()).format(dateTimeFormat);

					const mediaUrl = data.message.result[0]?.mediaUrl;

					try {
						if (data.message.result[0]?.mediaUrl !== null) {
							if (!mediaUrl.startsWith("https://")) {
								data.message.result[0].mediaUrl = process.env.REACT_APP_IMAGE_SERVER + "/" + mediaUrl;
							}
						}
					} catch (error) {

					}

					setMessageDetails(data.message.result[0]);

					const receiversList = data.message.result[0].results;
					for (let i in receiversList) {
						receiversList[i].updatedAt = moment(receiversList[i].updatedAt).tz(moment.tz.guess()).format(dateTimeFormat);
					}
					setRowData(data.message.result[0].results);

					let dataToExport = [];

					if (messageDetails.body === "Different messages.") {
						for (let i in data.message.result[0].results) {
							let currentRow = data.message.result[0].results[i];
							dataToExport.push({
								Phone: currentRow.To,
								Message: currentRow.body,
								MediaUrl: currentRow.mediaUrl,
								MessageStatus: currentRow.MessageStatus,
								ErrorCode: currentRow.ErrorCode,
								Date: currentRow.updatedAt
							});
						}
					} else {
						for (let i in data.message.result[0].results) {
							let currentRow = data.message.result[0].results[i];
							dataToExport.push({
								Phone: currentRow.To,
								MessageStatus: currentRow.MessageStatus,
								ErrorCode: currentRow.ErrorCode,
								Date: currentRow.updatedAt
							});
						}
					}
					setexportToCsv(dataToExport);
				})
				.catch(error => {
					console.log("List.MessageReceivers.getMessagesListById-error:", error)
					if (error.name === "AxiosError" && error.code === "ERR_NETWORK") {
						notification(t("Axios.errors.ERR_NETWORK"), "error");
						return false;
					}
					notification(t("List.errors.getMessagesListById"), "error");
				}).finally(() => {

				});
		}
		getMessagesListById();
	}, []);

	return (
		<>
			{
				(isLoading) ?
					<Loader />
					:
					<Container maxWidth="xl" sx={{ overflow: "auto" }}>
						<Box
							sx={{
								backgroundColor: COLORS.light.ternary,
							}}
						>
							<div style={{ height: "50px", display: "flex", flexDirection: "row", backgroundColor: COLORS.light.primary, width: "100%", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>

								<div style={{ display: "flex", justifyContent: "left", width: "100%" }}>
									<CustomTooltip placement={"top"} title={"Return"}>
										<IconButton style={{ color: "#ffffff" }} size="large" component="label" onClick={() => { navigate("/list"); }}>
											<KeyboardReturn fontSize="inherit" />
										</IconButton>
									</CustomTooltip>
								</div>

								<div style={{ display: "flex", justifyContent: "right", width: "100%" }}>
									<CustomTooltip placement={"top"} title={"Export"}>
										<IconButton style={{ color: "#ffffff" }} size="large" component="label" onClick={() => { csvLink.current.link.click(); }}>
											<Download fontSize="inherit" />
											<CSVLink filename={fileName} data={exportToCsv} target="_blank" ref={csvLink} />
										</IconButton>
									</CustomTooltip>
								</div>

							</div>

							<div style={{ display: "flex", flexDirection: "column", gap: "32px", padding: "32px", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "2px solid #dde0ee" }}>
								<Box
									sx={{
										display: "flex",
									}}
								>
									{
										(messageDetails.mediaUrl) &&
										<div style={{ display: "flex", paddingRight: "16px" }}>
											<a
												href={messageDetails.mediaUrl}
												rel="noopener noreferrer"
												target={"_blank"}
											>
												<img
													alt={"MMS file."}
													src={messageDetails.mediaUrl}
													style={{
														maxWidth: "256px",
														maxHeight: "256px",
														borderRadius: "5px"
													}}
												/>
											</a>
										</div>
									}

									<div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px" }}>

										<TextField
											disabled
											fullWidth
											label={t("List.subject")}
											sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "black" }, backgroundColor: COLORS.light.ternary }}
											value={messageDetails.subject}
										/>

										<TextField
											disabled
											fullWidth
											label={t("List.date")}
											sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "black" }, backgroundColor: COLORS.light.ternary }}
											value={messageDetails.createdAt}
										/>

										<TextField
											disabled
											fullWidth
											label={t("List.body")}
											multiline
											sx={{
												"& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "black" }, backgroundColor: COLORS.light.ternary
											}}
											value={messageDetails.body}
										/>
									</div>
								</Box>

								<DataGrid
									autoHeight
									columns={(messageDetails.body === "Different messages.") ? columnsCustom : columns}
									getRowId={(row) => row._id}
									pageSize={nPerPage}
									rows={rowData}
									rowsPerPageOptions={[10]}
									sx={{
										border: "1px solid " + COLORS.light.primary,
										backgroundColor: COLORS.light.ternary,
										overflowX: "scroll"
									}}
								/>

							</div>
						</Box>

					</Container>
			}
		</>
	);
}