import React, { useContext, useEffect, useRef, useState } from 'react'
import Loader from '../components/Loader';
import MainContainer from '../components/MainContainer';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../context/GlobalContext';
import { Alert, Box, Button, Container, Divider, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { CloudUpload, ExitToApp, Paid, People } from '@mui/icons-material';
import { COLORS } from "../colors";
import { TwilioConstants, notification, verifyE164Format, verifyValidFileExtension } from '../helpers/Global';
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import { CSVLink } from 'react-csv';
import Card from '../components/Card';
import { useNavigate } from 'react-router-dom';

const styles = {
	button: {
		backgroundColor: COLORS.light.primary,
		color: "#ffffff",
		border: "2px solid " + COLORS.light.primary,
		"&:hover": {
			backgroundColor: "transparent",
			color: COLORS.light.primary
		}
	},
	buttonAlert: {
		backgroundColor: COLORS.light.alert,
		color: "#ffffff",
		border: "2px solid " + COLORS.light.alert,
		"&:hover": {
			backgroundColor: "transparent",
			color: COLORS.light.alert
		}
	},
	buttonTest: {
		backgroundColor: COLORS.light.test,
		color: "#ffffff",
		border: "2px solid " + COLORS.light.test,
		"&:hover": {
			backgroundColor: "transparent",
			color: COLORS.light.test
		}
	},
	buttonWithBackground: {
		backgroundColor: COLORS.light.primary,
		border: "2px solid " + COLORS.light.ternary,
		color: COLORS.light.text,
		"&:hover": {
			backgroundColor: COLORS.light.ternary,
			color: COLORS.light.primary,
			border: "2px solid " + COLORS.light.primary
		}
	}
};

const BulkCustom = () => {
	const { cookies, isLoading, setIsLoading } = useContext(GlobalContext);
	const token = cookies.get("token");
	const [t] = useTranslation("global");
	const [validPhones, setValidPhones] = useState([]);
	const [invalidPhones, setInvalidPhones] = useState([]);
	const [textareaSubject, setTextareaSubject] = useState("");
	const [balanceAccount, setBalanceAccount] = useState(0);
	const [currencyAccount, setCurrencyAccount] = useState("");
	const [blacklistPhones, setBlacklistPhones] = useState([]);
	const [errorMessage, setErrorMessage] = useState("");
	const [activeStep, setActiveStep] = useState(0);
	const [selectedFile, setSelectedFile] = useState(undefined);
	const [newBalanceAccount, setNewBalanceAccount] = useState(0);
	const [fullfilled, setFullfilled] = useState([]);
	const [rejected, setRejected] = useState([]);
	const csvExampleLink = useRef();
	const navigate = useNavigate();

	const columnsValidPhones = [
		{ field: "id", headerName: "Id", hide: true },
		{ field: "phone", headerName: "Phone", flex: 0.5 },
		{ field: "message", headerName: "Message", flex: 1 },
		{ field: "mediaUrl", headerName: "Media url", flex: 1 }
	];

	const columnsInvalidPhones = [
		{ field: "id", headerName: "Id", hide: true },
		{ field: "phone", headerName: "Phone", flex: 0.5 },
		{ field: "message", headerName: "Message", flex: 1 },
		{ field: "mediaUrl", headerName: "Media url", flex: 1 },
		{ field: "reason", headerName: "Reason", flex: 1 }
	];

	const csvFileName = "CustomMessagesExample.csv";

	const csvExampleData = [
		["Phone", "Message", "Media url"],
		["15555555555", "Hello Joe.", "https://fei.edu/wp-content/uploads/2022/03/Recurso-1.png"],
		["16666666666", "Have a nice day."],
		["17777777777", "", "https://fei.edu/wp-content/uploads/2022/03/Recurso-1.png"]
	];

	const columnsFullfilled = [
		{ field: "id", headerName: "Id", hide: true },
		{ field: "to", headerName: "Number", flex: 1 },
		{ field: "status", headerName: "Status", flex: 1 }
	];

	const columnsRejected = [
		{ field: "id", headerName: "Id", hide: true },
		{ field: "to", headerName: "Number", flex: 0.5 },
		{ field: "errorCode", headerName: "Error Code", flex: 0.5 },
		{ field: "reason", headerName: "Reason", flex: 1 }
	];

	const readExcelFile = (reader, file, valids, invalids) => {
		reader.readAsBinaryString(file);

		reader.onload = (evt) => {
			const workBook = XLSX.read(evt.target.result, { type: "binary" });
			const workSheetName = workBook.SheetNames[0];
			const workSheet = workBook.Sheets[workSheetName];
			const column = XLSX.utils.sheet_to_json(workSheet, { header: 1 });

			for (let i in column) {
				let tempRow = column[i][0].toString();

				if (tempRow === "") {
					continue;
				}

				if (!tempRow.startsWith("+")) {
					tempRow = "+" + tempRow;
				}
			}

			if (valids.length < 1) {
				notification("Not valid phone numbers found on " + file.name + " file. Phone numbers should be on E164 format.", "error");
			}

		}

		reader.onerror = () => {
			notification("Error while reading " + file.name + " file, please select another file or try again. If the problem persists contact to support");
		}

	}

	const loadPhonesFromFile = async (evt) => {
		setIsLoading(true);

		evt.preventDefault();
		let file = evt.target.files[0];

		// verifica si el archivo no es undefined
		if (file?.name === undefined) {
			notification("No file selected.", "error");
			return;
		}

		// revisa la extension del archivo
		const fileExtension = file.name.split(".").pop();
		const validExtensions = ["csv", "xls", "xlsx", "txt"];

		if (!validExtensions.includes(fileExtension)) {
			notification("You've provided a file with extension: '" + fileExtension + "'. Only csv, xls, xslx or txt files are allowed.", "error");
			setIsLoading(false);
			return;
		}

		const reader = new FileReader();

		let valids = [];
		let invalids = [];

		switch (fileExtension) {
			case "csv":
				reader.readAsText(file);

				reader.onload = () => {
					Papa.parse(reader.result, {
						skipEmptyLines: true,
						complete: (results) => {
							for (let i in results.data) {
								const row = results.data[i][0];
								let tempRow = row.replaceAll(/[^0-9\n]/g, " ");
								tempRow = tempRow.replaceAll(/\s+/g, "");

								if (tempRow === "") {
									continue;
								}

								if (!tempRow.startsWith("+")) {
									tempRow = "+" + tempRow;
								}

								if (verifyE164Format(tempRow)) {
									valids.push({ id: i, phone: tempRow });
								} else {
									invalids.push({ id: i, phone: tempRow, reason: "Phones should be on E164 format." });
								}

							}

							if (valids.length < 1) {
								notification("Not valid phone numbers found on " + file.name + " file. Phone numbers should be on the first columnd and be on E164 format.", "error");
							}

							console.log(valids);
							console.log(invalids);

							setValidPhones(valids);
							setInvalidPhones(invalids);

						},
						error: (error) => {
							console.log("BulkSms.loadPhonesFromFile-onload:", error);
							notification("Error getting the csv data, please try again later. If the problem persists contact to support.", "error");
						}
					})
				};

				reader.onerror = () => {
					console.log("BulkSms.loadPhonesFromFile-onerror:", reader.error);
					notification("Error reading csv file, please try again later. If the problem persists contact to support.", "error");
				}

				break;

			case "txt":
				reader.readAsText(file);

				reader.onload = () => {
					let tempList = reader.result.split("\n");
					for (let i in tempList) {
						let tempRow = tempList[i].replaceAll(/[^0-9\n]/g, " ");
						tempRow = tempRow.replaceAll(/\s+/g, "");

						if (tempRow === "") {
							continue;
						}

						if (!tempRow.startsWith("+")) {
							tempRow = "+" + tempRow;
						}

					}

					if (valids.length < 1) {
						notification("Not valid phone numbers found on " + file.name + " file. Phone numbers should be on E164 format.", "error");
					}

				}

				reader.onerror = () => {
					console.log("BulkSms.loadPhonesFromFile.txt-error:", reader.error);
					notification("Reading txt file error, please try again. If the problem persists contact to support.", "error");
				}

				break;

			case "xls":
				readExcelFile(reader, file, valids, invalids);
				break;

			case "xlsx":
				readExcelFile(reader, file, valids, invalids);
				break;
		}

		evt.target.value = null;
		setIsLoading(false);
	}

	const isOnBlackList = async () => {
		setIsLoading(true);
		const numberList = [];
		const blacklist = [];

		for (let i in validPhones) {
			numberList.push(validPhones[i].phone);
		}

		await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/blacklist/isOnBlackList`,
			{ numberList },
			{ headers: { "authorization": token } }
		)
			.then(({ data }) => {

				for (let i in data.message) {
					blacklist.push({ id: i, phone: data.message[i].number, reason: "Is on the black list." });
				}

				setBlacklistPhones(blacklist);
				if (blacklist.length === 0) {
					notification("There is no phone numbers on the blacklist.", "success");
				}

				if (blacklist.length === 1) {
					notification(`${blacklist.length} phone number have been found on the blacklist.`, "info");
				}

				if (blacklist.length > 1) {
					notification(`${blacklist.length} phone numbers have been found on the blacklist.`, "info");
				}

				let tempValidPhones = [];
				for (let i in validPhones) {
					tempValidPhones.push(validPhones[i]);
				}

				for (let i in tempValidPhones) {
					for (let j in blacklist) {
						if (tempValidPhones[i].phone === blacklist[j].phone) {
							tempValidPhones.splice(i, 1);
						}
					}
				}
				setValidPhones(tempValidPhones);
			})
			.catch(error => {
				if (error.name === "AxiosError" && error.code === "ERR_NETWORK") {
					notification(t("Axios.errors.ERR_NETWORK"), "error");
					return;
				}

				notification("Error getting blacklist error, please try again. If the problen persists contact to support.");
			}).finally(() => {
				setIsLoading(false);
			});
	}

	const onSubmitMessage = async (evt) => {
		setIsLoading(true);

		evt.preventDefault();

		const json = {};
		json.subject = textareaSubject;
		json.list = validPhones;

		await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/sms/customSms`,
			json,
			{ headers: { "authorization": token } }
		)
			.then(({ data }) => {
				let results = data.message;
				console.log(data);

				let tempFulfilled = [];
				let tempRejected = [];

				for (let i in results) {
					if (results[i].status === "fulfilled") {
						const currentResult = results[i].value.message;
						// console.log("currentResultF:", currentResult)
						tempFulfilled.push({ id: i, to: currentResult.to, status: currentResult.status });
					} else {
						const currentResult = results[i].reason;
						// console.log("currentResultR:", currentResult)
						let resultRejected = {};
						resultRejected.id = i;
						resultRejected.to = currentResult.number;
						resultRejected.errorCode = currentResult.errorCode;

						switch (resultRejected.errorCode) {
							case 21609:
								resultRejected.reason = "The StatusCallback URL provided is not a valid URL.";
								break;
						}

						tempRejected.push(resultRejected);
					}
				}

				setFullfilled(tempFulfilled);
				setRejected(tempRejected);
			})
			.catch((error) => {
				console.log("BulkCustom.onSubmitMessage-error:", error);
				if (error.name === "AxiosError" && error.code === "ERR_NETWORK") {
					notification(t("Axios.errors.ERR_NETWORK"), "error");
					return;
				}
			})
			.finally(() => {
				setIsLoading(false);
				setActiveStep(4);
			});

		// setActiveStep(4);
	}

	const readCsvFile = () => {
		// verifica si el archivo no es undefined
		if (selectedFile === undefined) {
			notification("No file selected.", "error");
			return;
		}

		// revisa la extension del archivo
		const fileExtension = selectedFile.name.split(".").pop();
		const validExtensions = ["csv"];

		if (!validExtensions.includes(fileExtension)) {
			notification("You've provided a file with extension: '" + fileExtension + "'. Only csv files are allowed.", "error");
			return;
		}

		setValidPhones([]);
		setInvalidPhones([]);

		const reader = new FileReader();

		let valids = [];
		let invalids = [];
		let newBalance = 0;

		reader.readAsText(selectedFile);

		reader.onload = () => {
			Papa.parse(reader.result, {
				skipEmptyLines: true,
				complete: (results) => {
					let tempContact = [];
					for (let i = 1; i < results.data.length; i++) {
						tempContact = results.data[i];
						console.log(tempContact);

						let phone = tempContact[0];
						let message = tempContact[1];
						let messageLength = message?.length;
						let mediaUrl = tempContact[2];
						let mediaUrlLength = mediaUrl?.length;

						phone = phone.replaceAll(/[^0-9]/g, "");

						if (phone === "" || phone.length === 0) {
							continue;
						}

						if (phone.length === 10) {
							phone = "1" + phone;
						}


						if (!phone.startsWith("+")) {
							phone = "+" + phone;
						}

						// si el telefono no tiene el formato correcto
						if (!verifyE164Format(phone)) {
							invalids.push({ id: i, phone, message, mediaUrl, reason: "Invalid phone number, it should be on E164 format." });
							continue;
						}

						// si no existe el mensaje o no existe la url se agregan a invalids
						if (message.replace(/\s/g, "") === "" && mediaUrl?.replace(/\s/g, "") === "") {
							invalids.push({ id: i, phone, message, mediaUrl, reason: "Message or Url image are required, it can be one of them or both." });
							continue;
						}

						if (message) {
							if (message.length > TwilioConstants.MESSAGE_LENGTH_LIMIT) {
								invalids.push({ id: i, phone, message, mediaUrl, reason: "Your message can only have up to 1600 characters." });
								continue;
							}
						}

						if (mediaUrl) {
							if (mediaUrl?.replace(/\s/g, "") !== "") {
								if (!verifyValidFileExtension(mediaUrl, ["jpg", "jpeg", "png", "gif"])) {
									invalids.push({ id: i, phone, message, mediaUrl, reason: "You can attach only jpg, jpeg, png and gif files." });
									continue;
								}
							}
						}


						valids.push({ id: i, phone, message, mediaUrl });

						if (mediaUrlLength > 0) {
							newBalance += ((Math.ceil(messageLength / TwilioConstants.CHARACTERS_PER_MESSAGE)) * TwilioConstants.MMS_COST);
						} else {
							newBalance += ((Math.ceil(messageLength / TwilioConstants.CHARACTERS_PER_MESSAGE)) * TwilioConstants.SMS_COST);
						}

					}

					let notRepeated = [];
					let repeated = [];

					for (let i in valids) {
						if (!notRepeated.includes(valids[i].phone)) {
							notRepeated.push(valids[i].phone);
						} else {
							repeated.push(valids[i]);
						}
					}

					for (let i in repeated) {
						const index = valids.indexOf(repeated[i]);
						invalids.push({ id: valids[index].id, phone: valids[index].phone, reason: "Duplicated phone number." });
						valids.splice(index, 1);
					}

					// console.log("repeated:", repeated);
					// console.log("notRepeated:", notRepeated);

					// console.log("valids:", valids);
					// console.log("invalids:", invalids);

					if (valids.length < 1) {
						notification("Valid data not found on " + selectedFile.name + ". Check the errors, correct them and try again.", "error");
					}

					setValidPhones(valids);
					setInvalidPhones(invalids);
					newBalance = balanceAccount - newBalance;
					if (newBalance < 0) {
						notification("Negative balance after this operation.");
					}
					setNewBalanceAccount(newBalance + currencyAccount);
				},
				error: (error) => {
					console.log("BulkSms.loadPhonesFromFile-onload:", error);
					notification("Error getting the csv data, please try again later. If the problem persists contact to support.", "error");
				}
			})
		};

		reader.onerror = () => {
			console.log("BulkSms.loadPhonesFromFile-onerror:", reader.error);
			notification("Error reading csv file, please try again later. If the problem persists contact to support.", "error");
		}

	}

	useEffect(() => {
		setIsLoading(false);
		document.title = "Send Custom SMS";

		const getBalanceAccount = async () => {
			await axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/twilio/getBalanceAccount`,
				{ headers: { "Authorization": token } }
			)
				.then(({ data }) => {
					setBalanceAccount(data.message.balance);
					setCurrencyAccount("[" + data.message.currency + "]");
				})
				.catch(error => {
					console.log("Dashboard.getBalanceAccount-error:", error);
					switch (error.response.data.message) {
						case "incompleteCredentials":
							setErrorMessage("You haven't saved your credentials.");
							notification("You haven't saved your credentials yet, please save them in order to get your balance account.", "error");
							break;

						case "gettingAccountBalanceError":
							if (error.response.data.error.code === 20003) {
								setErrorMessage("Wrong credentials or negative balance on your Twilio account.");
								notification("We couldn't get your balance account. This usually happens when your Twilio account is suspended or your credentials are incorrect.", "error");
							}
							break;
					}
				});
		}
		getBalanceAccount();
		setIsLoading(false);
	}, []);

	return (
		<>
			{
				(isLoading)
					?
					<Loader />
					:
					<MainContainer>

						{
							(errorMessage !== "") ?
								<Alert variant="filled" severity="warning">
									{errorMessage}
								</Alert>
								:
								<>
									{/* Stepper */}
									<Box
										sx={{
											width: "100%",
											backgroundColor: COLORS.light.ternary,
											padding: "16px",
											borderRadius: "5px",
											border: "2px solid " + COLORS.light.primary
										}}
									>
										<Stepper
											alternativeLabel
											activeStep={activeStep}
											sx={{
												paddingBottom: "16px",
												'& .MuiStepLabel-root .Mui-completed': {
													color: COLORS.light.primary, // circle color (COMPLETED)
												},
												'& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
												{
													color: "#000", // Just text label (COMPLETED)
												},
												'& .MuiStepLabel-root .Mui-active': {
													color: COLORS.light.primary, // circle color (ACTIVE)
												},
												'& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
												{
													color: "#000", // Just text label (ACTIVE)
												},
												'& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
													fill: COLORS.light.ternary, // circle's number (ACTIVE)
												},
											}}
										>
											{/* Upload your file */}
											<Step>
												<StepLabel>
													Upload your file
												</StepLabel>
											</Step>
											<Step>
												<StepLabel>
													CSV data
												</StepLabel>
											</Step>
											<Step>
												<StepLabel>
													Check if there is somebody on the blacklist
												</StepLabel>
											</Step>
											<Step>
												<StepLabel>
													Send your bulk message
												</StepLabel>
											</Step>
											<Step>
												<StepLabel>
													Results
												</StepLabel>
											</Step>
										</Stepper>

										<Divider
											style={{
												background: COLORS.light.primary,
												width: "100%"
											}}
										/>

										{
											(activeStep === 0) &&
											<Container
												maxWidth={"sm"}
											>
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: 2,
														marginTop: "16px"
													}}
												>
													<Box
														sx={{
															display: "flex",
															flexDirection: "column",
															justifyContent: "left",
															alignItems: "center",
															gap: 2,
														}}
													>
														<CloudUpload
															sx={{
																color: COLORS.light.primary,
																fontSize: "64px"
															}}
														/>
														<Typography>
															Upload your messages file (.csv)
															<br />
															You can upload your messages list by selecting a .csv file that contains your data.
															<br />
															In order to upload your messages list, the .csv it should have the following format:
															<CSVLink filename={csvFileName} data={csvExampleData} target="_blank" ref={csvExampleLink}>
																{csvFileName}
															</CSVLink>
														</Typography>
														<Box
															sx={{
																display: "flex",
																gap: 2,
																justifyContent: "space-between",
																width: "100%",
																alignItems: "center"
															}}
														>
															{
																(selectedFile === undefined)
																	?
																	<Typography>
																		No file selected.
																	</Typography>
																	:
																	<Typography>
																		{selectedFile.name}
																	</Typography>
															}
															<Button
																component="label"
																sx={styles.buttonWithBackground}
																variant='contained'
															>
																Select CSV File
																<input
																	accept=".csv"
																	id={"upload-csv"}
																	hidden
																	onChange={(evt) => {
																		if (!evt.target.files[0].name.endsWith(".csv")) {
																			notification("Only .csv file allowed, please try with another file.", "error");
																			setSelectedFile(undefined);
																			return;
																		}

																		if (evt.target.files.length > 0) {
																			setSelectedFile(evt.target.files[0]);
																		}
																	}}
																	type="file"
																/>
															</Button>
														</Box>
														{
															(selectedFile !== undefined) &&
															<Button
																sx={{
																	...styles.buttonWithBackground,
																	width: "100%"
																}}
																onClick={() => {
																	readCsvFile();
																	setActiveStep(1);
																}}
															>
																Next
															</Button>
														}
													</Box>
												</Box>
											</Container>
										}

										{
											(activeStep === 1) &&
											<Box
												sx={{
													paddingTop: "16px",
													display: "flex",
													flexDirection: "column",
													gap: 2
												}}
											>
												{/* valid phones */}
												{
													(validPhones.length > 0) &&
													<Box
														sx={{
															backgroundColor: COLORS.light.primary,
															borderRadius: "5px"
														}}
													>
														<Typography
															sx={{
																color: COLORS.light.text,
																textAlign: "center",
																padding: "8px",
																fontWeight: "800"
															}}
														>
															Valid phone numbers
														</Typography>
														<DataGrid
															autoHeight
															rows={validPhones}
															columns={columnsValidPhones}
															pageSize={10}
															rowsPerPageOptions={[10]}
															sx={{
																border: "1px solid " + COLORS.light.primary,
																backgroundColor: COLORS.light.ternary,
																borderTopLeftRadius: 0,
																borderTopRightRadius: 0
															}}
														/>
													</Box>
												}

												{/* Invalid phones */}
												{
													(invalidPhones.length > 0) &&
													<Box
														sx={{
															backgroundColor: COLORS.light.primary,
															borderRadius: "5px"
														}}
													>
														<Typography
															sx={{
																color: COLORS.light.text,
																textAlign: "center",
																padding: "8px",
																fontWeight: "800"
															}}
														>
															Invalid phone numbers
														</Typography>
														<DataGrid
															autoHeight
															rows={invalidPhones}
															columns={columnsInvalidPhones}
															pageSize={10}
															rowsPerPageOptions={[10]}
															sx={{
																border: "1px solid " + COLORS.light.primary,
																backgroundColor: COLORS.light.ternary,
																borderTopLeftRadius: 0,
																borderTopRightRadius: 0
															}}
														/>
													</Box>
												}

												<Container
													maxWidth={"sm"}
												>
													<Box
														sx={{
															display: "flex",
															gap: 2,
															paddingTop: "16px"
														}}
													>
														<Button
															sx={{
																...styles.buttonWithBackground,
																width: "100%"
															}}
															onClick={() => {
																setActiveStep(0);
															}}
														>
															Return
														</Button>
														{
															(validPhones.length > 0) &&
															<Button
																sx={{
																	...styles.buttonWithBackground,
																	width: "100%"
																}}
																onClick={() => {
																	setActiveStep(2);
																	isOnBlackList();
																}}
															>
																Next
															</Button>
														}
													</Box>
												</Container>

											</Box>
										}

										{
											(activeStep === 2) &&
											<Box
												sx={{
													paddingTop: "16px",
													display: "flex",
													flexDirection: "column",
													gap: 2
												}}
											>
												<Divider
													style={{
														background: COLORS.light.primary,
														width: "100%"
													}}
												/>

												<Box
													sx={{
														backgroundColor: COLORS.light.primary,
														borderRadius: "5px"
													}}
												>
													<Typography
														sx={{
															color: COLORS.light.text,
															textAlign: "center",
															padding: "8px",
															fontWeight: "800"
														}}
													>
														Valid phone numbers
													</Typography>
													<DataGrid
														autoHeight
														rows={validPhones}
														columns={columnsValidPhones}
														pageSize={10}
														rowsPerPageOptions={[10]}
														sx={{
															border: "1px solid " + COLORS.light.primary,
															backgroundColor: COLORS.light.ternary,
															borderTopLeftRadius: 0,
															borderTopRightRadius: 0
														}}
													/>
												</Box>

												{
													(blacklistPhones.length > 0) &&
													<Box
														sx={{
															backgroundColor: COLORS.light.primary,
															borderRadius: "5px"
														}}
													>
														<Typography
															sx={{
																color: COLORS.light.text,
																textAlign: "center",
																padding: "8px",
																fontWeight: "800"
															}}
														>
															Phone numbers on blacklist
														</Typography>
														<DataGrid
															autoHeight
															rows={blacklistPhones}
															columns={columnsInvalidPhones}
															pageSize={10}
															rowsPerPageOptions={[10]}
															sx={{
																border: "1px solid " + COLORS.light.primary,
																backgroundColor: COLORS.light.ternary,
																borderTopLeftRadius: 0,
																borderTopRightRadius: 0
															}}
														/>
													</Box>
												}

												<Container maxWidth={"sm"}>
													<Box
														sx={{
															display: "flex",
															flexDirection: "row",
															gap: 2
														}}
													>
														<Button
															onClick={() => {
																setActiveStep(1);
															}}
															variant={"contained"}
															sx={{
																width: "100%",
																...styles.button
															}}
														>
															Return
														</Button>

														<Button
															onClick={() => {
																setActiveStep(3);
															}}
															variant={"contained"}
															sx={{
																width: "100%",
																...styles.button
															}}
														>
															Next
														</Button>
													</Box>
												</Container>

											</Box>
										}

										{
											(activeStep === 3) &&
											<Box
												sx={{
													display: "flex",
													flexDirection: "column"
												}}
											>

												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: 2,
														paddingTop: "16px"
													}}
												>
													<form
														onSubmit={onSubmitMessage}
														style={{
															display: "flex",
															flexDirection: "column",
															gap: "10px",
															width: "100%",
															backgroundColor: COLORS.light.ternary,
															borderRadius: "5px",
														}}
													>

														{/* Status account */}
														<Box
															sx={{
																borderRadius: "5px",
																width: "100%",
																display: "flex",
																flexDirection: "row",
																gap: 2,
															}}
														>
															<Card
																icon={<Paid
																	sx={{
																		height: "64px",
																		width: "64px",
																		color: COLORS.light.primary
																	}}
																/>}
																title={t("Dashboard.cards.twilioBalance")}
																value={balanceAccount}
																subValue={currencyAccount}
															/>

															<Card
																icon={<People
																	sx={{
																		height: "64px",
																		width: "64px",
																		color: COLORS.light.primary
																	}}
																/>}
																title={"Phone numbers to send"}
																value={validPhones.length}
															/>

															<Card
																icon={<Paid
																	sx={{
																		height: "64px",
																		width: "64px",
																		color: "red"
																	}}
																/>}
																title={"Estimated new balance"}
																value={newBalanceAccount}
															/>
														</Box>

														{/* Subject */}
														<Typography>
															Enter some subject to your bulk message.
														</Typography>

														<TextField
															id="textareaSubject"
															onChange={(evt) => { setTextareaSubject(evt.target.value); }}
															placeholder={"Example: Christmas message"}
															style={{ width: "100%", height: "100%", backgroundColor: COLORS.light.ternary }}
															value={textareaSubject}
															label={"Subject"}
															fullWidth
															required
														/>

														<Box
															sx={{
																backgroundColor: COLORS.light.primary,
																borderRadius: "5px"
															}}
														>
															<Typography
																sx={{
																	color: COLORS.light.text,
																	textAlign: "center",
																	padding: "8px",
																	fontWeight: "800"
																}}
															>
																Messages to send
															</Typography>
															<DataGrid
																autoHeight
																rows={validPhones}
																columns={columnsValidPhones}
																pageSize={10}
																rowsPerPageOptions={[10]}
																sx={{
																	border: "1px solid " + COLORS.light.primary,
																	backgroundColor: COLORS.light.ternary,
																	borderTopLeftRadius: 0,
																	borderTopRightRadius: 0
																}}
															/>
														</Box>

														<Container
															maxWidth={"sm"}
														>
															<Box
																sx={{
																	display: "flex",
																	flexDirection: "row",
																	gap: 2
																}}
															>
																<Button
																	onClick={() => {
																		setActiveStep(2);
																	}}
																	variant={"contained"}
																	sx={{
																		width: "100%",
																		...styles.button
																	}}
																>
																	Return
																</Button>

																<Button
																	type={"submit"}
																	variant={"contained"}
																	sx={{
																		width: "100%",
																		...styles.button
																	}}
																>
																	Send messages
																</Button>
															</Box>
														</Container>
													</form>

												</Box>

											</Box>
										}

										{
											(activeStep === 4) &&
											<Container
												maxWidth={"sm"}
											>
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														gap: 2,
														marginTop: "16px"
													}}
												>
													{
														(fullfilled.length > 0) &&
														<Box
															sx={{
																backgroundColor: COLORS.light.primary,
																borderRadius: "5px"
															}}
														>
															<Typography
																sx={{
																	color: COLORS.light.text,
																	textAlign: "center",
																	padding: "8px",
																	fontWeight: "800"
																}}
															>
																Fullfilled
															</Typography>
															<DataGrid
																autoHeight
																rows={fullfilled}
																columns={columnsFullfilled}
																pageSize={10}
																rowsPerPageOptions={[10]}
																sx={{
																	border: "1px solid " + COLORS.light.primary,
																	backgroundColor: COLORS.light.ternary,
																	borderTopLeftRadius: 0,
																	borderTopRightRadius: 0
																}}
															/>
															<Box
																sx={{
																	paddingTop: "16px",
																	backgroundColor: COLORS.light.ternary,
																	display: "flex",
																	flexDirection: "column",
																	gap: 2,
																	justifyContent: "center"
																}}
															>
																<Typography>
																	Your messages has been sent to Twilio, en a few minutes check the menssages status in the "Bulk Sms Sent" screen.
																</Typography>

																<Button
																	onClick={() => {
																		navigate("/list");
																	}}
																	startIcon={<ExitToApp />}
																	variant={"contained"}
																	sx={{
																		width: "100%",
																		...styles.button
																	}}
																>
																	Check messages status
																</Button>

															</Box>

														</Box>
													}

													{
														(rejected.length > 0) &&
														<Box
															sx={{
																backgroundColor: COLORS.light.primary,
																borderRadius: "5px"
															}}
														>
															<Typography
																sx={{
																	color: COLORS.light.text,
																	textAlign: "center",
																	padding: "8px",
																	fontWeight: "800"
																}}
															>
																Rejected
															</Typography>
															<DataGrid
																autoHeight
																rows={rejected}
																columns={columnsRejected}
																pageSize={10}
																rowsPerPageOptions={[10]}
																sx={{
																	border: "1px solid " + COLORS.light.primary,
																	backgroundColor: COLORS.light.ternary,
																	borderTopLeftRadius: 0,
																	borderTopRightRadius: 0
																}}
															/>
														</Box>
													}
												</Box>
											</Container>
										}

									</Box>
								</>
						}
					</MainContainer >
			}
		</>
	);
}

export default BulkCustom;