import { Logout, Settings } from "@mui/icons-material";
import { AppBar, Avatar, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../colors";
import { GlobalContext } from "../context/GlobalContext";

import { useTranslation } from "react-i18next";

// Images
import userDefault from "../img/texting-woman.jpg";

const AppBarMui = () => {
	const [t] = useTranslation("global");

	const navigate = useNavigate();
	const { cookies } = useContext(GlobalContext);
	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
	const [anchorElUser, setAnchorElUser] = useState(null);

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const logout = () => {
		cookies.remove("token", { path: "/" });
		localStorage.clear();
		navigate("/");
	}

	const userMenu = [
		{ text: t("AppBarMui.settings"), onClick: () => { navigate("/settings"); }, icon: <Settings sx={{ color: COLORS.light.primary }} /> },
		{ text: t("AppBarMui.logout"), onClick: () => { logout(); }, icon: <Logout sx={{ color: COLORS.light.primary }} /> }
	];

	const drawerWidth = 80;

	return (
		<AppBar
			sx={{
				backgroundColor: COLORS.light.primary,
				boxShadow: "none",
				position: "absolute",
				width: `calc(100% - ${drawerWidth}px)`,
				ml: `${drawerWidth}px`
			}}
		>
			<Toolbar
				disableGutters
				sx={{
					margin: "0 25px"
				}}
			>

				{
					(userInfo?.name) &&
					<>
						<Typography
							sx={{
								color: "#ffffff",
								fontWeight: "800"
							}}
							variant={"h6"}
						>
							{userInfo.name}
						</Typography>
					</>
				}

				<Box sx={{ flexGrow: 1 }}></Box>

				<Box sx={{ flexGrow: 0 }}>
					<IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
						<Avatar src={userDefault} />
					</IconButton>
					<Menu
						sx={{
							marginTop: "45px",
						}}
						id="menu-appbar"
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}
					>

						{
							userMenu.map((value, index) => (
								<MenuItem
									key={index}
									onClick={value.onClick}
								>
									<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
										{value.icon}
										<Typography
											sx={{
												color: COLORS.light.primary,
												marginLeft: "8px"
											}}
										>
											{value.text}
										</Typography>
									</div>
								</MenuItem>
							))
						}
					</Menu>
				</Box>

			</Toolbar>
		</AppBar>
	);
}

export default AppBarMui;
