import { Container } from "@mui/system";
import React from "react";

import noConnection from "../img/noConnection.jpg";

const NoConnection = () => {
	return (
		<Container>
			<img
        alt={"No internet connection"}
        src={noConnection}
        height={"50%"}
      />
		</Container>
		);
}

export default NoConnection;
