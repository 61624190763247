import { Message } from "@mui/icons-material";
import { AppBar, Button, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../colors";

import "../css/HomeAppBar.css";

const HomeAppBar = () => {
	const navigate = useNavigate();

	const styles = {
		appbarButton: {
			color: COLORS.light.text,
			border: "2px solid transparent",
			"&:hover": {
				border: "2px solid #ffffff"
			}
		},
		appbarButtonLogin: {
			backgroundColor: COLORS.light.primary,
			color: "#ffffff",
			border: "2px solid " + COLORS.light.primary,
			"&:hover": {
				backgroundColor: "transparent"
			}
		},
		linkText: {
			color: "#ffffff",
			textDecoration: "none"
		}
	};

	return (
		<>
			<AppBar
				sx={{
					backgroundColor: "transparent",
					boxShadow: "none",
					position: "absolute"
				}}
			>
				<Toolbar
				>
					<Box
						sx={{
							display: "flex",
							gap: 3,
							justifyContent: "center",
							alignItems: "center",
							alignContent: "center"
						}}
					>
						<Message />
						<Typography
							variant={"h5"}
							fontWeight={"700"}
						>
							TwilioGUI
						</Typography>
					</Box>

					<Box sx={{ flexGrow: 1 }}></Box>

					<Box
						sx={{
							display: "flex",
							gap: 3
						}}
					>
						<a
							href="#features"
							style={styles.linkText}
						>
							<Button
								sx={styles.appbarButton}
							>
								Features
							</Button>
						</a>

						<a
							href="#pricing"
							style={styles.linkText}
						>
							<Button
								sx={styles.appbarButton}
							>
								Pricing
							</Button>
						</a>

						<a
							href="#about"
							style={styles.linkText}
						>
							<Button
								sx={styles.appbarButton}
							>
								About us
							</Button>
						</a>

						<a
							href="#contact"
							style={styles.linkText}
						>
							<Button
								sx={styles.appbarButton}
							>
								Contact
							</Button>
						</a>

					</Box>

					<Box sx={{ flexGrow: 1 }}></Box>

					<Box
						sx={{
							display: "flex",
							gap: 3
						}}
					>
						<Button
							sx={styles.appbarButton}
							onClick={() => {
								navigate("/login");
							}}
						>
							Login
						</Button>

						<Button
							sx={styles.appbarButtonLogin}
							onClick={() => {
								navigate("/signup");
							}}
							variant={"contained"}
						>
							Signup
						</Button>
					</Box>

				</Toolbar>

			</AppBar>
		</>
	)
}

export default HomeAppBar;
