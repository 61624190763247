import React, { createContext, useState } from "react";
import Cookies from "universal-cookie";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
	const cookies = new Cookies();
	const [isLoading, setIsLoading] = useState(true);
	const [sideBar, setSideBar] = useState(false);
	const [message, setMessage] = useState("");

	return (
		<GlobalContext.Provider value={{
			cookies,
			isLoading, setIsLoading,
			message, setMessage,
			sideBar, setSideBar,
		}}>
			{children}
		</GlobalContext.Provider>
	);
}
