import { ToastContainer } from 'react-toastify';
import PagesRouter from './routes/PagesRouter';

function App() {

	return (
		<>
			<PagesRouter />
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable={false}
				pauseOnHover
				theme="colored"
			/>
		</>
	);
}

export default App;
