import { Check, Email, Handshake, Close, Language, LocalPhone, LocationOn, StarBorder, Message, WorkspacePremium, VolunteerActivism } from "@mui/icons-material";
import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, Container, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import React, { useContext, useEffect } from "react";
import { COLORS } from "../colors";
import HomeAppBar from "../components/HomeAppBar";
import axios from "axios";

// Import images
import BackgroundImage from "../img/back.jpg";
import TextingWoman from "../img/tw1.jpg"; // https://pixabay.com/es/photos/mujer-smartphone-tecnología-1847044/
import ourTeam from "../img/ourTeam.jpeg";

// Import Stripe
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { notification } from "../helpers/Global";
import { GlobalContext } from "../context/GlobalContext";

const BuyPlanForm = () => {

	const stripe = useStripe();
	const elements = useElements();

	const { cookies } = useContext(GlobalContext);
	const token = cookies.get("token");

	const handleBuyPlanForm = async (evt) => {
		evt.preventDefault();
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: elements.getElement(CardElement)
		});

		if (error) {
			console.log("error:", error);
			notification("Check your credit card credentials.", "error");
			return;
		}

		console.log(paymentMethod)

		await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/stripe/checkout`,
			{
				id: paymentMethod.id,
				amount: 100 //deben ser centavos
			},
			{ headers: { "authorization": token } }
		)
			.then(({ data }) => {
				notification("Successfull transaccion", "success");
			})
			.catch(error => {
				notification("Error in payment method, please try again later.", "error");
			});

	}

	return (
		<form onSubmit={handleBuyPlanForm}>
			<CardElement />
			<Button
				variant="contained"
				type="submit"
			>
				Buy
			</Button>
		</form>
	);
}

const Home = () => {

	const styles = {
		sectionStyle: {
			height: "100vh",
			background: `url(${TextingWoman})`,
			backgroundColor: "grey",
			backgroundBlendMode: "multiply",
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover"
		},
		listItem: {
			color: COLORS.light.text,
			fontWeight: "500"
		},
		pricingButton: {
			backgroundColor: COLORS.light.ternary,
			color: COLORS.light.primary,
			border: "1px solid " + COLORS.light.primary,
			"&:hover": {
				color: COLORS.light.text,
				backgroundColor: COLORS.light.primary,
				border: "1px solid " + COLORS.light.primary
			}
		},
		pricingButtonPro: {
			backgroundColor: COLORS.light.primary,
			color: COLORS.light.text,
			border: "1px solid " + COLORS.light.primary,
			"&:hover": {
				color: COLORS.light.primary,
				backgroundColor: "transparent",
				border: "1px solid " + COLORS.light.primary
			}
		},
		sendMessage: {
			backgroundColor: COLORS.light.primary,
			border: "2px solid " + COLORS.light.primary,
			color: COLORS.light.text,
			"&:hover": {
				backgroundColor: "transparent",
				color: COLORS.light.primary
			}
		},
		homeTitle: {
			fontWeight: "700",
			textAlign: "center",
			marginTop: 5,
			color: COLORS.light.primary
		},
		iconPricingStyle: {
			color: COLORS.light.primary
		}
	};

	const tiers = [
		{
			title: 'Lite',
			price: '1',
			description: [
				{ text: "Unlimited bulk messages", icon: <Check sx={styles.iconPricingStyle} /> },
				{ text: "SMS support", icon: <Check sx={styles.iconPricingStyle} /> },
				{ text: "MMS support", icon: <Close sx={styles.iconPricingStyle} /> },
				{ text: "Per to per chat", icon: <Close sx={styles.iconPricingStyle} /> },
				{ text: "Contacts administration module", icon: <Close sx={styles.iconPricingStyle} /> }
			],
			buttonText: 'Get Started',
		},
		{
			title: 'Pro',
			subheader: 'Most popular',
			price: '2',
			description: [
				{ text: "Unlimited bulk messages", icon: <Check sx={styles.iconPricingStyle} /> },
				{ text: "SMS support", icon: <Check sx={styles.iconPricingStyle} /> },
				{ text: "MMS support", icon: <Check sx={styles.iconPricingStyle} /> },
				{ text: "Per to per chat", icon: <Close sx={styles.iconPricingStyle} /> },
				{ text: "Contacts administration module", icon: <Close sx={styles.iconPricingStyle} /> }
			],
			buttonText: 'Get started',
		},
		{
			title: 'Enterprise',
			price: '3',
			description: [
				{ text: "Unlimited bulk messages", icon: <Check sx={styles.iconPricingStyle} /> },
				{ text: "SMS support", icon: <Check sx={styles.iconPricingStyle} /> },
				{ text: "MMS support", icon: <Check sx={styles.iconPricingStyle} /> },
				{ text: "Per to per chat", icon: <Check sx={styles.iconPricingStyle} /> },
				{ text: "Contacts administration module", icon: <Check sx={styles.iconPricingStyle} /> }
			],
			buttonText: 'Get Started',
		}
	];

	const ourValues = [
		{
			icon:
				<Handshake sx={{
					height: "64px",
					width: "64px",
					color: COLORS.light.text
				}}
				/>,
			title: "Trabajo en equipo",
			text: "Somos una comunidad que se apoya constantemente."
		},
		{
			icon:
				<WorkspacePremium sx={{
					height: "64px",
					width: "64px",
					color: COLORS.light.text
				}}
				/>,
			title: "Calidad",
			text: "Garantizamos los mejores resultados."
		},
		{
			icon:
				<VolunteerActivism sx={{
					height: "64px",
					width: "64px",
					color: COLORS.light.text
				}}
				/>,
			title: "Honestidad",
			text: "Te asesoramos siempre con la verdad."
		},
	];

	const contactInfo = [
		{
			icon:
				<LocationOn sx={{
					height: "64px",
					width: "64px",
					color: COLORS.light.text,
					backgroundColor: COLORS.light.primary,
					borderRadius: "100%",
					padding: "10px"
				}}
				/>,
			content: [
				{ text: "Ambato, Leonidas Plaza y Vicente Ramón Roca N.W. 05-325", type: "text" },
			]
		},
		{
			icon:
				<LocalPhone sx={{
					height: "64px",
					width: "64px",
					color: COLORS.light.text,
					backgroundColor: COLORS.light.primary,
					borderRadius: "100%",
					padding: "10px"
				}}
				/>,
			content: [
				{ text: "+593998139770", type: "tel" },
				{ text: "+593996691057", type: "tel" }
			]
		},
		{
			icon:
				<Email sx={{
					height: "64px",
					width: "64px",
					color: COLORS.light.text,
					backgroundColor: COLORS.light.primary,
					borderRadius: "100%",
					padding: "10px"
				}}
				/>,
			content: [
				{ text: "info@acrdigitalmarketing.com", type: "mailto" }
			]
		},
		{
			icon:
				<Language sx={{
					height: "64px",
					width: "64px",
					color: COLORS.light.text,
					backgroundColor: COLORS.light.primary,
					borderRadius: "100%",
					padding: "10px"
				}}
				/>,
			isWebsite: true,
			content: [
				{ text: "https://www.acrdigitalmarketing.com", type: "link" }
			]
		}
	];

	const acrLocation = { lat: -1.2610776704943172, lng: -78.62735235825528 };

	const { isLoaded } = useLoadScript({ googleMapsApiKey: `${process.env.REACT_APP_MAPS_API_KEY}` });

	useEffect(() => {
		document.title = "Twilio GUI";
	}, []);

	return (
		<>
			<Box
				sx={{
					display: "flex"
				}}
			>
				<HomeAppBar />
				<Box
					sx={{
						width: "100%",
						margin: "0 auto",
					}}
				>

					{/* Background texts */}
					<Box
						display={"flex"}
						sx={{
							paddingLeft: "32px",
							...styles.sectionStyle
						}}
					>

						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								gap: 5,
							}}
						>
							<Typography
								variant={"h2"}
								sx={styles.listItem}
							>
								Join us to our community
							</Typography>

							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									gap: 1
								}}
							>
								<Typography
									sx={styles.listItem}
								>
									- Send bulk messages.
								</Typography>

								<Typography
									sx={styles.listItem}
								>
									- A modern user interface for twilio users.
								</Typography>

								<Typography
									sx={styles.listItem}
								>
									- Person to person chat.
								</Typography>

								<Typography
									sx={styles.listItem}
								>
									- Reports.
								</Typography>
							</Box>
						</Box>
					</Box>

					{/* Features */}
					<Box
						id="features"
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							backgroundColor: COLORS.light.ternary,
							padding: "16px"
						}}
					>
						<Typography
							variant="h2"
							gutterBottom
							sx={styles.homeTitle}
						>
							Features
						</Typography>

						<Typography
							sx={{
								textAlign: "center"
							}}
							variant="h5"
						>
							TwilioGUI it's a very powerfull interface for Twilio users, this is what we can offer to you:
						</Typography>

						<Box
							sx={{
								flexGrow: 1
							}}
						>
							<Grid container spacing={2}>
								<Grid item sm={6} md={3}>
									<Card
										sx={{
											height: "100%",
											borderBottom: "1px solid " + COLORS.light.primary
										}}
									>

										<CardMedia
											component={"img"}
											image="https://www.bulksms.com/images/bulksms-responsive-web-app.png?v=1"
											alt="Bulk messages"
										/>

										<CardContent
											sx={{
												border: "1px solid " + COLORS.light.primary,
												height: "100%"
											}}
										>
											<Typography
												variant="h5"
												sx={{
													textAlign: "center",
													color: COLORS.light.primary
												}}
											>
												Bulk Messages
											</Typography>

											<Typography
												sx={{
													textAlign: "center"
												}}
											>
												Send SMS and MMS to thousands of people at the same time.
											</Typography>
										</CardContent>

									</Card>
								</Grid>
								<Grid item sm={6} md={3}>
									<Card
										sx={{
											height: "100%",
											borderBottom: "1px solid " + COLORS.light.primary
										}}
									>

										<CardMedia
											component={"img"}
											image="https://www.bulksms.com/images/bulksms-responsive-web-app.png?v=1"
											alt="Bulk messages"
										/>

										<CardContent
											sx={{
												border: "1px solid " + COLORS.light.primary,
												height: "100%"
											}}
										>
											<Typography
												variant="h5"
												sx={{
													textAlign: "center",
													color: COLORS.light.primary
												}}
											>
												Contact administration
											</Typography>

											<Typography
												sx={{
													textAlign: "center"
												}}
											>
												Add contacts to chat with him.
											</Typography>
										</CardContent>

									</Card>
								</Grid>
								<Grid item sm={6} md={3}>
									<Card
										sx={{
											height: "100%",
											borderBottom: "1px solid " + COLORS.light.primary
										}}
									>

										<CardMedia
											component={"img"}
											image="https://www.bulksms.com/images/bulksms-responsive-web-app.png?v=1"
											alt="Bulk messages"
										/>

										<CardContent
											sx={{
												border: "1px solid " + COLORS.light.primary,
												height: "100%"
											}}
										>
											<Typography
												variant="h5"
												sx={{
													textAlign: "center",
													color: COLORS.light.primary
												}}
											>
												Logs
											</Typography>

											<Typography
												sx={{
													textAlign: "center"
												}}
											>
												Record of all your sent messages.
											</Typography>
										</CardContent>

									</Card>
								</Grid>
								<Grid item sm={6} md={3}>
									<Card
										sx={{
											height: "100%",
											borderBottom: "1px solid " + COLORS.light.primary
										}}
									>

										<CardMedia
											component={"img"}
											image="https://www.bulksms.com/images/bulksms-responsive-web-app.png?v=1"
											alt="Bulk messages"
										/>

										<CardContent
											sx={{
												border: "1px solid " + COLORS.light.primary,
												height: "100%"
											}}
										>
											<Typography
												variant="h5"
												sx={{
													textAlign: "center",
													color: COLORS.light.primary
												}}
											>
												Blacklist records
											</Typography>

											<Typography
												sx={{
													textAlign: "center"
												}}
											>
												Record of all your users has opted for not receive your messages.
											</Typography>
										</CardContent>

									</Card>
								</Grid>
							</Grid>
						</Box>
					</Box>


					{/* Pricing */}
					<Box
						id="pricing"
						sx={{
							marginTop: 5,
							paddingBottom: 5,
							padding: "0 5%",
						}}
					>

						<Typography
							variant="h2"
							gutterBottom
							sx={styles.homeTitle}
						>
							Pricing
						</Typography>

						<Typography
							variant="h5"
							align="center"
							color={COLORS.black}
							component="p"
							sx={{
								marginTop: 5,
								marginBottom: 5
							}}
						>
							Quickly build an effective pricing table for your potential customers with
							this layout. It&apos;s built with default MUI components with little
							customization.
						</Typography>

						<Grid
							alignItems="flex-end"
							container spacing={5}
							sx={{
							}}
						>
							{tiers.map((tier, index) => (
								// Enterprise card is full width at sm breakpoint
								<Grid
									item
									key={index}
									xs={12}
									sm={tier.title === 'Enterprise' ? 12 : 6}
									md={4}
									sx={{
									}}
								>
									<Card>
										<CardHeader
											title={tier.title}
											subheader={tier.subheader}
											titleTypographyProps={{ align: 'center' }}
											action={tier.title === 'Pro' ? <StarBorder /> : null}
											subheaderTypographyProps={{
												align: 'center',
												color: COLORS.light.text
											}}
											sx={{
												backgroundColor: COLORS.light.primary,
												color: COLORS.light.text
											}}
										/>
										<CardContent>
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'baseline',
													mb: 2,
												}}
											>
												<Typography
													component="h2"
													variant="h3"
													color={COLORS.black}
												>
													${tier.price}
												</Typography>
												<Typography
													variant="h6"
													color={COLORS.light.secondary}
												>
													/mo
												</Typography>
											</Box>

											<Divider
												sx={{
													width: "100%",
													backgroundColor: COLORS.light.primary,
													margin: "16px 0"
												}}
											/>

											{tier.description.map((value, index) => (
												<Box
													key={index}
													sx={{
														display: "flex",
														flexDirection: "column"
													}}
												>
													<Box
														sx={{
															display: "flex",
															flexDirection: "row",
															gap: 2
														}}
													>
														{value.icon}
														<Typography
															variant="subtitle1"
															align="left"
															key={index}
														>
															{value.text}
														</Typography>
													</Box>
												</Box>
											))}
										</CardContent>
										<CardActions>
											<Button
												fullWidth
												variant={tier.buttonVariant}
												sx={(tier.title === "Pro") ? styles.pricingButtonPro : styles.pricingButton}
											>
												{tier.buttonText}
											</Button>
										</CardActions>
									</Card>
								</Grid>
							))}
						</Grid>

					</Box>

					{/* About us */}
					<Box
						id="about"
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 3,
							backgroundColor: COLORS.light.ternary,
							width: "100%",
							marginY: "16px",
							padding: "16px"
						}}
					>

						<Typography
							variant="h2"
							gutterBottom
							sx={styles.homeTitle}
						>
							About us
						</Typography>

						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								gap: 3
							}}
						>

							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									justifyContent: "center"
								}}
							>
								<Typography
									variant="h4"
									fontWeight="800"
									textAlign="center"
									sx={{
										color: COLORS.light.primary
									}}
								>
									Our team
								</Typography>

								<Typography
									paragraph
									textAlign="justify"
								>
									Alexandra Campaña tras dejar la docencia e incursionarse en el mundo del Marketing Digital, específicamente en campañas en Facebook, empieza con su sueño de emprender un negocio fuerte, honesto y transparente. Ese pequeño sueño se hizo realidad creando ACR Digital Marketing en agosto del 2016, siendo esta una gran oportunidad para crecer
									como profesional y generar fuentes de empleo en su ciudad natal, Ambato.
								</Typography>

							</Box>

							<Box
								sx={{
									width: "100%"
								}}
							>
								<img
									src={ourTeam}
									style={{
										height: "auto%",
										width: "100%",
										objectFit: "cover",
										borderRadius: "5px"
									}}
								/>
							</Box>
						</Box>

						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
							}}
						>

							<Typography
								variant="h2"
								textAlign="center"
								fontWeight="800"
								sx={{
									color: COLORS.light.primary
								}}
							>
								Our values
							</Typography>

							<Box
								sx={{
									display: "flex",
									gap: 3,
									marginTop: "16px"
								}}
							>

								{
									ourValues.map((value, index) => (
										<Paper
											key={index}
											elevation={0}
											sx={{
												width: "100%",
												padding: "16px 32px",
												backgroundColor: COLORS.light.primary,
												borderRadius: "10px"
											}}
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center"
												}}
											>
												{value.icon}
												<Typography
													variant="h5"
													sx={{
														textAlign: "center",
														fontWeight: "800",
														margin: "8px auto",
														color: COLORS.light.text
													}}
												>
													{value.title}
												</Typography>

												<Divider style={{ background: COLORS.light.text, width: "100%", marginBottom: "8px" }} />

												<Typography
													paragraph
													sx={{
														color: COLORS.light.text,
														textAlign: "justify"
													}}
												>
													{value.text}
												</Typography>
											</Box>

										</Paper>
									))
								}

							</Box>

						</Box>

					</Box>

					{/* Contact */}
					<Box
						id={"contact"}
						sx={{
							display: "flex",
							flexDirection: "column",
							margin: "0 5%",
						}}
					>

						<Typography
							variant="h2"
							gutterBottom
							sx={styles.homeTitle}
						>
							Contact
						</Typography>

						<Box
							sx={{
								display: "flex",
								flexdirection: "row",
								gap: 3,
							}}
						>

							{
								contactInfo.map((value, index) => (
									<Box
										key={index}
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											width: "100%",
											padding: "8px 16px",
											gap: 2,
											borderRadius: "10px",
											backgroundColor: "#ffffff",
											border: "2px solid " + COLORS.light.primary
										}}
									>
										{value.icon}
										{
											value.content.map((value, index) => (
												<Box
													key={index}
												>
													{
														(value.type === "text") &&
														<Typography
															sx={{
																textAlign: "center"
															}}
														>

															{value.text}
														</Typography>
													}
													{
														(value.type === "tel") &&
														<a
															href={`tel:${value.text}`}
															style={{
																textDecoration: "none",
																color: "#000000"
															}}

														>
															<Typography
																sx={{
																	textAlign: "center",
																}}
															>
																{value.text}
															</Typography>
														</a>
													}
													{
														(value.type === "mailto") &&
														<a
															href={`mailto:${value.text}`}
															style={{
																textDecoration: "none",
																color: "#000000"
															}}
														>
															<Typography
																sx={{
																	textAlign: "center",
																}}
															>
																{value.text}
															</Typography>
														</a>
													}
													{
														(value.type === "link") &&
														<a
															href={`${value.text}`}
															style={{
																textDecoration: "none",
																color: "#000000"
															}}
															target={"_blank"}
															rel="noreferrer"
														>
															<Typography
																sx={{
																	textAlign: "center",
																}}
															>
																{value.text}
															</Typography>
														</a>
													}
												</Box>
											))
										}
									</Box>
								))
							}

						</Box>

						<Box
							sx={{
								margin: "32px auto",
								display: "flex",
								width: "100%",
								gap: 2
							}}
						>

							<Box
								sx={{
									width: "100%",
									border: "2px solid " + COLORS.light.primary,
									borderRadius: "10px"
								}}
							>
								{
									(isLoaded) &&
									<GoogleMap
										center={acrLocation}
										zoom={19}
										mapContainerStyle={{
											height: "100%",
											borderRadius: "10px"
										}}
									>
										<Marker
											position={acrLocation}
										>
											<InfoWindow
												position={acrLocation}
											>
												<Typography
													sx={{
														fontSize: "1em"
													}}
												>
													ACR Digitals
												</Typography>
											</InfoWindow>
										</Marker>
									</GoogleMap>
								}
							</Box>

							<Box
								sx={{
									width: "100%",
									display: "flex",
									flexDirection: "column",
									borderRadius: "10px",
									padding: "32px",
									gap: 3,
									border: "2px solid " + COLORS.light.primary,
									backgroundColor: COLORS.light.ternary
								}}
							>

								<Typography
									sx={{
										color: COLORS.light.primary,
										fontWeight: "800",
										textAlign: "center"
									}}
									variant={"h4"}
								>
									Send us an email
								</Typography>

								<Divider
									sx={{
										width: "100%",
										backgroundColor: COLORS.light.primary
									}}
								/>

								<Typography
									sx={{
										color: COLORS.light.primary,
										fontWeight: "500"
									}}
								>
									We're open for any suggestion or just to have a chat
								</Typography>

								<form
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										height: "100%",
										gap: "16px",
										alignContent: "center"
									}}
								>

									<TextField
										required
										fullWidth
										id="contact-name"
										label="Full name"
									/>

									<TextField
										required
										fullWidth
										id="contact-subject"
										label="Subject"
									/>

									<TextField
										required
										fullWidth
										id="contact-email"
										label="Email Address"
									/>

									<TextField
										required
										fullWidth
										id="contact-message"
										label="Message"
										multiline
										minRows={4}
									/>

									<Button
										variant={"contained"}
										sx={styles.sendMessage}
										type={"submit"}
									>
										Send Message
									</Button>
								</form>
							</Box>

						</Box>

					</Box>

					{/* Footer */}
					<Box
						sx={{
							backgroundColor: COLORS.light.primary,
							padding: "16px"
						}}
					>
						<Typography
							sx={{
								textAlign: "center",
								color: COLORS.light.text
							}}
						>
							© {new Date().getFullYear()} Copyright, ACR Digital Marketing. All Rights Reserved
						</Typography>
					</Box>

				</Box>

			</Box>
		</>
	);
}

export default Home;
