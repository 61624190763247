import { AccountCircle, Cancel, CheckCircle, CloudUpload, ContactPhone, Create, Delete, FileDownload, FileUpload, FindInPage, Inbox, Person, PersonAdd, Save, Upload } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Step, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useNavigate, useParams } from "react-router-dom";
import Papa from "papaparse";

import ConfirmationDialog from "../components/ConfirmationDialog";
import CustomTooltip from "../components/CustomTooltip";
import ImportContactsDiv from "../components/ImportContactsDiv";
import Loader from "../components/Loader";
import { ChatContext, ChatProvider } from "../context/ChatContext";
import { GlobalContext } from "../context/GlobalContext";
import { notification, verifyE164Format } from "../helpers/Global";

import "../css/Chat.css";
import "../css/Contact.css";
import MainContainer from "../components/MainContainer";
import { Container } from "@mui/system";
import { COLORS } from "../colors";
import ContentContainer from "../components/ContentContainer";

const styles = {
	buttonWithBackground: {
		backgroundColor: COLORS.light.primary,
		border: "2px solid " + COLORS.light.ternary,
		color: COLORS.light.text,
		"&:hover": {
			backgroundColor: COLORS.light.ternary,
			color: COLORS.light.primary,
			border: "2px solid " + COLORS.light.primary
		}
	}
};

const Contacts = () => {

	useEffect(() => {
		document.title = "Contacts";
	}, []);

	return (
		<>
			<MainContainer>
				<ChatProvider>
					<ContactDashboard />
				</ChatProvider>
			</MainContainer>
		</>
	);
}

export default Contacts;

const ContactDashboard = () => {
	const { idContact } = useParams();

	return (
		<>
			{
				(!idContact) ? <ContactsList /> : <ContactInfo />
			}
		</>
	);
}

const ContactsList = () => {
	const { cookies, isLoading, setIsLoading } = useContext(GlobalContext);
	const { setCurrentContact } = useContext(ChatContext);
	const [csvContactsData, setCsvContactsData] = useState([]);
	const navigate = useNavigate();
	const token = cookies.get("token");
	const fileName = "contacts-" + moment().format("YYYYMMDDhhmmss") + ".csv";
	const csvLink = useRef();

	const columns = [
		{ field: "_id", headerName: "Id", hide: true },
		{ field: "name", headerName: "Name", flex: 1 },
		{ field: "lastname", headerName: "Lastname", flex: 1 },
		{ field: "phone", headerName: "Phone", flex: 1 },
		{ field: "email", headerName: "Email", flex: 1 }
	];

	const [pageNumber, setPageNumber] = useState(1);
	const [nPerPage, setNPerPage] = useState(10);
	const [total, setTotal] = useState(0);
	const [rowData, setRowData] = useState([]);

	const getContacts = async () => {
		setIsLoading(true);
		await axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/contacts/getContacts`,
			{ headers: { "authorization": token }, params: { pageNumber, nPerPage } }
		).then(({ data }) => {
			setTotal(data.message.total);
			setRowData(data.message.result);
		}).catch(error => {
			console.log("Chats.getContacts-error:", error);
			notification("Getting contact list error, please try again later. If the problem persists contact support", "error");
		});
		setIsLoading(false);
	}

	const exportContacts = async () => {
		await axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/contacts/exportContacts`,
			{ headers: { "authorization": token } }
		)
			.then(({ data }) => {
				let dataCSV = data.message;
				let tempCsvData = [];
				for (let i in dataCSV) {
					let tempData = {};
					if (dataCSV[i].name) {
						tempData.name = dataCSV[i].name;
					}
					if (dataCSV[i].lastname) {
						tempData.lastname = dataCSV[i].lastname;
					}
					if (!dataCSV[i].phone) {
						continue;
					}
					tempData.phone = dataCSV[i].phone;
					if (dataCSV[i].email) {
						tempData.email = dataCSV[i].email;
					}
					tempCsvData.push(tempData);
				}
				setCsvContactsData(tempCsvData);
				setTimeout(() => {
					csvLink.current.link.click();
				}, 1000);
			})
			.catch(error => {
				console.log("Contacts.exportContacts-error:", error);
				notification("Exporting contacts error, please try again later. If the problem persists contact to support.", "error");
			});
	}

	useEffect(() => {
		getContacts();
	}, [pageNumber, nPerPage]);

	return (
		<>
			{
				(isLoading) ?
					<Loader />
					:
					<Box
						sx={{
							width: "100%",
							backgroundColor: "yellow"
						}}
					>
						<div style={{ width: "100%" }}>

							<div style={{ height: "50px", width: "100%", display: "flex", flexDirection: "row", justifyContent: "left", gap: "10px", alignItems: "center", border: "2px solid " + COLORS.light.primary, paddingLeft: "10px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", backgroundColor: COLORS.light.ternary, borderBottom: "0px", backgroundColor: COLORS.light.primary }}>

								<div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", gap: "10px" }}>
									<Person sx={{ color: COLORS.light.ternary }} />
									<Typography sx={{ color: COLORS.light.text, fontWeight: "800" }}>Contacts</Typography>
								</div>

								<div style={{ width: "50%", marginRight: "15px", display: "flex", justifyContent: "right" }}>
									<CustomTooltip placement={"top"} title={"Add a single contact"}>
										<IconButton sx={{ color: COLORS.light.ternary }} size="large" component="label" onClick={() => { navigate("/contacts/add"); }}>
											<PersonAdd fontSize="inherit" />
										</IconButton>
									</CustomTooltip>

									<CustomTooltip placement={"top"} title={"Import contacts"}>
										<IconButton sx={{ color: COLORS.light.ternary }} size="large" component="label" onClick={() => { navigate("/contacts/import"); }}>
											<FileUpload fontSize="inherit" />
										</IconButton>
									</CustomTooltip>

									<CustomTooltip placement={"top"} title={"Export contacts"}>
										<IconButton
											component="label"
											onClick={() => {
												exportContacts();
											}}
											size="large"
											sx={{ color: COLORS.light.ternary }}
										>
											<FileDownload fontSize="inherit" />
										</IconButton>
									</CustomTooltip>

									<CSVLink
										data={csvContactsData}
										filename={fileName}
										ref={csvLink}
										style={{
											display: "none"
										}}
										target={"_blank"}
									>
									</CSVLink>

								</div>

							</div>

						</div>

						<div style={{ width: "100%" }}>
							<DataGrid
								autoHeight
								checkboxSelection={false}
								disableSelectionOnClick
								columns={columns}
								getRowId={(row) => row._id}
								onPageChange={(newPageNumber) => { setPageNumber(newPageNumber + 1); }}
								onPageSizeChange={(newPageSize) => { setNPerPage(newPageSize); }}
								onRowClick={evt => { setCurrentContact(evt.row); navigate("/contacts/" + evt.row._id); }}
								page={pageNumber - 1}
								pageSize={nPerPage}
								pagination
								paginationMode={"server"}
								rows={rowData}
								rowCount={total}
								rowsPerPageOptions={[10, 30, 50]}
								sx={{
									backgroundColor: COLORS.light.ternary,
									border: "2px solid " + COLORS.light.primary,
									borderTopLeftRadius: "0px",
									borderTopRightRadius: "0px"
								}}
							/>
						</div>
					</Box>
			}
		</>
	);
}

const ContactInfo = () => {

	const { currentContact, setCurrentContact } = useContext(ChatContext);
	const { isLoading, setIsLoading, cookies } = useContext(GlobalContext);
	const [nameTextField, setNameTextField] = useState("");
	const [lastnameTextField, setLastnameTextField] = useState("");
	const [phoneTextField, setPhoneTextField] = useState("");
	const [emailTextField, setEmailTextField] = useState("");
	const [selectedFile, setSelectedFile] = useState(undefined);
	const [activeStep, setActiveStep] = useState(0);
	const [validPhones, setValidPhones] = useState([]);
	const [invalidPhones, setInvalidPhones] = useState([]);
	const [onDatabasePhones, setOnDatabasePhones] = useState([]);

	const columnsValidPhones = [
		{ field: "id", headerName: "Id", hide: true },
		{ field: "name", headerName: "Name", flex: 1 },
		{ field: "lastname", headerName: "Lastname", flex: 1 },
		{ field: "phone", headerName: "Phone", flex: 1 },
		{ field: "email", headerName: "Email", flex: 1 }
	];

	const columnsInvalidPhones = [
		{ field: "id", headerName: "Id", hide: true },
		{ field: "name", headerName: "Name", flex: 1 },
		{ field: "lastname", headerName: "Lastname", flex: 1 },
		{ field: "phone", headerName: "Phone", flex: 1 },
		{ field: "email", headerName: "Email", flex: 1 },
		{ field: "reason", headerName: "Reason", flex: 1 }
	];

	const csvExampleData = [
		["Name", "Lastname", "Phone", "Email"],
		["John", "Smith", "9195558888", "jr@fake.com"],
		["John", "Doe", "9195558888", "jd@fake.com"]
	];

	const { idContact } = useParams();
	const navigate = useNavigate();
	const token = cookies.get("token");
	const INPUT_MAX_LENGTH = 50;
	const csvExampleLink = useRef();

	const setContactData = (data) => {
		if (data.name) {
			setNameTextField(data.name);
		}
		if (data.lastname) {
			setLastnameTextField(data.lastname);
		}
		if (data.phone) {
			setPhoneTextField(data.phone);
		}
		if (data.email) {
			setEmailTextField(data.email);
		}

		if (data.name && data.lastname) {
			data.identity = data.name + " " + data.lastname;
		} else {
			data.identity = data.phone;
		}

		setCurrentContact(data);
	}

	const getContactInfo = async () => {
		await axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/contacts/getContactById/${idContact}`,
			{ headers: { "authorization": token } }
		)
			.then(({ data }) => {
				setContactData(data.message);
				setCurrentContact(data.message);
				return data.message;
			})
			.catch(error => {
				console.log("Chats.getAllMessages-getContactByPhoneError:", error);
				return null;
			});
	}

	const verifyDataBeforeStoreOnDatabase = (phone) => {
		let isValid = true;

		if (!verifyE164Format(phone)) {
			isValid = false;
			notification("Invalid phone number. The phone number it should be on E.164 format. Example: +13059026233", "error");
		}

		return isValid;
	}

	const sendMessage = () => {
		navigate("/chats/" + currentContact._id);
	}

	const updateContact = async () => {

		let tempPhoneTextField = phoneTextField;
		if (!tempPhoneTextField.startsWith("+")) {
			tempPhoneTextField = "+" + tempPhoneTextField;
			setPhoneTextField(tempPhoneTextField);
		}

		if (!verifyDataBeforeStoreOnDatabase(tempPhoneTextField)) {
			return;
		}

		await axios.put(
			`${process.env.REACT_APP_BASE_URL}/api/contacts/update`,
			{
				filter: {
					_id: idContact
				},
				update: {
					name: nameTextField,
					lastname: lastnameTextField,
					phone: tempPhoneTextField,
					email: emailTextField
				}
			},
			{ headers: { "authorization": token } }
		)
			.then(result => {
				navigate("/contacts");
				notification("Contact correctly updated!", "success");
			})
			.catch(error => {
				console.log("Contacts.updateContact-error:", error);

				notification("Update contact error, please try again later. If the problem persists contact to support.", "error");
			});
	}

	const deleteContact = async () => {
		await axios.delete(
			`${process.env.REACT_APP_BASE_URL}/api/contacts/deleteById`,
			{ headers: { "authorization": token }, params: { _id: idContact } }
		).then(result => {
			console.log("Deleted contact:", result);
			navigate("/contacts");
			notification("Contact and chat deleted!", "success");
		}).catch(error => {
			console.log("Contact.deleteContact.error", error);
			notification("Error trying to delete chat and contact, please try again later. If the problem persists contact to support.", "error");
		});
	}

	const saveContact = async () => {
		let tempPhoneTextField = phoneTextField;
		if (!tempPhoneTextField.startsWith("+")) {
			tempPhoneTextField = "+" + tempPhoneTextField;
			setPhoneTextField(tempPhoneTextField);
		}

		if (!verifyDataBeforeStoreOnDatabase(tempPhoneTextField)) {
			return;
		}

		await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/contacts/save`,
			{
				name: nameTextField,
				lastname: lastnameTextField,
				phone: tempPhoneTextField,
				email: emailTextField
			},
			{ headers: { "authorization": token } }
		).then(result => {
			navigate("/contacts");
			notification("Contact created!", "success");
		}).catch(error => {
			console.log("Contacts.saveContact-error:", error);
			if (error.response.data.error.code) {
				switch (error.response.data.error.code) {
					case 11000:
						notification("This phone number is already registered in the contacts.", "error");
						break;
				}
				return;
			}
			notification("Creating contact error, please try again later, if the problem persists contact to support.", "error");
		});
	}

	const existsPhoneOnContacts = async () => {
		setIsLoading(true);

		let phoneList = [];
		for (let i in validPhones) {
			phoneList.push(validPhones[i].phone);
		}

		console.log("phoneList:", phoneList);

		let result = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/contacts/existsPhoneOnContacts`,
			{ phoneList },
			{ headers: { "authorization": token } }
		);

		if (!result.data.OK) {
			console.log("Contacts.existsPhoneOnContacts.gettingContactListError:", result.data);
			notification("Error fetching from database, please try again later. If the problem persists contact to support.", "error");
			return;
		}

		let repeated = result.data.message.result;

		let valids = [...validPhones];
		let onDatabase = [];

		for (let i in valids) {
			for (let j in repeated) {
				if (valids[i].phone === repeated[j].phone) {
					onDatabase.push({ id: i, name: valids[i].name, lastname: valids[i].lastname, phone: valids[i].phone, email: valids[i].email, reason: "Is on the database." });
					valids.splice(i, 1);
				}
			}
		}

		setValidPhones(valids);
		setOnDatabasePhones(onDatabase);

		setIsLoading(false);
	}

	const importContacts = async () => {
		let result = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/contacts/importContacts`,
			{ validPhones },
			{ headers: { "authorization": token } }
		);

		if (!result.data.OK) {
			console.log("Contacts.importContacts.importingContactListError:", result.data);
			notification("Error importing contacts to database, please try again later. If the problem persists contact to support.", "error");
			return;
		}

		console.log(result.data);

		notification(`${result.data.message.result.length} contacts imported correctly`, "success");

	}

	const cancelContact = () => {
		navigate("/contacts");
	}

	const readCsvFile = () => {

		// verifica si el archivo no es undefined
		if (selectedFile === undefined) {
			notification("No file selected.", "error");
			return;
		}

		// revisa la extension del archivo
		const fileExtension = selectedFile.name.split(".").pop();
		const validExtensions = ["csv"];

		if (!validExtensions.includes(fileExtension)) {
			notification("You've provided a file with extension: '" + fileExtension + "'. Only csv files are allowed.", "error");
			return;
		}

		setValidPhones([]);
		setInvalidPhones([]);

		const reader = new FileReader();

		let valids = [];
		let invalids = [];

		reader.readAsText(selectedFile);

		reader.onload = () => {
			Papa.parse(reader.result, {
				skipEmptyLines: true,
				complete: (results) => {
					let tempContact = [];
					for (let i = 1; i < results.data.length; i++) {
						tempContact = results.data[i];
						tempContact[2] = tempContact[2].replaceAll(/[^0-9]/g, "");

						if (tempContact[2] === "" || tempContact[2].length === 0) {
							continue;
						}

						if (!tempContact[2].startsWith("+")) {
							tempContact[2] = "+" + tempContact[2];
						}
						if (verifyE164Format(tempContact[2])) {
							valids.push({ id: i, name: tempContact[0], lastname: tempContact[1], phone: tempContact[2], email: tempContact[3] });
						} else {
							invalids.push({ id: i, name: tempContact[0], lastname: tempContact[1], phone: tempContact[2], email: tempContact[3], reason: "Phones should be on E164 format." });
						}
					}

					let notRepeated = [];
					let repeated = [];

					for (let i in valids) {
						if (!notRepeated.includes(valids[i].phone)) {
							notRepeated.push(valids[i].phone);
						} else {
							repeated.push(valids[i]);
						}
					}

					for (let i in repeated) {
						const index = valids.indexOf(repeated[i]);
						invalids.push({ id: valids[index].id, name: valids[index].name, lastname: valids[index].lastname, phone: valids[index].phone, email: valids[index].email, reason: "Duplicated phone number." });
						valids.splice(index, 1);

					}

					// console.log("repeated:", repeated);
					// console.log("notRepeated:", notRepeated);

					// console.log("valids:", valids);
					// console.log("invalids:", invalids);

					if (valids.length < 1) {
						notification("Not valid phone numbers found on " + selectedFile.name + " file. Phone numbers should be on the first columnd and be on E164 format.", "error");
						return;
					}

					setValidPhones(valids);
					setInvalidPhones(invalids);
				},
				error: (error) => {
					console.log("BulkSms.loadPhonesFromFile-onload:", error);
					notification("Error getting the csv data, please try again later. If the problem persists contact to support.", "error");
				}
			})
		};

		reader.onerror = () => {
			console.log("BulkSms.loadPhonesFromFile-onerror:", reader.error);
			notification("Error reading csv file, please try again later. If the problem persists contact to support.", "error");
		}

	}

	useEffect(() => {
		setIsLoading(false);
		if (idContact !== "add" && idContact !== "import") {
			getContactInfo();
		}
	}, []);

	return (
		<>
			{
				(isLoading)
					? <Loader />
					:
					<>
						{
							(idContact === "import")
								?
								<Box
									sx={{
										width: "100%",
										backgroundColor: COLORS.light.ternary,
										padding: "16px",
										borderRadius: "5px",
										border: "2px solid " + COLORS.light.primary
									}}
								>

									<Stepper
										alternativeLabel
										activeStep={activeStep}
										sx={{
											paddingBottom: "16px",
											'& .MuiStepLabel-root .Mui-completed': {
												color: COLORS.light.primary, // circle color (COMPLETED)
											},
											'& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
											{
												color: "#000", // Just text label (COMPLETED)
											},
											'& .MuiStepLabel-root .Mui-active': {
												color: COLORS.light.primary, // circle color (ACTIVE)
											},
											'& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
											{
												color: "#000", // Just text label (ACTIVE)
											},
											'& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
												fill: COLORS.light.ternary, // circle's number (ACTIVE)
											},
										}}
									>

										{/* 0. Select csv file */}
										<Step>
											<StepLabel>
												Select your csv file
											</StepLabel>
										</Step>

										{/* 1. Load data */}
										<Step>
											<StepLabel>
												Csv file data
											</StepLabel>
										</Step>

										{/* 2. Check if somebody is already on the database */}
										<Step>
											<StepLabel>
												Check if there is somebody on the database
											</StepLabel>
										</Step>

										{/* 3. Import your contacts */}
										<Step>
											<StepLabel>
												Import your contacts
											</StepLabel>
										</Step>

									</Stepper>

									<Divider
										style={{
											background: COLORS.light.primary,
											width: "100%"
										}}
									/>
									<Container maxWidth={"sm"}>

										{
											(activeStep === 0) &&
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													padding: "16px",
													gap: 2
												}}
											>
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														justifyContent: "left",
														alignItems: "center",
														gap: 2,
													}}
												>
													<CloudUpload
														sx={{
															color: COLORS.light.primary,
															fontSize: "64px"
														}}
													/>
													<Typography>
														Upload contacts (.csv file)
														<br />
														You can import your contacts by selecting a .csv file that contains your contacts.
														<br />
														In order to import your contacts, the .csv it should have the following format: <CSVLink filename={"CsvExample.csv"} data={csvExampleData} target="_blank" ref={csvExampleLink}>Format.csv</CSVLink>
													</Typography>

													<Box
														sx={{
															display: "flex",
															gap: 2,
															justifyContent: "space-between",
															width: "100%",
															alignItems: "center"
														}}
													>
														{
															(selectedFile === undefined)
																?
																<Typography>
																	No file selected.
																</Typography>
																:
																<Typography>
																	{selectedFile.name}
																</Typography>
														}

														<Button
															component="label"
															sx={styles.buttonWithBackground}
															variant='contained'
														>
															Select CSV File
															<input
																accept=".csv"
																id={"upload-csv"}
																hidden
																onChange={(evt) => {
																	if (!evt.target.files[0].name.endsWith(".csv")) {
																		notification("Only .csv file allowed, please try with another file.", "error");
																		setSelectedFile(undefined);
																		return;
																	}

																	if (evt.target.files.length > 0) {
																		setSelectedFile(evt.target.files[0]);
																	}
																}}
																type="file"
															/>
														</Button>

													</Box>

												</Box>

												<Box
													sx={{
														display: "flex",
														gap: 2,
														width: "100%"
													}}
												>
													<Button
														sx={{
															...styles.buttonWithBackground,
															width: "100%"
														}}
														onClick={() => {
															navigate("/contacts");
														}}
													>
														Cancel
													</Button>

													{
														(selectedFile !== undefined) &&
														<Button
															sx={{
																...styles.buttonWithBackground,
																width: "100%"
															}}
															onClick={() => {
																readCsvFile();
																setActiveStep(1);
															}}
														>
															Next
														</Button>
													}

												</Box>

											</Box>
										}

									</Container>

									{
										(activeStep === 1) &&
										<Box
											sx={{
												paddingTop: "16px",
												display: "flex",
												flexDirection: "column",
												gap: 2
											}}
										>

											{/* valid phones */}
											{
												(validPhones.length > 0) &&
												<Box
													sx={{
														backgroundColor: COLORS.light.primary,
														borderRadius: "5px"
													}}
												>
													<Typography
														sx={{
															color: COLORS.light.text,
															textAlign: "center",
															padding: "8px",
															fontWeight: "800"
														}}
													>
														Valid phone numbers
													</Typography>
													<DataGrid
														autoHeight
														rows={validPhones}
														columns={columnsValidPhones}
														pageSize={10}
														rowsPerPageOptions={[10]}
														sx={{
															border: "1px solid " + COLORS.light.primary,
															backgroundColor: COLORS.light.ternary,
															borderTopLeftRadius: 0,
															borderTopRightRadius: 0
														}}
													/>
												</Box>
											}

											{/* Invalid phones */}
											{
												(invalidPhones.length > 0) &&
												<Box
													sx={{
														backgroundColor: COLORS.light.primary,
														borderRadius: "5px"
													}}
												>
													<Typography
														sx={{
															color: COLORS.light.text,
															textAlign: "center",
															padding: "8px",
															fontWeight: "800"
														}}
													>
														Invalid phone numbers
													</Typography>
													<DataGrid
														autoHeight
														rows={invalidPhones}
														columns={columnsInvalidPhones}
														pageSize={10}
														rowsPerPageOptions={[10]}
														sx={{
															border: "1px solid " + COLORS.light.primary,
															backgroundColor: COLORS.light.ternary,
															borderTopLeftRadius: 0,
															borderTopRightRadius: 0
														}}
													/>
												</Box>
											}

											<Container
												maxWidth={"sm"}
											>
												<Box
													sx={{
														display: "flex",
														gap: 2,
														paddingTop: "16px"
													}}
												>
													<Button
														sx={{
															...styles.buttonWithBackground,
															width: "100%"
														}}
														onClick={() => {
															setActiveStep(0);
														}}
													>
														Return
													</Button>
													{
														(validPhones.length > 0) &&
														<Button
															sx={{
																...styles.buttonWithBackground,
																width: "100%"
															}}
															onClick={() => {
																existsPhoneOnContacts();
																setActiveStep(2);
															}}
														>
															Next
														</Button>
													}
												</Box>
											</Container>

										</Box>
									}

									{
										(activeStep === 2) &&
										<Box
											sx={{
												display: "flex",
												flexDirection: "column"
											}}
										>
											{/* valid phones */}
											{
												(validPhones.length > 0) &&
												<Box
													sx={{
														backgroundColor: COLORS.light.primary,
														borderRadius: "5px"
													}}
												>
													<Typography
														sx={{
															color: COLORS.light.text,
															textAlign: "center",
															padding: "8px",
															fontWeight: "800"
														}}
													>
														Valid phone numbers
													</Typography>
													<DataGrid
														autoHeight
														rows={validPhones}
														columns={columnsValidPhones}
														pageSize={10}
														rowsPerPageOptions={[10]}
														sx={{
															border: "1px solid " + COLORS.light.primary,
															backgroundColor: COLORS.light.ternary,
															borderTopLeftRadius: 0,
															borderTopRightRadius: 0
														}}
													/>
												</Box>
											}

											{/* phones on database */}
											{
												(onDatabasePhones.length > 0) &&
												<Box
													sx={{
														backgroundColor: COLORS.light.primary,
														borderRadius: "5px"
													}}
												>
													<Typography
														sx={{
															color: COLORS.light.text,
															textAlign: "center",
															padding: "8px",
															fontWeight: "800"
														}}
													>
														Already on the database
													</Typography>
													<DataGrid
														autoHeight
														rows={onDatabasePhones}
														columns={columnsInvalidPhones}
														pageSize={10}
														rowsPerPageOptions={[10]}
														sx={{
															border: "1px solid " + COLORS.light.primary,
															backgroundColor: COLORS.light.ternary,
															borderTopLeftRadius: 0,
															borderTopRightRadius: 0
														}}
													/>
												</Box>
											}

											<Container
												maxWidth={"sm"}
											>
												<Box
													sx={{
														display: "flex",
														gap: 2,
														paddingTop: "16px"
													}}
												>
													<Button
														sx={{
															...styles.buttonWithBackground,
															width: "100%"
														}}
														onClick={() => {
															setActiveStep(1);
														}}
													>
														Return
													</Button>
													{
														(validPhones.length > 0) &&
														<Button
															sx={{
																...styles.buttonWithBackground,
																width: "100%"
															}}
															onClick={() => {
																importContacts();
																setActiveStep(3);
															}}
														>
															Next
														</Button>
													}
												</Box>
											</Container>

										</Box>
									}

									{
										(activeStep === 3) &&
										<Box>
											<Typography>
												hecho
											</Typography>
										</Box>
									}

								</Box>
								:
								<div style={{ width: "100%", border: "1px solid " + COLORS.light.primary, marginBottom: "30px", borderRadius: "5px" }}>

									<div style={{ width: "100%", backgroundColor: COLORS.light.ternary }}>
										<div style={{ height: "50px", width: "100%", display: "flex", flexDirection: "row", justifyContent: "left", gap: "10px", alignItems: "center", paddingLeft: "10px", backgroundColor: COLORS.light.primary }}>

											<div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", gap: "15px" }}>
												<AccountCircle sx={{ color: COLORS.light.ternary }} fontSize="large" />
												<Typography sx={{ color: COLORS.light.text }}>
													<Typography>
														{
															(idContact === "add")
																? "New Contact"
																:
																(currentContact.name && currentContact.lastname)
																	? (currentContact.name + " " + currentContact.lastname)
																	: (currentContact.phone)
														}
													</Typography>
												</Typography>
											</div>

											{
												(idContact !== "add") &&
												<div style={{ height: "50px", display: "flex", width: "100%", justifyContent: "right", alignItems: "center", paddingRight: "10px", gap: "16px" }}>

													<Button
														onClick={() => {
															sendMessage();
														}}
														sx={styles.buttonWithBackground}
													>
														Send Message
													</Button>

													<ConfirmationDialog
														accepText={"Delete"}
														acceptButtonColor={"error"}
														onClickAccept={() => { deleteContact(); }}
														text={"If you delete this contact you also will delete the conversation. This action can't be undone."}
														textButton={"Delete"}
														title={"Delete '" + currentContact.identity + "' contact?"}
													/>

												</div>
											}

										</div>

										<div style={{ margin: "10px" }}>

											<div style={{ display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", gap: "15px" }}>
												<ContactPhone sx={{ color: COLORS.light.primary }} fontSize="large" />
												<Typography>Contact Information</Typography>
											</div>

											<div style={{ display: "flex", flexDirection: "column", gap: "15px", margin: "10px auto" }}>
												<TextField id="nameTextField" label="Name" variant="outlined" autoComplete={"off"} style={{ width: "100%" }} onChange={(evt) => { setNameTextField(evt.target.value); }} inputProps={{ maxLength: INPUT_MAX_LENGTH }} value={nameTextField} />

												<TextField id="lastnameTextField" label="Lastname" variant="outlined" autoComplete={"off"} style={{ width: "100%" }} onChange={(evt) => { setLastnameTextField(evt.target.value); }} inputProps={{ maxLength: INPUT_MAX_LENGTH }} value={lastnameTextField} />

												<TextField id="phoneTextField" label="Phone" variant="outlined" autoComplete={"off"} style={{ width: "100%" }} onChange={(evt) => { setPhoneTextField(evt.target.value); }} inputProps={{ maxLength: INPUT_MAX_LENGTH }} value={phoneTextField} />

												<TextField id="emailTextField" label="Email" variant="outlined" autoComplete={"off"} style={{ width: "100%" }} onChange={(evt) => { setEmailTextField(evt.target.value); }} inputProps={{ maxLength: INPUT_MAX_LENGTH }} value={emailTextField} />
											</div>

											<div style={{ display: "flex", justifyContent: "right", gap: "15px" }}>
												<Button
													onClick={() => {
														if (idContact === "add") {
															saveContact();
														} else {
															updateContact();
														}
													}}
													sx={styles.buttonWithBackground}
												>
													{(idContact === "add") ? "Save" : "Update"}
												</Button>

												<Button
													variant='contained'
													color="error"
													onClick={() => { cancelContact(); }}
												>
													Cancel
												</Button>
											</div>

										</div>

									</div>

								</div>
						}
					</>
			}
		</>
	);
}
