import React, { useContext, useEffect, useState } from 'react';
import axios from "axios";
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { QueryStats, Search } from '@mui/icons-material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import {
	Chart, CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	ArcElement,
	RadialLinearScale,
	BarElement
} from "chart.js";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Bar } from "react-chartjs-2";

import Loader from "../components/Loader";
import { notification } from "../helpers/Global";
import { GlobalContext } from '../context/GlobalContext';

import "../css/Stats.css";
import MainContainer from '../components/MainContainer';
import { Box, Container } from '@mui/system';
import { COLORS } from '../colors';

Chart.register(
	CategoryScale,
	LinearScale,
	PointElement,
	BarElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	ArcElement,
	RadialLinearScale
);

const styles = {
	button: {
		backgroundColor: COLORS.light.primary,
		color: "#ffffff",
		border: "2px solid " + COLORS.light.primary,
		"&:hover": {
			backgroundColor: "transparent",
			color: COLORS.light.primary
		}
	},
	buttonAlert: {
		backgroundColor: COLORS.light.alert,
		color: "#ffffff",
		border: "2px solid " + COLORS.light.alert,
		"&:hover": {
			backgroundColor: "transparent",
			color: COLORS.light.alert
		}
	},
	buttonTest: {
		backgroundColor: COLORS.light.test,
		color: "#ffffff",
		border: "2px solid " + COLORS.light.test,
		"&:hover": {
			backgroundColor: "transparent",
			color: COLORS.light.test
		}
	}
};

const dateFormat = "YYYY-MM-DD";

const Stats = () => {
	const { setIsLoading } = useContext(GlobalContext);

	useEffect(() => {
		document.title = "Stats - ACR Digitals";
		setIsLoading(false);
	}, []);

	return (
		<>
			<MainContainer>
				<StatsScreen />
			</MainContainer>
		</>
	);
}

export default Stats;

const StatsScreen = () => {
	const { isLoading, setIsLoading, cookies } = useContext(GlobalContext);
	const token = cookies.get("token");
	const [status, setStatus] = useState("all");
	const [fromDate, setFromDate] = useState(moment().subtract(7, "days").format(dateFormat));
	const [toDate, setToDate] = useState(moment().format(dateFormat));
	const [messagesStatusCount, setMessagesStatusCount] = useState([]);

	const getStats = async () => {

		if (fromDate > toDate) {
			notification("Invalid date, please select another date range.", "error");
			return;
		}

		setIsLoading(true);
		await axios.get(
			`${process.env.REACT_APP_BASE_URL}/api/sms/getMessagesStatus`,
			{ headers: { "authorization": token }, params: { fromDate, toDate, status } }
		)
			.then(({ data }) => {

				console.log(data)

				let totalDelivered, totalSent, totalUndelivered, totalFailed, totalOther;
				totalDelivered = totalSent = totalUndelivered = totalFailed = totalOther = 0;
				const resultArray = data.message;
				for (let i in resultArray) {
					const currentBulkMessage = resultArray[i];
					for (let j in currentBulkMessage.results) {
						switch (currentBulkMessage.results[j]._id) {
							case "delivered":
								totalDelivered += currentBulkMessage.results[j].count;
								break;

							case "sent":
								totalSent += currentBulkMessage.results[j].count;
								break;

							case "undelivered":
								totalUndelivered += currentBulkMessage.results[j].count;
								break;

							case "failed":
								totalFailed += currentBulkMessage.results[j].count;
								break;

							default:
								console.log(currentBulkMessage)
								totalOther += currentBulkMessage.results[j].count;
								break;
						}
					}
				}
				setMessagesStatusCount([totalDelivered, totalSent, totalUndelivered, totalFailed, totalOther]);
			})
			.catch(error => {
				console.log("Stats.getStats-getMessagesStatus:", error);
				notification("Getting stats error.", "error");
			});
		setIsLoading(false);
	}

	const onChangeStatus = (event) => {
		setStatus(event.target.value);
	}

	let dataStats = {
		labels: ["delivered", "sent", "undelivered", "failed", "other"],
		datasets: [
			{
				label: "Messages by status",
				data: [
					messagesStatusCount[0],
					messagesStatusCount[1],
					messagesStatusCount[2],
					messagesStatusCount[3],
					messagesStatusCount[4],
				],
				backgroundColor: [
					'#8dc73f',
					'#4467c5',
					'#faa306',
					'#f23551',
					'#d2d219'
				]
			}
		]
	}

	return (
		<>
			{
				(isLoading)
					? <Loader />
					:
					<>
						<Container maxWidth="xl" sx={{ overflow: "auto" }}>

							<Box
								sx={{
									backgroundColor: COLORS.light.ternary,
								}}
							>

								<div style={{ height: "50px", width: "100%", display: "flex", flexDirection: "row", justifyContent: "left", gap: "10px", alignItems: "center", border: "2px solid " + COLORS.light.primary, paddingLeft: "10px", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", backgroundColor: COLORS.light.ternary, borderBottom: "0px", backgroundColor: COLORS.light.primary }}>

									<div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "left", alignItems: "center", gap: "10px" }}>
										<QueryStats sx={{ color: COLORS.light.ternary }} />
										<Typography sx={{ color: COLORS.light.text, fontWeight: "800" }}>Sent messages by status</Typography>
									</div>

								</div>

								<Box
									sx={{
										border: "2px solid #dde0ee"
									}}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											padding: "16px",
										}}
									>
										<Box
											sx={{
												width: "100%",
												display: "flex",
												flexDirection: "row",
												gap: 2
											}}
										>
											<Box
												sx={{
													width: "100%"
												}}
											>
												<LocalizationProvider dateAdapter={AdapterMoment} >
													<DesktopDatePicker
														inputFormat={dateFormat}
														label={"From"}
														value={fromDate}
														onChange={newFromDate => {
															setFromDate(moment(newFromDate).format(dateFormat));
														}}
														renderInput={(params) => <TextField {...params} />}
													/>
												</LocalizationProvider>
											</Box>

											<Box
												sx={{
													width: "100%"
												}}
											>
												<LocalizationProvider dateAdapter={AdapterMoment}>
													<DesktopDatePicker
														label="To"
														inputFormat={dateFormat}
														value={toDate}
														onChange={newToDate => {
															setToDate(moment(newToDate).format(dateFormat));
														}}
														renderInput={(params) => <TextField {...params} />}
													/>
												</LocalizationProvider>
											</Box>

											<FormControl sx={{ width: "100%" }}>
												<InputLabel>Status</InputLabel>
												<Select
													value={status}
													defaultValue={"all"}
													onChange={onChangeStatus}
													label={"Status"}
												>
													<MenuItem value={"all"}>All</MenuItem>
													<MenuItem value={"available"}>Available</MenuItem>
													<MenuItem value={"archived"}>Archived</MenuItem>
												</Select>
											</FormControl>
										</Box>

										<Box
											sx={{
												width: "100%",
												display: "flex",
												justifyContent: "right",
												alignContent: "center"
											}}
										>
											<Button
												onClick={() => { getStats(); }}
												size="medium"
												startIcon={<Search />}
												variant='contained'
												sx={{
													...styles.button
												}}
											>
												Search
											</Button>
										</Box>

									</Box>

									<Box
										sx={{
											padding: "16px",
											display: "flex",
											flexDirection: "column",
											gap: 2
										}}
									>
										<Divider
											sx={{
												width: "100%",
												backgroundColor: COLORS.light.primary,
											}}
										/>

										<div className={"stats-graphics-chart"}>
											<Bar
												data={dataStats}
												options={{
													maintainAspectRatio: false,
													elements: { arc: { borderWidth: 0 } },
													hover: { mode: null }
												}}
											/>
										</div>
									</Box>
								</Box>

							</Box>

						</Container>
					</>
			}
		</>
	);
}