import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "../pages/Login";
import { List } from "../pages/List";
import Stats from "../pages/Stats";
import BlackList from "../pages/BlackList";
import Chats from "../pages/Chats";
import Contacts from "../pages/Contacts";
import Settings from "../pages/Settings";

import ProtectedRoutes from "./ProtectedRoutes";
import Signup from "../pages/Signup";
import Forbidden from "../pages/Forbidden";
import BulkSms from "../pages/BulkSms";
import Dashoboard from "../pages/Dashboard";
import Home from "../pages/Home";
import NoConnection from "../pages/NoConnection";
import Payment from "../pages/Payment";
import Logs from "../pages/Logs";
import BulkCustom from "../pages/BulkCustom";

function PagesRouter() {
	return (
		<Router>
			<Routes>
				<Route path={"/"} element={<Home />} />
				<Route path={"/login"} element={<Login />} />
				<Route path={"/signup"} element={<Signup />} />
				<Route path={"/noconnection"} element={<NoConnection />} />
				<Route element={<ProtectedRoutes />}>
					<Route path={"/dashboard"} element={<Dashoboard />} />
					<Route path={"/bulk"} element={<BulkSms />} />
					<Route path={"/bulkCustom"} element={<BulkCustom />} />
					<Route path={"/list"} element={<List />} />
					<Route path={"/list/:idBulkMessage"} element={<List />} />
					<Route path={"/chats"} element={<Chats />} />
					<Route path={"/chats/:idContact"} element={<Chats />} />
					<Route path={"/contacts"} element={<Contacts />} />
					<Route path={"/contacts/:idContact"} element={<Contacts />} />
					<Route path={"/stats"} element={<Stats />} />
					<Route path={"/blacklist"} element={<BlackList />} />
					<Route path={"/payment"} element={<Payment />} />
					<Route path={"/payment/:code"} element={<Payment />} />
					<Route path={"/logs"} element={<Logs />} />
					<Route path={"/settings"} element={<Settings />} />
				</Route>
				<Route path={"*"} element={<Forbidden />} />
			</Routes>
		</Router>
	);
}

export default PagesRouter;